@font-face {
  font-family: 'AmericanIcons';
  src: url('../fonts/AmericanIcons/american-icons-v4.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.mn_aaIcon,
[class*='mn_aaIcon'] {
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: AmericanIcons;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  line-height: 1;
}

.mn_aaIconQuestion:before {
  content: '\e03b';
}

.mn_aaIconSearch:before {
  content: '\e039';
}

.mn_aaIconCheckCircle:before {
  content: '\e04b';
}

.mn_aaIconExclamation:before {
  content: '\e03d';
}

.mn_aaIconCircleCheck:before {
  content: '\e04b';
}

.mn_aaIconCircleX:before {
  content: '\e04c';
}

.mn_aaIconCirclePlus:before {
  content: '\e071';
}

.mn_modalClose:before {
  content: '\e04c';
  font-family: AmericanIcons; // stylelint-disable-line font-family-no-missing-generic-family-keyword
  display: none;
}

.mn_aaSvgIcon {
  border: none;
  background: none;
  -webkit-appearance: none;
  border-radius: 0;

  &:before {
    content: '';
    display: block;
    height: 2.9rem;
    width: 2.9rem;
  }
}

.mn_aaIconExtraMiles:before {
  background: url(../assets/images/icons/extra-miles-icon-white.svg) no-repeat no-repeat;
}

.mn_aaIconHamburger {
  &:before {
    background: url(../assets/images/icons/hamburger-white.svg) no-repeat no-repeat;
    height: 2.5rem;
  }
}

.mn_aaIconHelp:before {
  background: url(../assets/images/icons/help-icon-white.svg) no-repeat no-repeat;
}

.mn_aaIconFavorite:before {
  background: url(../assets/images/icons/heart-icon-white.svg) no-repeat center;
  width: 3rem;
  background-size: contain;
}

.mn_aaIconMyAccount:before {
  background: url(../assets/images/icons/my-acc-icon-white.svg) no-repeat no-repeat;
  width: 1.8rem;
}

.mn_aaIconSearchSvg:before {
  background: url(../assets/images/icons/search_white.svg) no-repeat no-repeat;
}
