@import '~core/styles/variables';

$xxlarge: 1420px;
$hamburgerMenuNavMinHeight: 2500px;

/* Colors */
$brandPrimaryColor: #0078d2;
$brandSecondaryColor: #003057;
$brandTertiaryColor: #455869;
$brandQuaternaryColor: #627a88;

$brandPrimaryRed: #c30019;
$brandPrimaryGrey: #36495a;
$brandPrimaryGreen: #008712;
$brandPrimaryBlue: #2172ba;
$brandDarkBlue: #00467d;
$brandLightBlue: #4fb3f5;
$brandLightBlue2: #439fd5;
$brandGray: #c8c9ca;
$brandSecondaryGray: #d0dae0;
$brandLightGray: #ecf0f3;
$brandDarkGray: #9da5ab;
$brandWhite: #fff;
$brandBlack: #000;
$brandDarkGray2: #666;
$brandDarkGray3: #231f20;
$brandBorder2: #e6e6e6;
$brandDarkBlue2: #00467f;
$brandTertiaryColor2: #3d4f60;
$brandGray2: #f5f7f7;
$brandGray3: #e7ecef;
$brandBorder3: $brandGray3;
$brandDarkBlue3: #0061ab;
$brandGray4: #999;
$brandGray5: #ccc;
$brandGray6: #ebeff0;
$brandNeutralDarkColor2: #ccc;

/* Typography */
// Text
$brandTextColor: $brandPrimaryGrey;
$brandTextColorInverse: $brandWhite;
$brandTextFontSize: (small: 1.5rem, medium: 1.5rem);
$brandFontFamily: 'AmericanSans', Helvetica, Arial, sans-serif;
$brandFontFamilyLight: 'AmericanSans-Light', 'AmericanSans', Helvetica, Arial, sans-serif;
// Links
$brandLinkColor: $brandPrimaryColor;
$brandLinkColorHover: #0087ec;
// Border
$brandBorderColor: #9da6ab;
$brandBorder: $brandBorderColor solid 1px;
// H1
$brandH1FontSize: (small: 3.4rem, medium: 3.8rem);
$brandH1FontWeight: normal;
$brandH1FontFamily: $brandFontFamily;
// H2
$brandH2Margin: (small: 2rem 0);
$brandH2FontSize: (small: 2.6rem, medium: 3rem);
$brandH2FontWeight: normal;
$brandH2FontFamily: $brandFontFamily;
$brandH2Color: $brandPrimaryGrey;
// H3
$brandH3FontSize: (small: 2rem, medium: 2.4rem);
$brandH3FontWeight: normal;
$brandH3Margin: (small: 0);
$brandH3FontFamily: $brandFontFamily;
// Ul
$brandUlLiMargin: (small: 0);
// Rebates
$rebateValueWithCurrencyFontWeight: 500;
$rebateCurrencyFontWeight: 500;
$rebateColor: $brandPrimaryColor;
$rebateValueColor: $brandDarkBlue;
$rebateCurrencyColor: $brandPrimaryGreen;
// Rebate Elevations
$rebateElevationColor: #231f20;
$rebateElevationStrikethroughPrimary: 2px solid $rebateElevationColor;
$rebateElevationStrikethroughSecondary: 2px solid $rebateElevationColor;
$rebateElevationTextFontWeight: 400;
// Search
$searchModalSearchInputBackground: $brandWhite;
$searchFormFocusBorderColor: $brandBorder2;

/* Buttons */
$brandButtonBorder: none;
$brandButtonColor: $brandWhite;
$brandButtonFontFamily: $brandFontFamily;
$brandButtonFontSize: 1.6rem;
$brandButtonShadow: none;
$brandButtonBorderRadius: 2px;
$brandButtonFontWeight: bold;
$brandButtonBackgroundColor: $brandPrimaryColor;
$brandButtonBackgroundGradient: $brandButtonBackgroundColor, $brandDarkBlue3;
$brandButtonPadding: (small: 1rem 5rem, medium: 1rem 5rem);
// hover
$brandButtonColorHover: $brandWhite;
$brandButtonBackgroundGradientHover: none;
$brandButtonBackgroundColorHover: #005b9f;
$brandButtonBorderColorHover: none;
// active
$brandButtonColorActive: $brandWhite;
$brandButtonBackgroundGradientActive: none;
$brandButtonBackgroundColorActive: #005b9f;
// secondary
$brandButtonSecondaryBackground: linear-gradient(180deg, $brandQuaternaryColor 0%, #536773 100%);

/* Rebates */
$rebateColor: $brandPrimaryColor;
$rebateValueColor: $brandPrimaryColor;
$rebateCurrencyColor: $brandPrimaryColor;
$rebateValueWithCurrencyFontWeight: 700;
$rebateCurrencyFontWeight: 700;
// V2
$rebateV2NewValueFontSize: 1.2rem;
$rebateV2NewValueFontWeight: 700;
$rebateV2NewValueFontSize: 1.4rem;
$rebateV2NewValueLineHeight: 1.9rem;
$rebateV2OldValueColor: $brandQuaternaryColor;
$rebateV2OldValueLineHeight: 1.7rem;
$rebateV2ValueColor: $brandPrimaryColor;
$rebateV2NewValueLetterSpacing: 0;
$rebateV2NewValueFontColor: $brandPrimaryColor;

// Premier Marchant Rebate
$premierRebateElevationColor: $brandQuaternaryColor;
$premierRebateOldValueAfterContent: '.';

/* Lightbox */
$lightboxCloseIconContent: '\2715';
$lightboxCloseIconFontFamily: ();
$lightboxCloseFontSize: (small: 2.4rem);
$lightboxCloseRight: (small: .5rem, medium: 0);
$lightboxPadding: (small: 2.8rem .5rem .5rem .5rem, medium: 2rem 3rem);

/* Section */
$sectionTitleMargin: (small: 0 0 2rem 0);
$sectionTitleH2TextAlign: (small: center, medium: left);
$sectionTitleH2FontWeight: (small: 400, medium: 400);
$sectionTitleH2LineHeight: (small: 3rem, medium: 1.2);
$sectionTitleH2FontSize: (small: 2.5rem, medium: 3rem);
$sectionTitleFlexDirection: (medium: row);
$sectionTitleAlignItems: (small: baseline);
$sectionTitleJustifyContent: (small: center, medium: space-between);

/* Credit Card Marketing Banner */
$creditCardBannerInsideWrapperDisplay: block;
$creditCardBannerMargin: (small: 0 -2rem, medium: 0, large: 0 -3rem, xlarge: ());
$creditCardBannerJustifyContent: unset;
$creditCardBannerPadding: (small: 3rem 1rem 6.5rem 1rem, medium: 1rem 0 1rem 4rem, large: 1.5rem 3rem);
$creditCardBannerImageMargin: (small: (), medium: 0, large: 0);
$creditCardBannerInsideWrapperWidth: calc(100% - 29rem);
$creditCardBannerBorderLeft: 1px solid $brandWhite;
$creditCardBannerImageMaxWidth: (small: 16.5rem, medium: 29rem, large: 33rem);
$creditCardBannerTextColor: $brandWhite;
$creditCardBannerTextFontSize: (small: 2.5rem, medium: 2.5rem);
$creditCardBannerTextLineHeight: (small: 3rem, medium: 3rem);
$creditCardBannerTextTextAlign: (small: left, medium: left);
$creditCardBannerLinkColor: $brandWhite;
$creditCardBannerLinkFontSize: 1.6rem;
$creditCardBannerLinkMargin: 1.5rem 0 0;
$creditCardBannerLinkDisplay: block;
$creditCardBannerLinkTextAlign: left;
$creditCardBannerInsideWrapperMargin: 0 0 0 3rem;
$creditCardBannerMaxWidth: 155rem;

/* Search Form */
$searchFormSubmitButtonColor: $brandWhite;
$searchFormFocusBackground: rgba(255, 255, 255, .25);
$searchFormDesktopInputPadding: .4rem 3.5rem .4rem .6rem;
$searchFormDesktopInputHeight: 3.5rem;
$searchFormDesktopInputBorderBottom: 1px solid $brandWhite;
$searchFormInputColor: $brandWhite;
$searchFormDesktopInputFontSize: 1.8rem;
$searchFieldPlaceholderColor: $brandWhite;
$searchFieldPlaceholderOpacity: 1;
$searchFormSubmitButtonRight: 1.2rem;
$searchFormDesktopSubmitPositionTop: .8rem;
$searchFormDesktopSubmitFontSize: 2.2rem;
$searchFormFocusBorderColor: ();
$searchFormDesktopDropDownWidth: 31.5rem;
$searchFormDesktopDropDownBoxShadow: 0 2px 4px 0 $brandQuaternaryColor;
$searchFormDesktopDropDownPositionLeft: 0;
$searchFormInputPadding: .4rem 3.5rem .4rem 1.5rem;

/* Search bar */
$searchBarDesktopWidth: 31.5rem;
$searchBarDesktopPadding: 0;
$searchBarPadding: 0;

/* Hybrid Search Modal */
$hybridSearchModalSearchInputMargin: 1rem auto 0;
$hybridSearchModalFormWrapPadding: 0 0 1.3rem 0;
$hybridSearchModalFormWrapBackground: #1a94ff;
$hybridSearchModalSearchInputBackground: $brandWhite;
$hybridSearchModalSearchSubmitIconBeforeFontFamily: AmericanIcons;
$hybridSearchModalSearchSubmitIconBeforeContent: '\e039';
$hybridSearchModalSearchSubmitIconBeforeColor: $brandPrimaryColor;
$hybridSearchModalSearchSubmitIconBeforeFontStyle: normal;
$hybridSearchModalSearchInputColor: $brandPrimaryGrey;
$hybridSearchModalSearchSubmitPositionBottom: 3.5rem;
$hybridSearchModalSearchSubmitZIndex: 12;

/* Hybrid Quick Search */
$hybridQuickSearchBackground: (small: $brandWhite, medium: ());
$hybridQuickSearchRebateValueColor: $rebateColor;
$hybridQuickSearchColor: (small: (), medium: ());
$hybridQuickSearchPadding: (small: 2.4rem 2rem 2.6rem, medium: 2rem 2rem 1rem);
$hybridQuickSearchNoResultsMargin: (small: 2rem 0 0, medium: .4rem 0 0);
$hybridQuickSearchNoResultsFontSize: (small: 1.8rem);
$hybridQuickSearchNoResultsLineHeight: (small: 2.4rem);
$hybridQuickSearchNoResultsFontWeight: (small: 600);
$hybridQuickSearchItemMatchColor: $brandPrimaryGrey;
$hybridQuickSearchItemMatchProductColor: $brandBlack;
$hybridQuickSearchWrapLinkColor: $brandLinkColor;
$hybridQuickSearchWrapLinkTextDecoration: none;
$hybridQuickSearchWrapLinkHoverTextDecoration: underline;
$hybridQuickSearchWrapDividerMargin: 3rem 0 2rem;
$hybridQuickSearchResultsMargin: (small: 0 0 1.2rem);
$hybridQuickSearchItemMatchMerchantPadding: (small: .5rem 3.5rem .5rem 3rem);
$hybridQuickSearchItemMatchMerchantHoverBackground: $brandGray3;
$hybridQuickSearchItemMargin: 0;
$hybridQuickSearchItemMatchProductPadding: (small: .5rem 2rem .5rem 0);
$hybridQuickSearchItemMatchProductHoverBackground: $brandGray3;
$hybridQuickSearchAnonymousItemMatchMerchantPadding: (small: .5rem 0 .5rem 0);

/* Hybrid Search Form */
$hybridSearchFormFocusPlaceholderColor: $brandPrimaryGrey;
$hybridSearchFormPlaceholderColor: $brandWhite;
$hybridSearchFormDesktopInputHeight: 3.5rem;
$hybridSearchFormDesktopInputFontSize: 1.8rem;
$hybridSearchFormDesktopInputPadding: .4rem 3.5rem .4rem .6rem;
$hybridSearchFormDesktopInputBorderBottom: 1px solid $brandWhite;
$hybridSearchFormDesktopHoverBackground: $brandWhite;
$hybridSearchFormDesktopHoverBorder: solid #1a94ff;
$hybridSearchFormDesktopHoverBorderWidth: 2px 2px 0 2px;
$hybridSearchFormDesktopDropDownWidth: 41.6rem;
$hybridSearchFormDesktopDropDownBorder: 2px solid #1a94ff;
$hybridSearchFormWidth: 41.6rem;
$hybridSearchFormFocusBackground: $brandWhite;
$hybridSearchFormFocusBorder: solid #1a94ff;
$hybridSearchFormFocusBorderWidth: 2px 2px 0 2px;
$hybridSearchFormBorder: 2px solid #1a94ff;
$hybridSearchFormPositionLeft: (small: 0, medium: unset, large: 0);
$hybridSearchFormPositionRight: (small: 0, medium: 0, large: unset);
$hybridSearchFormMargin: -.2rem 0 0;
$hybridSearchFormFocusPosition: relative;
$hybridSearchFormFocusZIndex: 12;
$hybridSearchFormDesktopDropDownPositionLeft: ();
$hybridSearchFormDesktopDropDownPositionRight: ();
$hybridSearchFormDesktopDropDownPositionTop: ();
$hybridSearchFormSubmitButtonIconBeforeFontFamily: AmericanIcons;
$hybridSearchFormSubmitButtonIconBeforeContent: '\e039';
$hybridSearchFormSubmitButtonIconBeforeFontSize: 2.4rem;
$hybridSearchFormSubmitButtonIconBeforePositionRight: 1.5rem;
$hybridSearchFormSubmitButtonIconBeforePosition: relative;
$hybridSearchFormSubmitButtonIconBeforePositionTop: .5rem;
$hybridSearchFormSubmitButtonIconBeforeColor: $brandWhite;
$hybridSearchFormSubmitButtonIconBeforeFontStyle: normal;
$hybridSearchFormSubmitButtonIconBeforeZIndex: 12;
$hybridSearchFormSubmitButtonOnFocusIconBeforeColor: $brandDarkBlue2;
$hybridSearchFormBoxShadow: (small: (), medium: 0 0 6px 0 $brandPrimaryGrey);
$hybridSearchFormOnFocusAfterContent: '';
$hybridSearchFormOnFocusAfterDisplay: block;
$hybridSearchFormOnFocusAfterPosition: absolute;
$hybridSearchFormOnFocusAfterPositionBottom: 0;
$hybridSearchFormOnFocusAfterHeight: .5px;
$hybridSearchFormOnFocusAfterBackground: $brandQuaternaryColor;
$hybridSearchFormOnFocusAfterWidth: 30rem;
$hybridSearchFormOnFocusAfterPositionLeft: .5rem;
$hybridSearchFormOnFocusAfterZIndex: 12;
$hybridSearchFormInputColor: $brandWhite;
$hybridSearchFormFocusColor: $brandTextColor;

/* Recent Stores */
$recentStoresFlipTileBorder: 1px solid $brandBorder3;
$recentStoresFlipTileMargin: .5rem 0;
$recentStoresFlipTileBorderRadius: .2rem;
$recentStoresTitleHeadingColor: $brandPrimaryGrey;
$recentStoresViewAllLinkBottomFontSize: 1.4rem;
$recentStoresPadding: 1.2rem .7rem;
$recentStoresFlipTileFrontBackground: ();
$recentStoresFlipTileBackBackground: ();
$recentStoresInSearchPadding: (small: 0, medium: 0);
$recentStoresInSearchPosition: relative;
$recentStoresTitleMargin: 0 0 2rem;
$recentStoresViewAllLinkBottomMargin: 2rem 0 0;
$recentStoresTitleFontWeight: 600;
$recentStoresFlipTileBorder: none;

/* Similar Stores */
$similarStoreItemBorder: 1px solid $brandBorder3;
$similarStoreItemMargin: .5rem 0;
$searchModalSimilarStoresTileItemBorder: none;
$similarStoresRebatePrefixFontWeight: 700;
$similarStoresPadding: 1.2rem 0;
$similarStorePadding: 0;
$similarStoreItemBorder: none;
$similarStoreItemHeight: 5rem;

/* Header Extra Rewards */
$headerExtraRewardsHeaderMargin: 0;
$headerExtraRewardsHeaderColor: $brandPrimaryGrey;
$headerExtraRewardsHeaderFontWeight: bold;
$headerExtraRewardsHeaderFontSize: 1.8rem;
$headerExtraRewardsSectionTitleMargin: 0 0 .5rem;
$headerExtraRewardsLinkBottomWidth: 31.3rem;
$headerExtraRewardsLinkBottomHeight: 4rem;
$headerExtraRewardsLinkBottomBackground: linear-gradient(to bottom, $brandPrimaryColor, $brandDarkBlue3);
$headerExtraRewardsLinkBottomMargin: 1rem auto 2rem;
$headerExtraRewardsLinkBottomTextAlign: center;
$headerExtraRewardsLinkBottomColor: $brandWhite;
$headerExtraRewardsLinkBottomLineHeight: 4rem;
$headerExtraRewardsLinkBottomBorderRadius: 2px;
$headerExtraRewardsLinkBottomFontSize: 1.6rem;
$headerExtraRewardsLinkBottomHoverTextDecoration: none;
$headerExtraRewardsLinkBottomHoverBackground: #005b9f;
$headerExtraRewardsLinkBottomDisplay: block;

/* Header navigation */
$navigationListItemFontSize: 1.8rem;
$navigationListItemLinkColor: $brandPrimaryGrey;
$navigationListItemLinkDisplay: inline-block;
$navigationListItemLinkPadding: 1.5rem 0;
$navigationListItemHoverTextDecoration: underline;
$navigationListDropdownItemTriggerPadding: 1.5rem 0;
$navigationListDropdownItemMenuLinkColor: $brandQuaternaryColor;
$navigationListDropdownItemMenuLinkPadding: 1.5rem 3.5rem 1.5rem 7rem;
$navigationListDropdownItemMenuLinkFontSize: 1.6rem;
$navigationListDropdownItemMenuLinkHoverTextDecoration: underline;
$navigationListDropdownItemMenuLinkHoverColor: #39aaff;
$navigationListDropdownItemTriggerBeforeFontFamily: 'fontello';
$navigationListDropdownItemTriggerBeforeContent: '\f107';
$navigationListDropdownItemTriggerBeforeFontSize: 3rem;
$navigationListDropdownItemTriggerBeforePositionRight: 1rem;
$navigationListDropdownItemTriggerBeforePositionLeft: unset;
$navigationListDropdownItemTriggerFocusedBeforeTransform: rotate(180deg);

/* TEMPORARY TO FIX BUILD */
$brandNeutralDarkColor2: $brandGray5;
$brandBlack: #000;

/* Quick Links Tray */
$quickLinksTrayBackgroundColor: url('~org/assets/images/quick_tray_back_swoosh.png');
$quickLinksTrayToggleHeight: 3.5rem;
$quickLinksTrayToggleFontSize: 1.3rem;
$quickLinksTrayToggleLetterSpacing: .3px;
$quickLinksTrayToggleFontWeight: 500;
$quickLinksTrayToggleAfterDisplay: inline-block;
$quickLinksTrayToggleAfterVerticalAlign: middle;
$quickLinksTrayToggleAfterContent: '\f105';
$quickLinksTrayToggleAfterFontFamily: 'fontello';
$quickLinksTrayToggleAfterFontSize: 2.2rem;
$quickLinksTrayToggleAfterMargin: 0 0 0 1rem;
$quickLinksSearchIconBackground: $brandWhite url('~org/assets/images/icons/search_blue.svg') no-repeat center;
$quickLinksSearchIconBoxShadow: -.5px -.5px 5px 0 rgba(0, 0, 0, .25);
$quickLinksTrayIconAccountUrl: url('~org/assets/images/icons/account.svg');
$quickLinksTrayIconExtraRewardsUrl: url('~org/assets/images/icons/extra.svg');
$quickLinksTrayIconAllStoresUrl: url('~org/assets/images/icons/all_stores.svg');
$quickLinksTrayLinkFontSize: 1.2rem;
$quickLinksTrayLinkFontWeight: bold;
$quickLinksTrayLinkLineHeight: 1.4rem;
$quickLinksTrayIconHeight: 3.5rem;
$quickLinksTrayIconMargin: 5px auto 0;

/* Navigation TOF */
$navCategoriesTOFLinkHeight: ();
$navCategoriesTOFLinkWidth: 21.9rem;
$navCategoriesTOFLinkMargin: 0 0 2rem;
$navBannerContentBackground: $brandGray3;
$navBannerButtonBannerBackground: $brandWhite url('~org/assets/images/AA_hiw_button.png') no-repeat center;
$navBannerFavoritesBannerBackground: $brandWhite url('~org/assets/images/AA_hiw_favorites.png') no-repeat center;
$navBannerLinkColor: $brandDarkBlue;
$navBannerLinkTextDecoration: underline;
$navCategoriesTOFDropdownMenuWidth: 35rem;
$navCategoriesTOFDropdownMenuPositionLeft: 25.9rem;
$navCategoriesTOFDropdownMenuBackground: #fcfcfc;
$navCategoriesTOFDropdownMenuTop: 1rem;
$navCategoriesTOFDropdownMenuPadding: 1.5rem 1.5rem 1rem 2rem;
$navCategoryFeaturedItemTitleColor: $brandPrimaryGrey;
$navCategoriesTOFDropdownMenuBorderRadius: 4px;
$navCategoriesTOFDropdownMenuBoxShadow: 0 0 6px 0 rgba(0, 0, 0, .2);
$navCategoriesTOFDropdownMenuMinHeight: calc(100% - 3rem);
$navCategoryFeaturedItemHoverColorBackground: rgba(208, 218, 224, .5);
$navCategoryFeaturedItemTitleFont: 700 1.4rem $brandFontFamily;
$navCategoryFeaturedItemRebateFontSize: 1.4rem;
$navCategoryFeaturedItemRebateOldValueFontSize: 1.2rem;
$navCategoryFeaturedItemRebateTieredPrefixTextTransform: capitalize;
$navCategoriesTOFCloseMenuAfterContent: '\e04c';
$navCategoriesTOFCloseMenuAfterWidth: unset;
$navCategoriesTOFCloseMenuAfterHeight: unset;
$navCategoriesTOFCloseMenuAfterBackground: none;
$navCategoriesTOFCloseMenuAfterFontFamily: 'AmericanIcons';
$navCategoriesTOFCloseMenuAfterFontSize: 2rem;
$navCategoriesTOFCloseMenuAfterColor: $brandPrimaryColor;
$navCategoriesTOFCloseMenuPositionRight: .8rem;
$navCategoriesTOFCloseMenuPositionTop: .8rem;
$navCategoriesTOFLinkFontWeight: 400;
$navCategoryFeaturedFooterLinkColor: $brandPrimaryColor;
$navCategoryFeaturedFooterLinkCategoryTextTransform: lowercase;

/* Flip Tile */
$flipTilePremierRebateTextAlign: left;
$flipTileButtonFontSize: 1.2rem;
$flipTileButtonHeight: 2.7rem;
$flipTileButtonWidth: 10.4rem;
$flipTileButtonLineHeight: 2.7rem;
$flipTileButtonFontWeight: 700;
$flipTileBorder: 1px solid $brandBorder3;
$flipTileBorderRadius: 2px;
$flipTileBorderWidth: ();
$flipTileMargin: 0 0 .5rem 0;
$flipTileItemPadding: 0;
$flipTilePremierRebatePrefixFontWeight: 700;
$flipTileItemHeight: 5rem;

/* Quick Search */
$quickSearchFooterProductLinkWrapMargin: 0;
$quickSearchFooterProductLinkWrapFontWeight: 600;
$quickSearchFooterProductLinkWrapPadding: 1rem;
$quickSearchFooterProductLinkWrapBorderTop: 1px solid #9ea6a8;
$quickSearchFooterPadding: .8rem 1rem .8rem 4rem;
$quickSearchFooterLineHeight: 1.8rem;
$quickSearchFooterBackground: $brandWhite;
$quickSearchFooterColor: $brandPrimaryGrey;
$quickSearchFooterBorderRadius: 0;
$quickSearchResultsOverflow: auto;
$quickSearchResultsMaxHeight: (small: none, medium: 17rem);
$quickSearchResultsBorder: 1px solid #f2f2f2;
$quickSearchResultsBorderWidth: 0 0 .7rem 0;
$quickSearchMatchColor: $brandQuaternaryColor;
$quickSearchMatchHighlightColor: $brandQuaternaryColor;
$quickSearchMerchantRebateFontSize: 1.4rem;
$quickSearchMerchantRebatePrefixFontWeight: 700;
$quickSearchMatchPadding: (small: .5rem 2rem .5rem 5rem, medium: .8rem 1rem .8rem 4rem);
$quickSearchMatchHoverBackground: $brandGray3;
$quickSearchMatchLineHeight: 1.8rem;
$quickSearchMerchantFavoriteIconPositionTop: (small: .8rem, medium: .8rem);
$quickSearchMerchantFavoriteIconPositionLeft: (small: .8rem, medium: .8rem);
$quickSearchMatchJustifyContent: space-between;
$quickSearchMatchDashDisplay: none;
$quickSearchMerchantRebateValueAndSuffixWrapFontWeight: 700;
$quickSearchFooterProductLinkWrapBackground: $brandWhite;
$quickSearchResultsItemDisplay: block;

/* Nav Seasonal Flyout */
$navSeasonalFlyoutTileWidth: 13rem;
$navSeasonalFlyoutTileHeight: 13rem;
$navSeasonalFlyoutCTAColor: ();
$navSeasonalFlyoutMargin: 3rem 0 0;
$navSeasonalFlyoutTileWrapperMargin: 0 .5rem 1rem;
$merchantTileBorder: (small: 1px solid $brandBorder3);
$navSeasonalFlyoutRebatePositionTop: ();
$navSeasonalFlyoutCTAWrapMargin: 1rem 0 0 0;
$navSeasonalFlyoutRebateElevationNewValueFontSize: 1.2rem;
$navSeasonalFlyoutMerchantTileListJustifyContent: unset;
$navSeasonalFlyoutMerchantTileListMargin: 0 -.5rem;
$navSeasonalFlyoutTilePillPositionTop: 58%;
$navSeasonalFlyoutTilePillWidth: 6rem;
$navSeasonalFlyoutTilePillHeight: 1.5rem;
$navSeasonalFlyoutTilePillLabelFontSize: .8rem;
$navSeasonalFlyoutTilePillLabelLineHeight: 1.6rem;
$navSeasonalFlyoutTilePillLabelPadding: 0 0 0 1.5rem;
$navSeasonalFlyoutTilePillLabelAfterWidth: 1.5rem;
$navSeasonalFlyoutTilePillLabelAfterHeight: 1.5rem;
$navSeasonalFlyoutTileImgMargin: 2.3rem auto;
$navSeasonalFlyoutTileFavoriteIconMarginTop: 0;
$navSeasonalFlyoutTileFavoriteIconPositionRight: .4rem;

/* Merchant Tile */
$merchantTileBorderRadius: 0;
$merchantTileBoxShadow: (small: none, medium: none);
$merchantTileRebateBackground: (small: linear-gradient(-10deg, $brandGray2 50%, $brandWhite 50.5%, rgba(0, 0, 0, 0) 50.5%));
$merchantTileItemPositionTop: (small: 0);
$merchantTileItemPositionBottom: (small: 0);
$merchantTileItemPositionLeft: (small: 0);
$merchantTileItemPositionRight: (small: 0);
$merchantTileRebateHeight: (small: 6.7rem, medium: 9.7rem);
$merchantTileRebateAlignItems: flex-end;
$merchantTilePillWidth: (small: 9rem, medium: 8rem);
$merchantTileImageMargin: (small: 1.5rem auto 0);
$merchantTileFavoriteIconWidth: (small: 2.2rem, medium: 2.2rem);
$merchantTileFavoriteIconHeight: (small: 2.2rem, medium: 2.2rem);

/* Lightbox */
$lightboxCloseIconContent: '\2715';
$lightboxCloseFontSize: (small: 1.7rem,  medium: ());

/* Modal */
$modalPadding: (small: 3.5rem 1.5rem 1.5rem 1.5rem, medium: 3rem);
$modalCloseButtonFontFamily: 'AmericanIcons';
$modalCloseButtonFontSize: (small: 2.4rem);
$modalCloseButtonColor: $brandPrimaryColor;
$modalCloseButtonAfterContent: '\e04c';

/* Offer Overlay */
$merchantOverlayModalCloseButtonColor: (small: #fff, medium: $brandPrimaryColor);
$merchantOverlayModalCloseButtonFontSize: (small: 1.8rem, medium: 2.4rem);
$merchantOverlayModalCloseButtonAfterFontFamily: (small: $brandFontFamily, medium: 'AmericanIcons');
$merchantOverlayModalCloseButtonAfterContent: (small: '\2715', medium: '\e04c');
$merchantOverlayHeadLogoOffsetTop: (small: 3rem, medium: -4.8rem);
$merchantOverlayHeadLogoBorderRadius: (small: 0, medium: 0);
$merchantOverlayFavoriteIconPositionRight: (small: 0, medium: .3rem);
$merchantOverlayFavoriteIconPositionTop: (small: 0, medium: .7rem);
$merchantOverlayFavoriteIconWidth: (small: 2.3rem, medium: ());
$merchantOverlayFavoriteIconHeight: (small: 2.3rem, medium: ());
$merchantOverlayFavoriteFontIconFontSize: (small: 1.3rem, medium: 1.5rem);
$merchantOverlayHeadLogoPadding: (small: 1.5rem 2rem, medium: 2.4rem 2.4rem 0);
$merchantOverlayContentPadding: (small: 0, medium: 0 2rem 1rem);
$merchantOverlayDescriptionMargin: (small: 0, medium: 2rem 0 0);
$merchantOverlayOfferTitleFont: (small: 500 2rem/2.6rem $brandFontFamily,  medium: 500 3rem/3.7rem $brandFontFamily);
$merchantOverlayOfferTitleColor: (small: $brandPrimaryGrey, medium: $brandDarkBlue2);
$merchantOverlayHeadRebatePadding: (small: 1.2rem 0 1.8rem, medium: 2rem 0 0);
$merchantOverlayHeadRebateBorderBottom: (small: 1px solid rgba(0, 0, 0, .1), medium: none);
$merchantOverlayHeadRebateBorderTop: (small: none, medium: 1px solid rgba(0, 0, 0, .1));
$merchantOverlayHeadOfferExpiresFontSize: (small: 1.4rem);
$merchantOverlayHeadOfferExpiresMargin: (small: 0 0 1rem);
$merchantOverlayHeadOfferSeparatorDisplay: (small: none, medium: ());
$merchantOverlayHeadRebateMargin: (small: 0);
$merchantOverlayHeadRebateFontSize: (small: 1.4rem);
$merchantOverlayRebateOldValueDisplay: (small: block, medium: inline);
$merchantOverlayRebateOldValueFontSize: (small: 1.2rem, medium: 1.8rem);
$merchantOverlayRebateNewValueFontSize: (small: 1.6rem, medium: 1.8rem);
$merchantOverlayRebateNewValueColor: $brandPrimaryColor;
$merchantOverlayRebateNewValueFontWeight: bold;
$merchantOverlayMerchantRebateFontSize: 1.4rem;
$merchantOverlayHeadRebateFontWeight: (small: 600);
$merchantOverlayRebatePrefixColor: (small: $brandPrimaryColor);
$merchantOverlayCouponControlsJustifyContent: center;
$merchantOverlayCouponCodeInputMinWidht: (small: 22rem);
$merchantOverlayCouponCodeInputMaxWidht: (small: 22rem);
$merchantOverlayCouponCodeCopyWidth: (small: 8rem);
$merchantOverlayCouponCodeCopyHoverBackground: rgba(98, 122, 136, .25);
$merchantOverlayCouponCodeCopyHoverColor: #375364;
$merchantOverlayCouponCodeIsCopiedColor: #375364;
$merchantOverlayCouponCodeIsCopiedBackground: rgba(98, 122, 136, .25);
$merchantOverlayCouponCodeInputHeight: (small: 5rem);
$merchantOverlayCouponCodeMarginLeft: -1px;
$merchantOverlayCouponIntroFontSize: (small: 1.2rem, medium: 1.4rem);
$merchantOverlayCouponIntroMargin: (small: 1rem 0, medium: 1rem 0);
$merchantOverlayShopNowBackgroundColor: linear-gradient(180deg, $brandPrimaryColor 0%, $brandDarkBlue3 100%);
$merchantOverlayShopNowMargin: (small: 3rem 0 2rem, medium: 2rem 0);
$merchantOverlayShopNowFont: (small: 400 1.8rem/1.7rem $brandFontFamily, medium: 100 1.6rem/2.2rem $brandFontFamily);
$merchantOverlayShopNowBorder: none;
$merchantOverlayShopNowPadding: (small: 1.15rem 1rem, medium: 1rem);
$merchantOverlayShopNowBorderRadius: 2px;
$merchantOverlayShopNowMinWidth: (small: 25.8rem,  medium: 20rem);
$merchantOverlayFooterLinkFontWeight: (small: 500, medium: ());
$merchantOverlayFooterMerchantTermsMargin: (small: .5rem 0 0, medium: ());
$merchantOverlayFooterTermsFontSize: (small: 1.2rem, medium: 1rem);
$merchantOverlayFooterMerchantTermsColor: (small: #9da6ab, medium: $brandGray4);
$merchantOverlayFooterTermsTextDecoration: none;
$merchantOverlayDecorationDisplay: (small: block, medium: none);
$merchantOverlayDecorationBorderWidth: 2px 2px 0;
$merchantOverlayDecorationHeight: 146px;
$merchantOverlayDecorationBackground: linear-gradient(0deg, $brandDarkBlue2 0%, $brandDarkBlue3 100%);
$merchantOverlayDecorationPosition: relative;
$merchantOverlayDecorationAfterContent: '';
$merchantOverlayDecorationAfterDisplay: block;
$merchantOverlayDecorationAfterWidth: 100%;
$merchantOverlayDecorationAfterHeight: 56px;
$merchantOverlayDecorationAfterBackground: $brandPrimaryColor;
$merchantOverlayDecorationAfterPosition: absolute;
$merchantOverlayDecorationAfterPositionBottom: 0;
$merchantOverlayDisclaimerOrder: (small: 3, medium: 2);
$merchantOverlayOfferTitleOrder: (small: 2, medium: 1);
$merchantOverlayHeadOfferExpiresOrder: (small: 4, medium: 3);
$merchantOverlayPremierMerchantRebateOrder: (small: 1, medium: 4);
$merchantOverlayExtraPillWidth: 9rem;
$merchantOverlayDescriptionDisplay: (small: flex);
$merchantOverlayDescriptionFlexDirection: (small: column);
$merchantOverlayOfferTitleMargin: (small: 2rem 0 1rem);
$merchantOverlayRebateOldValueAfterContent: '';
$merchantOverlayDisclaimerFontSize: (small: 1.2rem, medium: ());
$merchantOverlayFooterTermsFontWeight: (small: 400, medium: ());
$merchantOverlayDisclaimerMargin: 0 auto;
$merchantOverlayCouponMargin: 1rem 0 2rem;
$merchantOverlayDisclaimerWidth: ();
$merchantOverlayArrowLinkDisplay: (small: none, medium: inline-block);
$merchantOverlayRebateOldValueColor: $brandQuaternaryColor;
$merchantNoRebateColor: $brandQuaternaryColor;
$merchantOverlayRebatePrefixFontSize: (small: 1.6rem, medium: 1.8rem);
$merchantOverlayRebatePrefixFontWeight: (small: bold, medium: 700);
$merchantOverlayRebateOldValueFontSize: (small: 1.6rem, medium: 1.8rem);
$merchantOverlayRebateValueWithCurrencyFontSize: (small: 1.6rem, medium: 1.8rem);
$merchantOverlayCouponCodeInputBackgroundColor: (small: $brandGray2, medium: $brandGray3);
$merchantOverlayCouponCodeInputPadding: (small: 1.5rem 1.8rem, medium: .2rem 0 .2rem 1rem);
$merchantOverlayCouponCodeInputBorder: (small: 1px solid $brandSecondaryGray, medium: 1px solid $brandGray3);
$merchantOverlayCouponCodeInputBorderRadius: (small: 4px 0 0 4px, medium: 3px 0 0 3px);
$merchantOverlayCouponCodeInputTextTransform: uppercase;
$merchantOverlayCouponCodeInputTextAlign: (small: left, medium: center);
$merchantOverlayCouponCodeInputMaxWidht: (small: 100%, medium: 100%);
$merchantOverlayCouponCodeInputHeight: (small: 5rem, medium:  3rem);
$merchantOverlayCouponCodeInputFont: (small: 600 1.3rem/1.4rem $brandFontFamily, medium: 500 1.4rem/2.1rem $brandFontFamily);
$merchantOverlayCouponCodeInputLetueterSpacing: .9px;
$merchantOverlayCouponCodeInputColor: (small: #11172b, medium: #375364);
$merchantOverlayCouponCodeCopyBackgroundColor: $brandWhite;
$merchantOverlayCouponCodeCopyBorderColor: $brandSecondaryGray;
$merchantOverlayCouponCodeCopyBorder: (small: 1px solid $merchantOverlayCouponCodeCopyBorderColor, medium: 1px solid $brandGray3);
$merchantOverlayCouponCodeCopyBorderRadius: (small: 0 4px 4px 0, medium: 0 3px 3px 0);
$merchantOverlayCouponCodeCopyFont: (small: 600 1.3rem/2.1rem $brandFontFamily, medium: 100 1.4rem/3rem $brandFontFamily);
$merchantOverlayCouponCodeCopyHeight: (small: 5rem, medium: 3.15rem);
$merchantOverlayCouponCodeCopyPadding: 0 2rem;
$merchantOverlayCouponCodeCopyColor: (small: #0b1f66, medium: #375364);
$merchantOverlayCouponCodeCopyWidth: (small: 9rem, medium: 9rem);
$merchantOverlayCouponCodeCopyTextTransform: (small: uppercase, medium: uppercase);

/* Pill */
$pillBackground: $brandDarkBlue2;
$pillLabelPadding: 0 0 0 1.2rem;
$pillLabelAfterPositionLeft: 0;
$pillLabelAfterHeight: 2rem;
$pillLabelAfterPosition: absolute;
$pillLabelAfterBackground: url('../assets/images/icons/rewards.svg') no-repeat;
$pillLabelAfterWidth: 2rem;

/* New Pill */
$newPillWidth: 6.9rem;
$newPillLabelFontWeight: 400;
$newPillLabelFontSize: 1.3rem;

/* Email OptIn Subscribe Modal */
$emailSubscribeModalBoxShadow: (small: 0 0 6px rgba(0, 0, 0, .5));
$emailSubscribePanelPadding: 2.5rem 5rem 1.3rem;
$emailSubscribePanelBackground: $brandDarkBlue;
$emailSubscribePanelTextAlign: center;
$emailSubscribeCloseWidth: (small: 2rem);
$emailSubscribeCloseHeight: (small: 2rem);
$emailSubscribeCloseColor: (small: $brandWhite, medium: $brandWhite);
$emailSubscribeHeadingTextAlign: center;
$emailSubscribeHeadingColor: (small: $brandLightGray, medium: $brandLightGray);
$emailSubscribeHeadingMargin: (small: 0 0 1rem, medium: 0 0 .5rem);
$emailSubscribeHeadingFont: (small: normal 3.3rem/1.2 $brandH1FontFamily, medium: normal 3.3rem/1.2 $brandH1FontFamily);
$emailSubscribeContentColor: (small: $brandWhite, medium: $brandWhite);
$emailSubscribeContentFont: (small: normal 1.6rem/1.4 $brandFontFamily, medium: normal 1.6rem/1.4 $brandFontFamily);
$emailSubscribeSubmitPadding: (small: 1.8rem 1.5rem, medium: 1.3rem 5.5rem);
$emailSubscribeSubmitFontSize: (small: 1.8rem, medium: 1.8rem);
$emailSubscribeSubmitFontWeight: normal;
$emailSubscribeSubmitMargin: (small: 1.5rem 0 1.5rem, large: 1.5rem 0 1rem);
$emailSubscribeSubmitLineHeight: 1;
$emailSubscribeSubmitBorder: none;
$emailSubscribeButtonLabelColor: $brandWhite;
$emailSubscribeButtonLabelFontSize: 1.3rem;
$emailSubscribeButtonLabelPaddingRight: 0;
$emailSubscribeModalPositionTop: 30%;
$emailSubscribeModalMinHeight: auto;
$emailSubscribeModalBackground: $brandDarkBlue;
$emailSubscribeModalWidth: (small: initial, medium: 57rem);
$emailSubscribeModalPadding: 2.5rem 8rem 3.3rem;
$emailSubscribeModalOutline: unset;
$emailSubscribeCancelLinkDisplay: (small: initial, medium: block);
$emailSubscribeCancelLinkColor: (small: $brandWhite, medium: $brandWhite);
$emailSubscribeCancelLinkFont: (small: normal 1.3rem/1.4 $brandFontFamily, medium: normal 1.3rem/1.4 $brandFontFamily);
$emailSubscribeCancelLinkLabelIconDisplay: none;
$emailSubscribeCancelMargin: (medium: auto);
$emailSubscribeCancelLinkLabeDotDisplay: none;
$emailSubscribeOptInImageBackground: url('../assets/images/emailOptIn.png') no-repeat center/contain;
$emailOptInImageBackground: url('../assets/images/emailOptIn.png') no-repeat center/contain;
$emailSubscribeOptinMargin: (medium: 5rem 0 0, large: 6rem 0 0);
$emailSubscribeOptInImageHeight: 8.8rem;
$emailSubscribeOptInImageWidth: 100%;
$emailSubscribeOptInImageMargin: (small: 5rem 0 3rem, medium: 0 0 1.3rem);
$emailSubscribeOptInH2Margin: (medium: 8rem 0 0, large: 7rem 0 1rem);
$emailSubscribeControlsMargin: (small: 1.5rem 0, medium: 2.2rem 0 .8rem);

/* Email OptIn Success Modal */
$emailSuccessMessageTextAlign: left;
$emailSuccessMessageColor: $brandSecondaryColor;
$emailSuccessMessageBackground: $brandQuaternaryColor;
$emailSuccessMessageCloseButtonBeforeColor: $brandWhite;
$emailSuccessMessageCloseButtonBeforeFontFamily: 'AmericanIcons';
$emailSuccessMessageCloseButtonBeforeContent: '\e04c';
$emailSuccessMessageCloseButtonBeforeFontSize: 2rem;
$emailSuccessMessageH2Color: $brandSecondaryColor;
$emailSuccessMessageH2Margin: 0 0 .5rem;
$emailSuccessMessageOlayDescFontSize: 2rem;
$emailSuccessModalBackground: $brandQuaternaryColor;
$emailSuccessModalCloseButtonColor: $brandWhite;
$emailSuccessModalContentH2Color: $brandSecondaryColor;
$emailSuccessModalContentH2FontSize: 3rem;
$emailSuccessModalContentH2FontWeight: 700;
$emailSuccessModalContentH2Margin: 0 0 .5rem;
$emailSuccessModalContentTextColor: $brandSecondaryColor;
$emailSuccessModalContentTextFontSize: 2rem;
$emailSuccessModalContentTextLineHeight: 1.4;

/* Onboarding */
$onboardingBorderTop: (small: solid $brandDarkBlue2, medium: solid $brandDarkBlue2, large: unset);
$onboardingPFontWeight: normal;
$onboardingBrandLogoBackground: url('../assets/images/aa_logo.svg') no-repeat center;
$onboardingBrandLogoHeight: 77px;
$onboardingBrandLogoFloat: none;
$onboardingBrandLogoBackgroundSize: (small: contain);
$onboardingBrandLogoWidth: 88%;
$onboardingBrandLogoMargin: (small: 0 auto 4.4rem);
$onboardingAddToFavoriteIconWidth: 1.7rem;
$onboardingAddToFavoriteIconHeight: 1.5rem;
$onboardingGetTheButtonBackground:  url('../assets/images/T2_AA_StaticButton.png') 50% 50%/185px 185px no-repeat;
$onboardingOptinLabelColor: $brandQuaternaryColor;
$onboardingStepLaterLinkColor: $brandQuaternaryColor;
$onboardingPageCounterPosition: absolute;
$onboardingPageCounterPositionTop: 10px;
$onboardingPageCounterPositionLeft: auto;
$onboardingPageCounterPositionRight: 1rem;
$onboardingPageCounterFontSize: 1.4rem;
$onboardingPageCounterColor: #3f3f3f;
$onboardingWelcomeStepHeaderMargin: (small: 0 auto 1.9rem, medium: 0 auto 1.9rem, large: 0 auto 1rem);
$onboardingWelcomeStepHeaderDotDisplay: inline;
$onboardingStepButtonFontWeight: normal;
$onboardingH2FontSize: (small: 3.2rem, large: 3rem);
$onboardingH2LineHeight: (small: 4.3rem, large: 3.9rem);
$onboardingH2FontWeight: bold;
$onboardingH2Color: $brandPrimaryGrey;
$onboardingPColor: $brandPrimaryGrey;
$onboardingPFontSize: (small: 1.8rem);
$onboardingPLineHeight: (small: 2.4rem, large: 2.3rem);
$onboardingH2Margin: (small: 0 auto 1rem);
$onboardingWelcomeDoneTop: 460px;
$onboardingWelcomeMarginTop: (small: 14.2rem, large: 12.2rem);
$onboardingStepButtonMargin: (small: 1.5rem 0 0, large: 2.5rem auto 1rem);
$onboardingStepButtonBorder: none;
$onboardingStepButtonWidth: (large: 24rem);
$onboardingStepLaterLinkColor: $brandDarkBlue3;
$onboardingStepLaterLinkFontWeight: normal;
$onboardingStepLaterLinkMargin: (small: auto, large: 0 auto);
$onboardingTurnOffAlertsColor: $brandPrimaryGrey;
$onboardingTurnOffMargin: (small: .1rem 0 0, large: 0);
$onboardingGetTheButtonHeight: (small: 140px, large: 181px);
$onboardingGetTheButtonMarginTop: 60px;
$onboardingGetTheButtonH2MarginBottom: 10px;
$onboardingGetTheButtonPMarginBottom: 70px;
$onboardingGetTheButtonListPadding: 1.1rem 0 .8rem;
$onboardingGetTheButtonListWidth: 42.2rem;
$onboardingGetTheButtonListLastItemPadding: 1.1rem 0 1.2rem;

$onboardingOptInHeaderDotDisplay: inline;
$onboardingOptInHeaderMargin: (small: 6.5rem auto 2.8rem, large: 0 auto 1rem);
$onboardingOptInHeaderPadding: 0 1.5rem;
$onboardingOptInHeaderFontSize: 3.2rem;
$onboardingOptInTextFontSize: 2rem;
$onboardingOptInTextLineHeight: 2.7rem;
$onboardingOptInTextMargin: 0 auto 3rem;
$onboardingOptInTextWidth: 352px;
$onboardingOptinLabelFontSize: 1.6rem;
$onboardingOptinLabelDotDisplay: inline;
$onboardingOptinLabelMargin: 0 0 0 .5rem;

$onboardingFavoritesMargin: (small: 1.6rem 0 0, large: 3rem 0 0);
$onboardingFavoritesHeadingFontSize: (small: 2.8rem, large: 3rem);
$onboardingFavoritesHeadingMargin: (small: 0 5rem .9rem, large: 0 auto 1rem);
$onboardingFavoritesHeadingLineHeight: (small: 3.2rem, medium: 3.2rem, large: 3.9rem);
$onboardingFavoritesTextFontSize: 1.8rem;
$onboardingFavoritesTextMargin: 0 0 1rem;
$onboardingFavoritesPickFavoritesMargin: (small: auto, large: auto);
$onboardingFavoritesPickFavoritesBorder: 1px solid #979797;
$onboardingFavoritesPickFavoritesBoxShadow: 0 3px 6px 0 rgba(0, 0, 0, .23), 0 3px 6px 0 rgba(0, 0, 0, .16);
$onboardingFavoritesPickFavoritesBorderWidth: (small: 1px 0 1px 0, large: 1px 1px 1px 1px);
$onboardingFavoritesPickFavoritesBackground: (small: #f7f7f7);
$onboardingFavoritesPickFavoritesPadding: (small: 1rem 0 0, large: 2.3rem 2.5rem 0);
$onboardingFavoritesPickFavoritesHeight: (small: 220px, large: 220px);
$onboardingFavoritesMerchantLogoWidth: (small: 160px, large: auto);
$onboardingFavoritesMerchantLogoPadding: (small: 0, large: .1rem);
$onboardingFavoritesMerchantLogoMargin: (small: 0 0 1rem, large: .7rem 1.5rem);
$onboardingFavoritesPotentialFavoriteWidth: 135px;
$onboardingFavoritesPotentialFavoriteHeight: 56px;
$onboardingFavoritesPotentialFavoriteMargin: (medium: none);
$onboardingFavoriteIconFontSize: 1.7rem;
$onboardingFavoriteIconPositionTop: (small: 5px, large: 16px);
$onboardingFavoriteIconPositionRight: (small: 5px, large: 6px);
$onboardingFavoriteIconWidth: 1.8rem;
$onboardingFavoriteIconHeight: (small: 1.8rem, large: 1.8rem);
$onboardingFavoriteStepBottomContentMinHeight: (small: unset, large: auto);
$onboardingFavoriteLaterLinkAfterDisplay: none;
$onboardingFavoriteLaterLinkDotDisplay: inline;
$onboardingStepLaterLinkColor: $brandPrimaryBlue;
$onboardingFavoriteStepButtonMargin: (small: 1.5rem 0 0, large: 2.5rem auto 1rem);
$onboardingFavoritesCarouselArrowNextBeforeContent: '';
$onboardingFavoritesCarouselArrowNextBeforeBackground: url('~core/assets/images/arrow-right.svg') 0 0 no-repeat;
$onboardingFavoritesCarouselArrowPrevBeforeContent: '';
$onboardingFavoritesCarouselArrowPrevBeforeBackground: url('~core/assets/images/arrow-left.svg') 0 0 no-repeat;
$onboardingFavoritesCarouselArrowDisplay: inline-block;
$onboardingFavoritesCarouselArrowHeight: 35px;
$onboardingFavoritesCarouselArrowWidth: 20px;
$onboardingFavoritesCarouselArrowNextPositionRight: -1.8rem;
$onboardingFavoritesCarouselArrowPrevPositionLeft: -1.8rem;
$onboardingFavoritesCarouselArrowNextTop: calc(40% + 4px);
$onboardingFavoritesCarouselArrowPrevTop: calc(40% + 4px);
$onboardingFavoritesPickFavoritesBorder: 2px solid $brandGray5;
$onboardingFavoritesPickFavoritesBorderWidth: (small: 2px 0 2px, large: 2px 2px 2px 2px);
$onboardingFavoritesPickFavoritesBoxShadow: none;
$onboardingCongratsHeadingFontWeight: (small: normal);
$onboardingCongratsHeadingPaddingTop: (small: 13rem, medium: 12rem, large: 2.8rem);
$onboardingCongratsHeadingFlexDirection: (small: column, medium: column, large: unset);
$onboardingCongratsImgHeight: 10rem;
$onboardingCongratsImgWidth: 10rem;
$onboardingCongratsImgMargin: 0 auto 1.5rem;
$onboardingCongratsPMargin: (small: 1.5rem 8.3rem 0, medium: 1.5rem 4.5rem 0);
$headerOnboardingCongratsIconDisplay: inline;
$headerOnboardingCongratsIconMargin: 0 1rem -.5rem 0;
$headerOnboardingCongratsIconHeight: 4rem;
$headerOnboardingCongratsIconWidth: 4rem;

$onboardingBottomContentPosition: (small: absolute, large: absolute);
$onboardingBottomContentPositionTop: (small: (), large: 374px);
$onboardingBottomContentPositionBottom: (small: 0, large: ());
$onboardingFavoriteStepBottomContentPosition: (small: static, large: ());
$onboardingBottomContentTop: 400px;
$onboardingBottomContentPadding: (small: 2rem);
$onboardingAddToFavoriteWrapRight: 6px;
$onboardingAddToFavoriteWrapTop: 16px;
$onboardingAddToFavoriteIconFontSize: 1.7rem;
$onboardingFavoritesItemsWrapPadding: 2.3rem 0 0;
$onboardingCarouselButtonsPositionTop: calc(50% + 4px);
$onboardingOptinH2FontSize: 3.2rem;
$onboardingOptinPFontSize: 2rem;
$onboardingOptinPLineHeight: 2.7rem;
$onboardingOptinLabelFontWeight: normal;
$onboardingOptinPMargin: 0 0 3rem;
$onboardingOptInImageHeight: 142px;
$onboardingOptInImageWidth: 142px;
$onboardingOptinCheckboxLabelBeforeBorder: none;
$onboardingOptinCheckboxLabelBeforeWidth: 22px;
$onboardingOptinCheckboxLabelBeforeHeight: 22px;
$onboardingOptinCheckboxLabelFontFamily: $brandFontFamily;
$onboardingOptinCheckboxLabelPaddingLeft: 3rem;
$onboardingOptInImageBackground: url('../assets/images/emailOptIn.png') no-repeat center;
$onboardingOptInImageMargin: (large: 0 auto 32px);
$onboardingStepButtonOptinDisableColor: $brandGray4;
$onboardingStepButtonOptinDisableBackground: $brandGray;

$onboardingButtonStepHeadingMargin: (small: 3.7rem 1rem .5rem);
$onboardingButtonStepBulletTextFontWeight: 600;
$onboardingButtonStepLaterLinkAfterDisplay: none;
$onboardingButtonStepBulletTextDisplay: (large: list-item);
$onboardingButtonStepBulletTextFontSize: (small: 1.4rem, medium: 1.3rem, large: 1.4rem);
$onboardingButtonStepHeadingFontSize: (medium: 3.2rem, large: 3.6rem);
$onboardingButtonStepHeadingLineHeight: (medium: 4rem, large: 4.4rem);
$onboardingButtonStepBulletTextWidth: 36.3rem;
$onboardingButtonStepDescriptionFlexDirection: (small: column, large: unset);
$onboardingButtonStepListWidth: (small: 100%, large: 43.7rem);
$onboardingButtonStepBulletTextWidth: 100%;
$onboardingButtonStepBulletTextBorderBottom: 1px solid rgba(137, 137, 137, .3);
$onboardingButtonStepButtonMargin: (large: 2.5rem auto 1rem);
$onboardingButtonStepBulletTextPadding: 1.1rem 0 .8rem;
$onboardingButtonStepBulletLastChildTextBorderBottom: 1px solid rgba(137, 137, 137, .3);

/* Favorites */
$brandUnselectedFavoriteOpacity: 0;

/* Favorites Icon */
$favoriteIconFontBeforeContent: '\f08a';
$favoriteIconFontBeforeDisplay: inline-block;
$favoriteIconFontBeforeFontFamily: 'fontello', serif;
$favoriteIconFontBeforeFontStyle: normal;
$favoriteIconFontBeforeFontSize: 2rem;
$favoriteIconFontBeforeColor: $brandPrimaryRed;
$favoriteIconFontFavoritedBeforeContent: '\f004';
$favoriteIconWidth: 2.1rem;
$favoriteIconHeight: 2.1rem;

/* Balloon tooltip */
$balloonTooltipBackground: $brandWhite;
$balloon-bg: $balloonTooltipBackground;
$balloon-color: $brandTextColor;

/* Copy To Clipboard Tooltip */
$copyToClipboardTooltipAfterColor: $brandWhite;
$copyToClipboardTooltipAfterPadding: 1.2rem 1.5rem;
$copyToClipboardTooltipAfterBackground: rgba(0, 0, 0, .8);
$copyToClipboardTooltipAfterFontWeight: bold !important;
$balloonSVGCopyFillColor: '%23000000';
$balloonSVGCopyStrokeColor: '%23000000';
$balloonSVGCopyStrokeOpacity: .8;

/* Trending And Seasonal Offers */
$trendingAndSeasonalOffersBackground: (small: $brandGray2, medium: none);

/* Merchant Tile */
$merchantTileFavoriteIconPositionTop: (small: .8rem, medium: .4rem);
$merchantTileFavoriteIconPositionRight: (small: .8rem, medium: 1rem);
$merchantTileBorderRadius: 0;

/* Merchant With Offer Count Tile */
$merchantWithOfferCountImageMargin: 2.5rem auto .7rem;
$merchantWithOfferCountBorder: (small: 1px solid $brandBorder3);
$merchantWithOfferCountH3Color: $brandTextColor;
$merchantWithOfferCountH3LineHeight: 2rem;
$merchantWithOfferCountRebateBackground: (small: linear-gradient(-10deg, $brandGray2 50%, $brandWhite 50.5%, rgba(0, 0, 0, 0) 50.5%));
$merchantWithOfferCountTilePositionTop: 0;
$merchantWithOfferCountTilePositionBottom: 0;
$merchantWithOfferCountTilePositionLeft: 0;
$merchantWithOfferCountTilePositionRight: 0;
$merchantWithOfferCountRebatePositionTop: unset;
$merchantWithOfferCountRebatePositionBottom: 0;
$merchantWithOfferCountRebateHeight: 17rem;
$merchantWithOfferCountRebateAlignItems: flex-end;
$merchantWithOfferCountRebateNewValueFontSize: 1.4rem;
$merchantWithOfferCountRebatePadding: 0 0 1.2rem;
$merchantWithOfferCountPillWidth: 9rem;
$merchantWithOfferCountPillPositionBottom: 7rem;
$merchantWithOfferCountFavoriteIconPositionTop: .8rem;
$merchantWithOfferCountFavoriteIconPositionRight: .8rem;

/* Earning Snapshot */
$earningsSnapshotSectionBackground: (small: $brandDarkBlue2);
$earningsSnapshotSummaryTextAlign: center;
$earningsSnapshotSummaryFontWeight: 400;
$earningsSnapshotTransactionColor: $brandTextColor;
$earningsSnapshotTransactionBorder: 1px solid $brandSecondaryGray;
$earningsSnapshotTransactionMargin: 0 0 1rem;
$earningsSnapshotTransactionsMargin: 0 0 3rem;
$earningsSnapshotTransactionMerchantFontSize: 1.4rem;
$earningsSnapshotTransactionInfoFontSize: 1.4rem;
$earningsSnapshotTransactionMerchantColor: $brandPrimaryColor;
$earningsSnapshotTransactionMerchantFontWeight: 700;
$earningsSnapshotTransactionInfoTopFontWeight: 400;
$earningsSnapshotTransactionInfoBottomFontWeight: 700;
$earningsSnapshotTransactionInfoTopLineHeight: 2rem;
$earningsSnapshotTransactionInfoBottomLineHeight: 2rem;
$earningsSnapshotButtonBorder: 1px solid $brandWhite;
$earningsSnapshotButtonFontWeight: 500;
$earningsSnapshotButtonPadding: ();
$earningsSnapshotButtonFontSize: 1.8rem;
$earningsSnapshotButtonPadding: 1.02rem 5rem;
$earningsSnapshotSectionTitleH2FontSize: (small: 3rem);
$earningsSnapshotSectionTitleH2LineHeight: (small: 3.6rem);

/* Explore Categories */
$exploreCategoriesSectionPadding: (small: 6rem 2.5rem 0);
$exploreCategoriesSectionBackground: (small: linear-gradient(0deg, $brandDarkBlue2 0%, $brandDarkBlue3 100%));
$exploreCategoriesListBorderBottom: 1px solid rgba(208, 218, 224, .25);
$exploreCategoriesListPadding: 0 0 5rem;
$exploreCategoriesItemLinkBackground: $brandWhite;
$exploreCategoriesItemLinkBorder: 1px solid $brandGray3;
$exploreCategoriesItemLinkColor: $brandTextColor;
$exploreCategoriesItemLinkFontSize: 1.4rem;
$exploreCategoriesItemLinkFontWeight: 600;
$exploreCategoriesItemLinkLineHeight: 2rem;
$exploreCategoriesIconBackground: $brandTextColor no-repeat 50% 40%;
$exploreCategoriesIconBackgroundSize: 5rem;
$exploreCategoriesItemLinkPadding: 0;
$exploreCategoriesListGap: 2.5rem;
$exploreCategoriesSectionTitleH2FontWeight: (small: 400);
$exploreCategoriesIconBeforeFontFamily: 'aa_categories';
$exploreCategoriesIconBeforeColor: $brandWhite;
$exploreCategoriesIconBeforeDepartmentStoresContent: '\e815';
$exploreCategoriesIconBeforeComputersElectronicContent: '\e804';
$exploreCategoriesIconBeforeWomensClothingContent: '\e81b';
$exploreCategoriesIconBeforeMensClothingContent: '\e812';
$exploreCategoriesIconBeforeBeautyScincareContent: '\e802';
$exploreCategoriesIconBeforeShoesContent: '\e814';
$exploreCategoriesIconBeforeSportsOutdoorsContent: '\e817';
$exploreCategoriesIconBeforeFlowersGiftsContent: '\e807';
$exploreCategoriesIconBeforeBabiesKidsToysContent: '\e801';
$exploreCategoriesIconBeforeHomeKitchenContent: '\e80e';
$exploreCategoriesIconBeforeHealthWellnessContent: '\e80d';
$exploreCategoriesIconBeforeToolsAutoPetsContent: '\e818';
$exploreCategoriesIconBeforeDepartmentStoresPositionTop: 30%;
$exploreCategoriesIconBeforeDepartmentStoresPositionLeft: 14%;
$exploreCategoriesIconBeforePositionTop: 28%;
$exploreCategoriesIconBeforePositionLeft: 13%;

/* Sticky Footer */
// Mobile
$stickyFooterMobileBackground: $brandDarkBlue3;
$stickyFooterMobileContentBorderTop: none;
$stickyFooterMobileContentBorderBottom: 1px solid rgba(208, 218, 224, .25);
$stickyFooterMobileContentPadding: 1rem 0 8.8rem;
$stickyFooterMobileUseCodeFontSize: 1rem;
$stickyFooterMobileUseCodeLineHeight: 1.8rem;
$stickyFooterMobileCodeFontSize: 1.2rem;
$stickyFooterMobileCodeLetterSpacing: .64px;
$stickyFooterMobileShopNowFontSize: 1.4rem;
$stickyFooterMobileShopNowAfterContent: '\f105';
$stickyFooterMobileShopNowAfterFontFamily: 'fontello', serif;
$stickyFooterMobileShopNowAfterFontSize: 2rem;
$stickyFooterMobileMerchantNameMargin: 0 0 1rem;
$stickyFooterDesktopWrapBackground: $brandPrimaryGrey;

/* Email OptIn MHP */
$emailOptInWrapperSectionPadding: (small: 6rem 3.5rem);
$emailOptInImageBackground: url('../assets/images/AA_hiw_email.png') no-repeat 50% 0/contain;
$emailOptInWrapperSectionBackground: (small: $brandGray2);
$emailOptInImageWidth: 16rem;
$emailOptInImageHeight: 16rem;
$emailOptInWrapperHeight: 55rem;
$emailOptInHeadingFontSize: 3rem;
$emailOptInHeadingLineHeight: 3.6rem;
$emailOptInHeadingDotDisplay: none;
$emailOptInHeadingMargin: 0 0 1.2rem;
$emailOptInTextLineHeight: 2.2rem;
$emailOptInTextMargin: 0 0 5rem;
$emailOptInSwitcherWrapperPositionBottom: 9.5rem;
$emailOptInSwitcherWrapperWidth: 26rem;
$emailOptInWrapperOptedInTextMargin: 4rem;
$emailOptInWrapperOptedInBackground: $brandGray3;
$emailOptInHeadingExclamationDisplay: none;
// Switcher
$switcherBackground: rgba(120, 120, 128, .16);
$switcherCheckedBackground: $brandPrimaryColor;

/* Pick Favorites */
$pickFavoritesBackground: $brandGray2;
$pickFavoritesButtonFontSize: 1.8rem;
$pickFavoritesButtonBackground: linear-gradient(180deg, $brandQuaternaryColor 0%, #536773 100%);
$pickFavoritesButtonPadding: 1.2rem 5rem;
$pickFavoritesButtonLineHeight: 2.4rem;
$pickFavoritesButtonFontWeight: 500;
$pickFavoritesMerchantLogoTileInnerWrapBoxShadow: 1px 1px 2px 0 rgba(0, 0, 0, .4);
$pickFavoritesMerchantLogoTileFavoriteIconWidth: 2rem;
$pickFavoritesMerchantLogoTileFavoriteIconHeight: 2rem;
$pickFavoritesMerchantLogoTileFavoriteIconTop: .5rem;
$pickFavoritesMerchantLogoTileFavoriteIconRight: .5rem;
$pickFavoritesMerchantLogoTileFavoriteIconFontSize: 1.5rem;

/* Marketing Merchants Home */
$marketingMerchantsHomeTileWidth: (small: 22.7rem, medium: 12rem);
$marketingMerchantsHomeTileHeight: (small: 23.7rem, medium: 13.3rem);
$marketingMerchantsHomeFavoriteIconPositionRight: 0;
$marketingMerchantsHomeFavoriteIconBeforeFontSize: 1.5rem;
$marketingMerchantsHomeTileImgMargin: 2rem auto 0;
$merchantTilePillBottom: 4.5rem;
$marketingMerchantsHomePillWidth: 6rem;
$marketingMerchantsHomePillLabelFontSize: .8rem;
$marketingMerchantsHomePillLabelLineHeight: 1.5rem;
$marketingMerchantsHomePillLabelPadding: 0 0 0 1rem;
$marketingMerchantsHomePillHeight: 1.5rem;
$marketingMerchantsHomePillLabelAfterWidth: 1.5rem;
$marketingMerchantsHomePillLabelAfterHeight: 1.5rem;
$marketingMerchantsHomeMerchantTileRebateHeight: 10.5rem;
$marketingMerchantsHomeMerchantTileRebatePadding: 0 0 1rem;
$marketingMerchantsHomeMerchantTileRebateElevationOldFontSize: 1.1rem;
$marketingMerchantsHomeMerchantTileRebateElevationOldLineHeight: 1.3rem;
$marketingMerchantsHomeMerchantTileRebateElevationNewFontSize: 1.1rem;
$marketingMerchantsHomeMerchantTileRebateElevationNewLineHeight: 1.4rem;
$marketingMerchantsHomeViewAllLinkInsideSliderLinkBackground: $brandDarkBlue2;
$marketingMerchantsHomeViewAllLinkInsideSliderBorder: 1px solid $brandBorder3;
$marketingMerchantsHomeViewAllLinkInsideSliderColor: $brandWhite;
$marketingMerchantsHomeViewAllLinkInsideSliderFontSize: 2.5rem;
$marketingMerchantsHomeViewAllLinkInsideSliderLineHeight: 3rem;
$marketingMerchantsHomeViewAllLinkHoverTextDecoration: none;

/* Popular Offers */
$popularOffersFlex: (xxlarge: 0 0 30rem);
$popularOffersBackground: (small: $brandTextColor, medium: none);
$popularOffersSectionTitleH2Color: (small: $brandWhite, medium: $brandTextColor);
$popularOfferImageBorder: (small: 1px solid $brandBorder3);
$popularOfferImageBorderWidth: 1px 1px 0 1px;
$popularOfferImageBorderRadius: 0;
$popularOfferRebateColor: $brandPrimaryColor;
$popularOfferRebateFontSize: (small: 1.4rem, medium: 1.4rem);
$popularOfferRebateFontWeight: (small: 700, medium: 700);
$popularOfferRebateValueAndPrefixColor: $brandPrimaryColor;
$popularOfferRebateValueAndPrefixFontWeight: (small: 700, medium: 700);
$popularOfferRebateBorder: (small: 1px solid $brandBorder3);
$popularOfferRebateBorderRadius: 0;
$popularOfferRebateBorderWidth: 1px 1px 1px 1px;
$popularOfferRebateHoverBackground: $brandLightGray;

/* Bonus Merchant Offer */
$bonusMerchantOfferBonusTagWrapBackground: $brandPrimaryColor;
$bonusMerchantOfferBonusTagWrapColor: $brandWhite;
$bonusMerchantOfferBonusTagWrapFontWeight: 500;
$bonusMerchantOfferBorderRadius: 3px;
$bonusMerchantOfferRebatePrefixTextTransform: lowercase;

/* Featured Deals */
$featuredDealsButtonMargin: 5rem 0 0;
// Rounded View
$featuredDealMobileRoundedMerchantLogoWrapMinWidth: 8.1rem;
$featuredDealMobileRoundedMerchantLogoWrapHeight: 8.1rem;
$featuredDealMobileRoundedBorderBottom: 1px solid rgba(54, 73, 90, .15);
$featuredDealMobileRoundedMerchantLogoWrapBoxShadow: .5px .5px 2px 0 rgba(0, 0, 0, .4);
$featuredDealMobileRoundedLinkPadding: 2.5rem 0 5rem;
$featuredDealMobileRoundedMerchantNameMargin: .5rem 0 1rem;
$featuredDealMobileRoundedMerchantNameFontWeight: 400;
$featuredDealMobileRoundedMerchantNameColor: $brandTextColor;
$featuredDealMobileRoundedMerchantNameLineHeight: 2.6rem;
$featuredDealMobileRoundedRebatePrefixFontWeight: bold;
$featuredDealMobileRoundedCouponCodeFontSize: 1.2rem;
$featuredDealMobileRoundedCouponCodeLineHeight: 1.8rem;
$featuredDealMobileRoundedCouponCodeLetterSpacing: .64px;
$featuredDealMobileRoundedCouponCodeColor: $brandPrimaryColor;
$featuredDealMobileRoundedCouponCodeLabelFontSize: 1.2rem;
$featuredDealsButtonPadding: 1.3rem 5rem;
$featuredDealsButtonFontSize: 1.8rem;
$featuredDealsButtonLineHeight: 2.4rem;
$featuredDealsButtonFontWeight: 500;
// Desktop View
$featuredDealsContainerGap: 2rem;
$featuredDealDesktopOverlayLinkPadding: 2.5rem 1.5rem 7rem 1.5rem;
$featuredDealDesktopWidth: 23.4rem;
$featuredDealDesktopBorder: 1px solid $brandGray3;
$featuredDealDesktopBoxShadow: 0 2px 4px 0 rgba(255, 255, 255, .5);
$featuredDealDesktopOfferDetailsFontSize: 1.2rem;
$featuredDealDesktopColor: $brandQuaternaryColor;
$featuredDealDesktopExpireFontSize: 1.2rem;
$featuredDealDesktopRebateBackground: linear-gradient(-10deg, $brandGray2 50%, $brandWhite 50.5%, rgba(0, 0, 0, 0) 50.5%);
$featuredDealDesktopRebateHeight: 15.5rem;
$featuredDealDesktopRebatePadding: 9.5rem 0 0 0;
$featuredDealDesktopRebateMargin: 0;
$featuredDealDesktopRebateOldValueFontSize: 1.2rem;
$featuredDealDesktopCouponCodeLabelFontSize: 1.2rem;
$featuredDealDesktopCouponCodeColor: $brandPrimaryColor;
$featuredDealDesktopPillWidth: 8rem;
$featuredDealDesktopPillBottom: 6.7rem;
$featuredDealDesktopFavoriteIconWidth: 2rem;
$featuredDealDesktopFavoriteIconHeight: 1.8rem;

/* Top Stores Expandable */
$topStoresExpandableBackground: $brandPrimaryColor;
$topStoresExpandableMerchantTileMerchantRebateV2Background: none;
$topStoresExpandableMerchantTileBorder: 2px solid $brandBorder3;
$topStoresExpandableMerchantTileBoxShadow: 1px 1px 2px 0 rgba(0, 0, 0, .2);
$topStoresExpandableTextColor: $brandWhite;
$topStoresExpandableMerchantTileMerchantRebateV2PositionTop: 13rem;
$topStoresExpandableMerchantTileMerchantRebateV2ElevationNewFontSize: 1.4rem;
$topStoresExpandableButtonContainerMargin: 2rem 0 0;
$topStoresExpandableButtonBackground: none;
$topStoresExpandableButtonBorder: 1px solid $brandWhite;
$topStoresExpandableButtonPadding: 1.22rem 5rem;
$topStoresExpandableButtonFontSize: 1.8rem;
$topStoresExpandableButtonLineHeight: 2.4rem;
$topStoresExpandableButtonFontWeight: 500;
$topStoresExpandableMerchantPillWidth: 100%;
$topStoresExpandableMerchantPillLabelAfterDisplay: none;
$topStoresExpandableMerchantPillLabelPadding: 0;
$topStoresPersonalizedMhpMerchantTileTitleColor: (small: $brandPrimaryGrey);

/* Top Stores */
$topStoresMerchantTileFavoriteIconPositionRight: 0;
$topStoresMerchantTileFavoriteIconBeforeFontSize: 1.5rem;
$topStoresMerchantTileWidth: 12rem;
$topStoresMerchantTileHeight: 13.3rem;
$topStoresMerchantTilePillLabelFontSize: (small: .8rem, medium: 1.2rem);
$topStoresMerchantTilePillLabelLineHeight: 1.5rem;
$topStoresMerchantTilePillWidth: 6rem;
$topStoresMerchantTilePillHeight: 1.5rem;
$topStoresMerchantTilePillLabelPadding: 0 0 0 1rem;
$topStoresMerchantTilePillLabelAfterWidth: 1.5rem;
$topStoresMerchantTilePillLabelAfterHeight: 1.5rem;
$topStoresMerchantTilePillPositionBottom: 4.5rem;
$topStoresMerchantTileRebateHeight: 10.5rem;
$topStoresMerchantTileRebatePadding: 0 0 1rem 0;
$topStoresMerchantTileRebateElevationOldFontSize: 1.1rem;
$topStoresMerchantTileRebateElevationOldLineHeight: 1.3rem;
$topStoresMerchantTileRebateElevationNewFontSize: 1.1rem;
$topStoresMerchantTileRebateElevationNewLineHeight: 1.4rem;

/* Favorite Stores */
$favoriteStoresMerchantTileHeight: (small: 19rem, medium: 18.3rem);
$favoriteStoresMerchantTileBorder: (medium: 1px solid #d4d4d0);
$favoriteStoresMerchantTileRebateHeight: (small: 14.7rem, medium: 13.7rem);
$favoriteStoresMerchantTileRebatePadding: 0 0 1.2rem;
$favoriteStoresMerchantTileRebateOldValueLineHeight: 1.8rem;
$favoriteStoresMerchantTileRebateNewValueFontSize: (medium: 1.8rem);
$favoriteStoresMerchantTileRebateNewValueLineHeight: 2.4rem;
$favoriteStoresMerchantTilePillWidth: 12.2rem;
$favoriteStoresMerchantTilePillPositionBottom: (small: 6.5rem, medium: 6.5rem);
$favoriteStoresManageFavoritesTileHeight: (small: 19rem, medium: 18.3rem);
$favoriteStoresManageFavoritesTileBackground: (small: $brandDarkBlue2, medium: $brandDarkBlue2);
$favoriteStoresManageFavoritesTileFontSize: (small: 2.5rem, medium: 3rem);
$favoriteStoresManageFavoritesTileTextTextAlign: (small: center, medium: left);
$favoriteStoresManageFavoritesTilePadding: (small: 5.2rem 2.3rem, medium: 1.5rem);
$favoriteStoresManageFavoritesTileWrapHeight: (small: 100%, medium: 18.3rem);
$favoriteStoresManageFavoritesTileBorderRadius: (small: 0, medium: 0);
$favoriteStoresManageFavoritesTileArrowFontFamily: (small: (), medium: 'fontello');
$favoriteStoresManageFavoritesTileArrowContent: (small: (), medium: '\f105');
$favoriteStoresManageFavoritesTileArrowFontSize: (small: (), medium: 3rem);

/* Info Bar */
// Slider
$infoBarSliderOfferBorderRadius: 4px 4px 0 0;
$infoBarSliderOfferDOTWBackground: #033157;
$infoBarSliderOfferLabelFontSize: 1.4rem;
$infoBarSliderOfferLabelTextTransform: ();
$infoBarSliderOfferLabelLineHeight: 1.9rem;
$infoBarSliderOfferLabelTextAlign: center;
$infoBarSliderOfferLabelPadding: 1.4rem .6rem .4rem;
$infoBarSliderOfferBackground: #1a94ff;
$infoBarSliderOfferWrapColor: $brandTextColor;
$infoBarSliderOfferRolloverCopyLineHeight: 2rem;
$infoBarSliderOfferWrapBorder: 1px solid $brandBorder3;
$infoBarSliderOfferItemCopyTextAlign: center;
$infoBarSliderOfferCouponCodeWrapAlignItems: center;
$infoBarSliderOfferItemExpireTop: 62%;
$infoBarSliderOfferItemExpireColor: #9da6ab;
$infoBarSliderOfferItemExpireWidth: 130px;
$infoBarSliderOfferItemExpireLeft: calc(50% - #{$infoBarSliderOfferItemExpireWidth} / 2);
$infoBarSliderOfferCouponCodeFontSize: 1.1rem;
$infoBarSliderOfferCouponCodeLetterSpacing: 0;
$infoBarSliderOfferCouponCodeLineHeight: 2rem;
$infoBarSliderOfferCouponCodeColor: $brandPrimaryColor;
$infoBarSliderOfferRebateBottom: 0;
$infoBarSliderOfferRebateLeft: 0;
$infoBarSliderOfferRebateWidth: 100%;
$infoBarSliderOfferRebateHeight: 17.6rem;
$infoBarSliderOfferRebateBackground: linear-gradient(-10deg, $brandGray2 50%, $brandWhite 50.5%, rgba(0, 0, 0, 0) 50.5%);
$infoBarSliderOfferRebateBlockDisplay: flex;
$infoBarSliderOfferRebateBlockAlignItems: flex-end;
$infoBarSliderOfferRebateBlockJustifyContent: center;
$infoBarSliderOfferRebateNewValueColor: $rebateValueColor;
$infoBarSliderOfferPillWidth: 9rem;
$infoBarSliderFavoriteIconTop: .5rem;
$infoBarSliderOfferItemExpireTextAlign: center;

// Accordion
$infoBarAccordionBoxShadow: (small: 0 0 0 1px $brandGray3);
$infoBarAccordionColor: $brandPrimaryGrey;
$infoBarAccordionDotwLabelHeight: 2.2rem;
$infoBarAccordionDotwLabelWidth: 10.1rem;
$infoBarAccordionDotwLabelTop: -1.1rem;
$infoBarAccordionDotwLabelFontSize: 1.1rem;
$infoBarAccordionDotwLabelBackground: #faaf00;
$infoBarAccordionDotwLabelColor: $brandPrimaryGrey;
$infoBarAccordionDotwLabelBorderRadius: 0 3px 3px 0;
$infoBarAccordionDotwLabelTextTransform: none;
$infoBarAccordionDotwLabelLetterSpacing: 0;
$infoBarAccordionDotwLabelLeft: -.5rem;
$infoBarAccordionDotwLabelLineHeight: 2.2rem;
$infoBarAccordionOfferTextLineHeight: (medium: 1.8rem);
$infoBarAccordionOfferTextMargin: (medium: 0 0 1rem);
$infoBarAccordionOfferTextFontSize: (small: 1.2rem, medium: 1.4rem, xxlarge: 1.4rem);
$infoBarAccordionOfferShortSummaryColor: $brandQuaternaryColor;
$infoBarAccordionOfferShortSummaryTextAlign: (medium: left, large: center, xxlarge: center);
$infoBarAccordionOfferRebateColor: $brandPrimaryColor;
$infoBarAccordionOfferRebateValueColor: $brandPrimaryColor;
$infoBarAccordionOfferRebateOldValueFontSize: (small: 1.2rem, medium: 1.4rem, large: 1.4rem, xlarge: 1.4rem, xxlarge: 1.4rem);
$infoBarAccordionOfferRebateFontSize: (small: 1.2rem, medium: 1.4rem, large: 1.4rem, xlarge: 1.4rem, xxlarge: 1.4rem);
$infoBarAccordionOfferCouponCodeAndRebateColor: $brandQuaternaryColor;
$infoBarAccordionOfferCouponCodeAndRebateCodeColor: $brandPrimaryColor;
$infoBarAccordionOfferCTABackground: none;
$infoBarAccordionOfferCTAColor: $brandPrimaryColor;
$infoBarAccordionOfferCTATextFontSize: 1.8rem;
$infoBarAccordionOfferCTATextFontWeight: 700;
$infoBarAccordionOfferCTATextBeforeContent: '';
$infoBarAccordionOfferCTATextBeforeBorderBottom: 96px solid $brandGray3;
$infoBarAccordionOfferCTATextBeforeBorderLeft: 37px solid transparent;
$infoBarAccordionOfferCTATextBeforeBorderRadius: 0 3px 3px 0;
$infoBarAccordionOfferCTAHoverTextDecoration: underline;
$infoBarAccordionSecondOfferBackground: $brandWhite;
$infoBarAccordionSecondOfferBoxShadow: 2px 2px 0 0 #8eadc6;
$infoBarAccordionSecondOfferBorderRadius: 3px;
$infoBarAccordionSecondOfferShortSummaryColor: $brandQuaternaryColor;
$infoBarAccordionSecondOfferRebateColor: $brandPrimaryBlue;
$infoBarAccordionExpandIconBeforeContent: '\e071';
$infoBarAccordionExpandIconBeforeFontFamily: 'AmericanIcons';
$infoBarAccordionExpandIconBeforeColor: #8eadc6;
$infoBarAccordionExpandIconBeforeFontSize: 1.9rem;
$infoBarAccordionOfferRebateOldValueAfterContent: ',';
$infoBarAccordionCollapsedOfferRebateFontSize: (small: 1.2rem, medium: 1.4rem);
$infoBarAccordionCollapsedOfferRebateValueFontSize: (small: 1.2rem, medium: 1.4rem);
$infoBarAccordionCollapsedOfferRebateOldValueFontSize: (small: 1.2rem, medium: 1.4rem);
$infoBarAccordionCollapsedOfferRebateNewValueFontSize: (small: 1.2rem, medium: 1.4rem);
$infoBarAccordionCollapsedOfferBorderRadius: 3px;
$infoBarAccordionCollapsedOfferBoxShadow: 2px 2px 0 0 #8eadc6;
$infoBarAccordionOfferBoxShadow: 2px 2px 0 0 #8eadc6;
$infoBarAccordionOfferBorderRadius: 3px;
$infoBarAccordionOfferFirstZIndex: 1;
$infoBarAccordionOfferShortSummaryFontSize: (small: 1.2rem, medium: 1.2rem, large: 1.2rem, xxlarge: 1.2rem);
$infoBarAccordionSecondOfferPlusSignDisplay: inline;
$infoBarAccordionSecondOfferPlusSignFontSize: 1.5rem;
$infoBarAccordionSecondOfferPlusSignFontWeight: 700;
$infoBarAccordionSecondOfferPlusSignPadding: 0 .5rem 0 0;
$infoBarAccordionSecondOfferPlusSignColor: $brandPrimaryColor;
$infoBarAccordionOfferCouponCodeAndRebateDividerBorderRight: none;
$infoBarAccordionOfferCouponCodeAndRebateDividerHeight: unset;
$infoBarAccordionOfferCouponCodeAndRebateDividerMargin: 0 .8rem;
$infoBarAccordionOfferCouponCodeAndRebateDividerAfterContent: '|';
$infoBarAccordionOfferCouponCodeAndRebateDividerAfterFontSize: 1.4rem;
$infoBarAccordionOfferCouponCodeAndRebateDividerAfterColor: $brandQuaternaryColor;

/* InfoBar Marketing */
$infoBarMarketingTilePadding: 5px;
$infoBarMarketingTileBorderRadius: 4px;
$infoBarMarketingContentBackground: linear-gradient(180deg, $brandWhite 0, $brandSecondaryGray 100%);
$infoBarMarketingContentBorder: 1px solid $brandSecondaryGray;
$infoBarMarketingContentTextAlign: (small: center);
$infoBarMarketingCountdownWrapBackground: $brandQuaternaryColor;
$infoBarMarketingCountdownWrapWidth: (small: calc(100% + 4.2rem));
$infoBarMarketingCountdownClockPrefixFontWeight: 700;
$infoBarMarketingCountdownClockPrefixLetterSpacing: 0;
$infoBarMarketingCountdownDigitFontWeight: 700;
$infoBarMarketingCountdownDigitAfterFontWeight: 700;
$infoBarMarketingCountdownDigitBeforeDisplay: inline-block;
$infoBarMarketingCountdownDigitBeforeBorderRight: 1px solid $brandBorderColor;
$infoBarMarketingCountdownDigitBeforeHeight: 2.3rem;
$infoBarMarketingCountdownDigitBeforePadding: 0 0 0 .7rem;
$infoBarMarketingCountdownDigitBeforeMargin: 0 .7rem -.5rem 0;
$infoBarMarketingHeaderMargin: (small: 1.5rem 0);
$infoBarMarketingHeaderLetterSpacing: -.56px;
$infoBarMarketingHeaderLineHeight: (small: 2.4rem, xlarge: 3.2rem);
$infoBarMarketingHeaderColor: $brandDarkBlue2;
$infoBarMarketingTextFontSize: (small: 1.4rem, xlarge: 1.6rem);
$infoBarMarketingTextLineHeight: (small: 2.2rem);
$infoBarMarketingTextColor: $brandPrimaryGrey;
$infoBarMarketingButtonMargin: (medium: auto 0 1rem 0, xlarge: auto 0 2rem 0);
$infoBarMarketingButtonPadding: 1.2rem;
$infoBarMarketingButtonFontWeight: 400;
$infoBarMarketingButtonWidth: 26rem;
$infoBarMarketingCountdownClockPrefixTextTransform: uppercase;

/* Letterboxes */
$heroCarouselMargin: (smallMobile: 0 0 3rem, mediumMobile: 0 0 3rem, smallTablet: (),
largeMobileAndSmallDesktop: 0 0 5rem, largeTabletAndXLargeDesktop: ());
$heroCarouselSliderCounterColor: (smallMobile: $brandWhite, mediumMobile: $brandWhite,
smallTablet: (), largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselTileWithExtraPillPadding: 0;
$heroCarouselTileWithExtraPillImagePaddingTop: calc(286 / 268 * 100%);
$heroCarouselBreakPointLeftImageWidths: (smallMobile: 420px, mediumMobile: 420px, smallTablet: 372px,
largeMobileAndSmallDesktop: 334px, largeTabletAndXLargeDesktop: 420px);
$heroCarouselBreakPointOfferBottomDetailsContentMinHeights: (smallTablet: 13rem, largeMobileAndSmallDesktop: 12rem, largeTabletAndXLargeDesktop: 10rem);
$heroCarouselItemBorder: (smallMobile: 1px solid $brandBorder3, mediumMobile: 1px solid $brandBorder3, smallTablet: (),
largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselOfferDetailsMinHeight: 12rem;
$heroCarouselBreakPointOfferDetailsPadding: (smallMobile: 5px 5px 5px 102px, mediumMobile: 10px 10px 10px 102px, smallTablet: .5rem 5.5rem .6rem 1.7rem,
largeMobileAndSmallDesktop: .5rem 3rem .6rem 1.9rem, largeTabletAndXLargeDesktop: 1rem 10rem .6rem 1.5rem);
$heroCarouselCopyColor: (smallMobile: $brandTextColor, mediumMobile: $brandTextColor, smallTablet: $brandDarkBlue2,
largeMobileAndSmallDesktop: $brandDarkBlue2, largeTabletAndXLargeDesktop: $brandDarkBlue2);
$heroCarouselBreakPointShortCopyFontSize: (smallMobile: 1.2rem, mediumMobile: 1.2rem, smallTablet: 2.6rem,
largeMobileAndSmallDesktop: 2rem, largeTabletAndXLargeDesktop: 3.2rem);
$heroCarouselBreakPointLongCopyFontSize: (smallMobile: 1.2rem, mediumMobile: 1.2rem, smallTablet: 1.7rem,
largeMobileAndSmallDesktop: 1.4rem, largeTabletAndXLargeDesktop: 2rem);
$heroCarouselBorderRadius: (smallMobile: 0, mediumMobile: 0, smallTablet: 3px,
largeMobileAndSmallDesktop: 3px, largeTabletAndXLargeDesktop: 3px);
$heroCarouselBreakPointCouponCodeFontSize: (smallMobile: 1.4rem, mediumMobile: 1.4rem, smallTablet: 1.4rem,
largeMobileAndSmallDesktop: 2rem, largeTabletAndXLargeDesktop: 2rem);
$heroCarouselCouponCodeLabelColor: (smallMobile: $brandTextColor, mediumMobile: $brandTextColor, smallTablet: $brandDarkBlue2,
largeMobileAndSmallDesktop: $brandDarkBlue2, largeTabletAndXLargeDesktop: $brandDarkBlue2);
$heroCarouselCouponCodeTextLineHeight: .9;
$heroCarouselCouponCodeTextLetterSpacing: (smallMobile: 0, mediumMobile: 0, smallTablet: 0,
largeMobileAndSmallDesktop: 0, largeTabletAndXLargeDesktop: 0);
$heroCarouselCouponCodeTextFontSize: (smallMobile: 1.4rem, mediumMobile: 1.4rem, smallTablet: 1.7rem,
largeMobileAndSmallDesktop: 1.3rem, largeTabletAndXLargeDesktop: 2rem);
$heroCarouselCouponCodeColor: (smallMobile: $brandPrimaryColor, mediumMobile: $brandPrimaryColor, smallTablet: $brandPrimaryColor,
largeMobileAndSmallDesktop: $brandPrimaryColor, largeTabletAndXLargeDesktop: $brandPrimaryColor);
$heroCarouselBreakPointCouponCodeFontSize: (smallMobile: 1.08rem, mediumMobile: 1.08rem, smallTablet: 1.7rem,
largeMobileAndSmallDesktop: 1.3rem, largeTabletAndXLargeDesktop: 2rem);
$heroCarouselBreakPointCouponCodeLineHeight: (smallMobile: 2rem, mediumMobile: 2rem, smallTablet: 2rem,
largeMobileAndSmallDesktop: 2.4rem, largeTabletAndXLargeDesktop: 2.4rem);
$heroCarouselBreakPointCouponCodeMargin: (smallMobile: .5rem 0 0, mediumMobile: .5rem 0 0, smallTablet: 0 0 1.5rem,
largeMobileAndSmallDesktop: 0, largeTabletAndXLargeDesktop: 1.5rem 0 0);
$heroCarouselBreakPointRebateFontSize: (smallMobile: 1.4rem, mediumMobile: 1.4rem, smallTablet: 1.7rem,
largeMobileAndSmallDesktop: 1.6rem, largeTabletAndXLargeDesktop: 2rem);
$heroCarouselBreakPointRebateFontWeight: (smallMobile: 700, mediumMobile: 700, smallTablet: 700,
largeMobileAndSmallDesktop: 700, largeTabletAndXLargeDesktop: 700);
$heroCarouselBreakPointRebateMarginBottom: (smallMobile: (), mediumMobile: (), smallTablet: (),
largeMobileAndSmallDesktop: 1.5rem, largeTabletAndXLargeDesktop: ());
$heroCarouselButtonFontWeight: 500;
$heroCarouselButtonMarginTop: (smallMobile: 0, mediumMobile: 0, smallTablet: 1.5rem,
largeMobileAndSmallDesktop: 2rem, largeTabletAndXLargeDesktop: .8rem);
$heroCarouselDisclaimerColor: (smallMobile: $brandBorderColor, mediumMobile: $brandBorderColor, smallTablet: $brandDarkGray2,
largeMobileAndSmallDesktop: $brandDarkGray2, largeTabletAndXLargeDesktop: $brandDarkGray2);
$heroCarouselDisclaimerBottomPosition: (smallMobile: 0, mediumMobile: 0, smallTablet: .6rem,
largeMobileAndSmallDesktop: .6rem, largeTabletAndXLargeDesktop: .6rem);
$heroCarouselInfobarMarketingCountdownBackgrond: (smallMobile: $brandPrimaryGrey, mediumMobile: $brandPrimaryGrey, smallTablet: $brandQuaternaryColor,
largeMobileAndSmallDesktop: $brandPrimaryGrey, largeTabletAndXLargeDesktop: $brandPrimaryGrey);
$heroCarouselInfobarMarketingHeaderPadding: (smallMobile: 1.7rem .5rem, mediumMobile: 1.7rem .5rem, smallTablet: 1.4rem .5rem,
largeMobileAndSmallDesktop: 1rem .5rem, largeTabletAndXLargeDesktop: 1.4rem .5rem);
$heroCarouselInfobarMarketingCountdownPrefixTextFontSize: (smallMobile: 1.6rem, mediumMobile: 1.6rem, smallTablet: 1.4rem,
largeMobileAndSmallDesktop: 1.4rem, largeTabletAndXLargeDesktop: 1.7rem);
$heroCarouselInfobarMarketingCountdownCounterBorderRight: 1px solid rgba(204, 204, 204, .5);
$heroCarouselInfobarMarketingHeaderFontSize: (smallMobile: 1.6rem, mediumMobile: 1.6rem, smallTablet: 1.4rem,
largeMobileAndSmallDesktop: 1.4rem, largeTabletAndXLargeDesktop: 2.4rem);
$heroCarouselInfobarMarketingCountdownLetterSpacing: (smallMobile: 0, mediumMobile: 0, smallTablet: 1px,
largeMobileAndSmallDesktop: 1px, largeTabletAndXLargeDesktop: 0);
$heroCarouselInfobarMarketingCountdownFontWeight: (smallMobile: 700, mediumMobile: 700, smallTablet: 700,
largeMobileAndSmallDesktop: 700, largeTabletAndXLargeDesktop: 700);
$heroCarouselInfobarMarketingCountdownLineHeight: (smallMobile: 1.8rem, mediumMobile: 1.8rem, smallTablet: 1.8rem,
largeMobileAndSmallDesktop: 1.8rem, largeTabletAndXLargeDesktop: 1.8rem);
$heroCarouselInfobarMarketingCountdownCounterFontSize: (smallMobile: 1.8rem, mediumMobile: 1.8rem, smallTablet: 1.8rem,
largeMobileAndSmallDesktop: 1.6rem, largeTabletAndXLargeDesktop: 1.6rem);
$heroCarouselInfobarMarketingCountdownCounterAfterFontSize: (smallMobile: 1.6rem, mediumMobile: 1.6rem, smallTablet: 1.4rem,
largeMobileAndSmallDesktop: 1.2rem, largeTabletAndXLargeDesktop: 1.2rem);
$heroCarouselInfobarMarketingTextJustifyContent: (smallMobile: flex-start, mediumMobile: flex-start, smallTablet: center,
largeMobileAndSmallDesktop: center, largeTabletAndXLargeDesktop: center);
$heroCarouselInfobarMarketingTextFlexGrow: (smallMobile: unset, mediumMobile: unset, smallTablet: 1,
largeMobileAndSmallDesktop: 1, largeTabletAndXLargeDesktop: 1);
$heroCarouselInfobarMarketingHeaderMargin: (smallMobile: 4rem 0 1rem, mediumMobile: 4rem 0 1rem, smallTablet: 1rem 0,
largeMobileAndSmallDesktop: 1rem 0, largeTabletAndXLargeDesktop: 1rem 0);
$heroCarouselInfobarMarketingHeaderFontSize: (smallMobile: 3rem, mediumMobile: 3rem, smallTablet: 2.8rem,
largeMobileAndSmallDesktop: 2.8rem, largeTabletAndXLargeDesktop: 2.4rem);
$heroCarouselInfobarMarketingHeaderLineHeight: (smallMobile: 3.6rem, mediumMobile: 3.6rem, smallTablet: 3.2rem,
largeMobileAndSmallDesktop: 3.2rem, largeTabletAndXLargeDesktop: 3.2rem);
$heroCarouselInfobarMarketingHeaderLetterSpacing: (smallMobile: 0, mediumMobile: 0, smallTablet: -.5px,
largeMobileAndSmallDesktop: -.56px, largeTabletAndXLargeDesktop: -.56px);
$heroCarouselInfobarMarketingCTAFontSize: (smallMobile: 1.8rem, mediumMobile: 1.8rem, smallTablet: 1.6rem,
largeMobileAndSmallDesktop: 1.4rem, largeTabletAndXLargeDesktop: 1.4rem);
$heroCarouselInfobarMarketingCTAPadding: (smallMobile: 1.1rem 0, mediumMobile: 1.1rem 0, smallTablet: 1.2rem 0,
largeMobileAndSmallDesktop: 1.12rem 0, largeTabletAndXLargeDesktop: 1.12rem 0);
$heroCarouselInfobarMarketingCTAFontWeight: 500;
$heroCarouselInfobarMarketingTextFontSize: (smallMobile: 1.6rem, mediumMobile: 1.6rem, smallTablet: 1.8rem,
largeMobileAndSmallDesktop: 1.6rem, largeTabletAndXLargeDesktop: 2rem);
$heroCarouselInfobarMarketingTextLineHeight: (smallMobile: 2.2rem, mediumMobile: 2.2rem, smallTablet: 2.8rem,
largeMobileAndSmallDesktop: 2.6rem, largeTabletAndXLargeDesktop: 3.2rem);
$heroCarouselInfobarMarketingTextColor: (smallMobile: $brandTextColor, mediumMobile: $brandTextColor, smallTablet: $brandTextColor,
largeMobileAndSmallDesktop: $brandTextColor, largeTabletAndXLargeDesktop: $brandTextColor);
$heroCarouselInfobarMarketingCountdownPrefixTextTransform: (smallMobile: lowercase, mediumMobile: lowercase, smallTablet: uppercase,
largeMobileAndSmallDesktop: uppercase, largeTabletAndXLargeDesktop: uppercase);
$heroCarouselInfobarMarketingCountdownPrefixFirstLetterTextTransform: uppercase;
$heroCarouselInfobarMarketingCountdownPrefixTextPadding: (smallMobile: 0 .5rem 0 0, mediumMobile: 0 .5rem 0 0, smallTablet: 0 .5rem 0 0,
largeMobileAndSmallDesktop: 0 .5rem 0 0, largeTabletAndXLargeDesktop: 0 .5rem 0 0);
$heroCarouselInfobarMarketingTextPadding: (smallMobile: 0 2.2rem, mediumMobile: 0 2.2rem, smallTablet: 0 2.2rem,
largeMobileAndSmallDesktop: 0 2.2rem, largeTabletAndXLargeDesktop: 0 2.2rem);
$heroCarouselInfobarMarketingPadding: (smallMobile: (), mediumMobile: (), smallTablet: .5rem,
largeMobileAndSmallDesktop: .5rem, largeTabletAndXLargeDesktop: .5rem);
$heroCarouselInfoBarMarketingContentBackground: (smallMobile: (), mediumMobile: (), smallTablet: linear-gradient(180deg, $brandWhite 0, $brandSecondaryGray 100%),
largeMobileAndSmallDesktop: linear-gradient(180deg, $brandWhite 0, $brandSecondaryGray 100%), largeTabletAndXLargeDesktop: linear-gradient(180deg, $brandWhite 0, $brandSecondaryGray 100%));
$heroCarouselInfobarMarketingContentBorder: (smallMobile: (), mediumMobile: (), smallTablet: 1px solid $brandSecondaryGray,
largeMobileAndSmallDesktop:1px solid $brandSecondaryGray, largeTabletAndXLargeDesktop: 1px solid $brandSecondaryGray);
$heroCarouselInfobarMarketingCTAMargin: (smallMobile: 2rem 0 0, mediumMobile: 2rem 0 0,
smallTablet: 0 0 1.5rem, largeMobileAndSmallDesktop: 0 0 1.5rem, largeTabletAndXLargeDesktop: 0 0 1.5rem);
$heroCarouselInfobarMarketingHeaderColor: (smallMobile: (), mediumMobile: (),
smallTablet: $brandDarkBlue2, largeMobileAndSmallDesktop: $brandDarkBlue2, largeTabletAndXLargeDesktop: $brandDarkBlue2);
$heroCarouselExtraRewardsPillPositionLeft: (smallMobile: -9.3rem, mediumMobile: -9.3rem);
$heroCarouselDotsPositionBottom: (smallTablet: 2.5rem, largeMobileAndSmallDesktop: 2rem, largeTabletAndXLargeDesktop: 2rem);
$heroCarouselBreakPointDotWidth: (smallTablet: 1rem, largeMobileAndSmallDesktop: 1rem, largeTabletAndXLargeDesktop: 1rem);
$heroCarouselBreakPointDotHeight: (smallTablet: 1rem, largeMobileAndSmallDesktop: 1rem, largeTabletAndXLargeDesktop: 1rem);
$heroCarouselBreakPointActiveDotBackground: (smallTablet: $brandDarkBlue2, largeMobileAndSmallDesktop: $brandDarkBlue2, largeTabletAndXLargeDesktop: $brandDarkBlue2);
$heroCarouselBreakPointDotBorderColor: (smallTablet: $brandDarkBlue2, largeMobileAndSmallDesktop: $brandDarkBlue2, largeTabletAndXLargeDesktop: $brandDarkBlue2);
$heroCarouselBreakPointDotBorderWidth: 1px;
$heroCarouselWrapBoxShadow: (smallMobile: (), mediumMobile: (), smallTablet: (0 0 4px 0 rgba(0, 0, 0, .15)),
largeMobileAndSmallDesktop: (0 0 4px 0 rgba(0, 0, 0, .15)), largeTabletAndXLargeDesktop: (0 0 4px 0 rgba(0, 0, 0, .15)));
$heroCarouselContainerMargin: (smallMobile: 0);
$heroCarouselArrowColor: $brandLightBlue2;
$heroCarouselArrowBackground: transparent;
$heroCarouselArrowFontSize: 3.5rem;
$heroCarouselImageAfterContent: '';
$heroCarouselOfferDetailsBeforeDisplay: block;
$heroCarouselItemPadding: (smallMobile: (), mediumMobile: (),
smallTablet: 0, largeMobileAndSmallDesktop: 0, largeTabletAndXLargeDesktop: 0);
$heroCarouselBreakPointHeights: (smallMobile: (), mediumMobile: (),
smallTablet: 26.1rem, largeMobileAndSmallDesktop: 23.4rem, largeTabletAndXLargeDesktop: 29.7rem);
$heroCarouselBreakPointLeftImageHeights: (smallMobile: (), mediumMobile: (),
smallTablet: 26.1rem, largeMobileAndSmallDesktop: 23.4rem, largeTabletAndXLargeDesktop: 29.7rem);
$heroCarouselDotMargin: (smallTablet: 0 0 .5rem .5rem, largeMobileAndSmallDesktop: 0 0 .5rem .5rem, largeTabletAndXLargeDesktop: 0 0 .5rem .5rem);
$heroCarouselDisclaimerPadding: (smallMobile: 0 10rem 0 0, mediumMobile: 0 10rem 0 0, smallTablet: 0 4rem 0 0,
largeMobileAndSmallDesktop: 0 4rem 0 0, largeTabletAndXLargeDesktop: 0 10rem 0 0);
$heroCarouselBreakPointDotBackground: (smallTablet: $brandWhite, largeMobileAndSmallDesktop: $brandWhite, largeTabletAndXLargeDesktop: $brandWhite);
$heroCarouselInfobarMarketingCountdownCounterFontWeight: (smallMobile: (), mediumMobile: (), smallTablet: 700,
largeMobileAndSmallDesktop: 700, largeTabletAndXLargeDesktop: 700);
$heroCarouselInfobarMarketingCountdownCounterAfterFontWeight: (smallMobile: (), mediumMobile: (), smallTablet: 700,
largeMobileAndSmallDesktop: 700, largeTabletAndXLargeDesktop: 700);
$heroCarouselBreakPointOfferDetailsWidths: (smallMobile: (), mediumMobile: (), smallTablet: 374.85px,
largeMobileAndSmallDesktop: 321px, largeTabletAndXLargeDesktop: 475px);
$heroCarouselBreakPointRebateLineHeight: (smallMobile: 1.62rem, mediumMobile: 1.62rem, smallTablet: 2rem,
largeMobileAndSmallDesktop: 1.5rem, largeTabletAndXLargeDesktop: 2.7rem);
$heroCarouselBreakPointLongCopyMarginTop: (smallMobile: 0, mediumMobile: 0, smallTablet: .6rem,
largeMobileAndSmallDesktop: .1rem, largeTabletAndXLargeDesktop: .6rem);
$heroCarouselArrowFocusedBoxShadow: 0 0 2px $brandPrimaryColor;

/* Countdown */
$countdownDivider: 0 .5rem;
$countdownFontWeight: 500;
$countdownLettersFontWeight: 500;

/* Balloon Tooltip */
$balloon-arrow-margin-right: 1px;
$balloon-arrow-margin-bottom: 1px;

/* Personalized Top Offers */
$personalizedTopOffersButtonFontWeight: 500;
$personalizedTopOffersButtonFontSize: 1.8rem;
$personalizedTopOffersButtonLineHeight: 2.4rem;
$personalizedTopOffersButtonPadding: 1.3rem 5rem;

/* Trending Stores */
$trendingStoresNewMemberBackground: $brandDarkBlue2;
$trendingStoresNewMemberPadding: .5rem 0 6.9rem;
$trendingStoresDividerBackground:rgba(208, 218, 224, .25);
$trendingStoresMerchantLinkTileWidth: (medium: 15.4rem);
$trendingStoresMerchantLinkTileHeight: (medium: 17.4rem, large: 18.4rem);
$trendingStoresMerchantLinkTileRebateHeight: (medium: 13.7rem, large: 14.7rem);
$trendingStoresMerchantLinkTileRebatePositionTop: (medium: 3.5rem);
$trendingStoresMerchantLinkTilePillTop: (medium: 57%);
$trendingStoresMerchantLinkTilePillWidth: 10rem;
$trendingStoresMerchantLinkTilePillLabelFontSize: (small: 1.2rem, medium: 1rem);

/* Merchant Logo */
$merchantLogosLogoWrapperMaxWidth: (small: 9rem, medium: 12rem);
$merchantLogosLogoWrapperHeight: 6rem;
$merchantLogosListLayoutColumns: (small: 3, medium: 5, large: 7, xlarge: 8);
$merchantLogosListLayoutColumnGap: (small: 1rem, medium: 2.5rem, large: 2rem);
$merchantLogosListFlexWrap: (large: nowrap);
$merchantLogosSectionMargin: (small: 0);
$merchantLogosSectionPadding: (small: 0 0 1rem 0, medium: 1rem 0);

/* How It Works */
$howItWorkSectionTitleH2TextAlign: (small: center);
$howItWorkSectionTitleH2FontSize: (small: 3rem);
$howItWorkSectionTitleH2LineHeight: (small: 3.6rem);
$howItWorkSectionTitleH2LetterSpacing: (small: -.46px);
$howItWorkSectionTitleH2FontFamily: (small: $brandFontFamilyLight);
$howItWorksItemTitleFontSize: (small: 2.4rem);
$howItWorksItemTitleLineHeight: (small: 2.8rem);
$howItWorksItemTitleMargin: (small: 2rem 0 1rem 0);
$howItWorksItemTitleFontWeight: (small: bold);
$howItWorksItemTitleLetterSpacing: .54px;
$howItWorksItemTitleColor: (small: $brandPrimaryGrey);
$howItWorksItemMarginBottom: (small: 6rem, large: 0);
$howItWorksItemDescriptionColor: (small: $brandPrimaryGrey);
$howItWorksItemDescriptionLineHeight: (small: 2.7rem);
$howItWorksItemDescriptionPadding: 0;
$howItWorksItemDescriptionMaxWidth: 31.6rem;
$howItWorksItemDescriptionLetterSpacing: .36px;
$howItWorksVideoLinkColor: $brandPrimaryColor;
$howItWorksVideoLinkFontSize: 1.6rem;
$howItWorksVideoLinkFontSize: 1.8rem;
$howItWorksVideoLinkFontFamily: $brandFontFamilyLight;
$howItWorksVideoLinkLineHeight: 2.4rem;
$howItWorksVideoLinkBeforeContent:'\f101';
$howItWorksVideoLinkBeforeFontFamily: 'fontello';
$howItWorksVideoLinkBeforeFontSize: 1.4rem;
$howItWorksVideoLinkBeforePosition: absolute;
$howItWorksVideoLinkBeforeMargin: 0 0 0 16rem;
$howItWorksInvertedSectionBackground: (small: linear-gradient(0deg, $brandDarkBlue2 0%, $brandDarkBlue3 100%));
$howItWorksVhpSectionPadding: (small: 4rem 0);
$howItWorksVhpSectionTitleMargin: (small: 0 0 1rem 0);
$howItWorksVhpSectionTitleH2FontSize: (small: 3.5rem);
$howItWorksVhpSectionTitleH2FontFamily: (small: $brandFontFamily);
$howItWorksVhpImageWidth: 22.6rem;
$howItWorksVhpSectionBackground: (small: linear-gradient(0deg, #e7ecef 0, #f5f7f7 100%));
$howItWorksItemWidth: 16.4rem;
$howItWorksPersonalizedMPHInvertedBackground: linear-gradient(180deg, #074f87 0, #439fd5 100%);

/* Button Benefits Home Page */
$homeButtonBenefitsSectionPadding: (small: 0);
$homeButtonBenefitsHeaderMargin: (small: 2rem 0 1.5rem 0);
$homeButtonBenefitsHeaderFontSize: 3.5rem;
$homeButtonBenefitsHeaderLineHeight: 4rem;
$homeButtonBenefitsUListMargin: 0;
$homeButtonBenefitsHeaderMinWidth: (small: 0);
$homeButtonBenefitsListFontSize: 1.6rem;
$homeButtonBenefitsListLineHeight: 3.2rem;
$homeButtonBenefitsListColor: $brandPrimaryGrey;
$homeButtonBenefitsListMargin: 0 0 1rem;
$homeButtonBenefitsChromeMargin: (small: 0 0 1.5rem, medium: 0);
$homeButtonBenefitsButtonWidth: 25rem;

/* Testimonials */
$testimonialsTextAlign: center;
$testimonialsSectionBackground: (small: #01426a);
$testimonialsIconBeforeBackground: no-repeat center/100% url('../assets/images/icons/user_icon_blue.svg');
$testimonialsIconBeforeWidth: 5rem;
$testimonialsIconBeforeHeight: 5rem;
$testimonialsCarouselIconHeight: 7.5rem;
$testimonialsCarouselIconWidth: 7.5rem;
$testimonialsCarouselTextColor: $brandWhite;
$testimonialsCarouselDotsColumnGap: 1.5rem;
$testimonialsCarouselDotWidth: 1.2rem;
$testimonialsCarouselDotHeight: 1.2rem;
$testimonialsCarouselDotBackground: rgba(157, 166, 171, .5);
$testimonialsCarouselDotBoxShadow: none;
$testimonialsCarouselDotActiveBackground: linear-gradient(0deg, #0061ab 0, #0078d2 100%);
$testimonialsCarouselSectionTitleH2FontSize: (small: 3.5rem);
$testimonialsCarouselSectionTitleH2LineHeight: (small: 4.2rem);
$testimonialsCarouselItemsMargin: (small: 0 auto);
$testimonialsCarouselItemsMaxWidth: (small: 786px);
$testimonialsCarouselArrowFontSize: 9rem;
$testimonialsCarouselArrowFontWeight: normal;
$testimonialsCarouselTextFontSize: 1.8rem;
$testimonialsCarouselTextLineHeight: 3.1rem;
$testimonialsCarouselTextMaxWidth: ();
$testimonialsStaticTextColor: $brandWhite;
$testimonialsStaticSectionBackground: (small: $brandDarkBlue2);
$testimonialsStaticTextLineHeight: 3.1rem;
$testimonialsStaticTextFontSize: 1.6rem;
$testimonialsStaticItemBorderBottom: 1px solid rgba(208, 218, 224, .25);

/* Featured Offers */
$featuredOffersLogoSectionTitleH2FontSize: 3.5rem;
$featuredOffersLogoItemTileWidth: 15.4rem;
$featuredOffersLogoItemTileHeight: 17.3rem;
$featuredOffersLogoItemTileBorder: 1px solid #d4d4d0;
$featuredOffersLogoItemTileBoxShadow: 0 2px 4px 0 rgba(255, 255, 255, .5);
$featuredOffersLogoTop: 0;
$featuredOffersLogoBottom: 0;
$featuredOffersLogoRight: 0;
$featuredOffersLogoLeft: 0;
$featuredOffersLogoRebateOldValue: 1.1rem;
$featuredOffersLogoRebateNewValueFontSize: 1.3rem;
$featuredOffersLogoRebateHeight: 12.5rem;
$featuredOffersLogoSectionTitleTextAlign: left;
$featuredOffersLogoRebatePadding: 0 0 1rem;
$featuredOffersLogoPillPositionBottom: (small: 5.2rem, medium: 5.2rem);
$featuredOffersLogoItemLogoMargin: 2.5rem auto 0;
$featuredOffersLogoPMPHSectionTitleH2FontSize: 3rem;

/* Pill */
$pillBorderRadius: 1rem;

/* Sticky CTA Bar */
// VHP
$stickyCtaBarVhpContentFlexDirection: (small: column, medium: row);
$stickyCtaBarVhpWrapHeight: (small: (), medium: (), large: (), xlarge: 11.2rem);
$stickyCtaBarVhpBackgroundColor: (small: $brandLightGray);
$stickyCtaBarVhpColor: $brandWhite;
$stickyCtaBarVhpHeaderFontSize: 2.4rem;
$stickyCtaBarVhpHeaderLineHeight: 2.9rem;
$stickyCtaBarVhpHeaderFontWeight: 700;
$stickyCtaBarVhpHeaderColor: $brandDarkBlue2;
$stickyCtaBarVhpHeaderMargin: (small: 0 4rem 2rem, medium: 0 2rem 0, large: 0);
$stickyCtaBarVhpHeaderTextAlign: center;
$stickyCtaBarVhpButtonWidth: (small: 37.5rem, medium: 20rem);
$stickyCtaBarVhpButtonMaxWidth: 100%;
$stickyCtaBarVhpButtonPadding: (small: 1.7rem 4rem 1.5rem, medium: 1.3rem 4rem 1.1rem);
$stickyCtaBarVhpButtonMargin: (small: (), medium: 0 0 0 2.5rem);
$stickyCtaBarVhpButtonFontWeight: normal;
$stickyCtaBarVhpButtonBackground: linear-gradient(0deg, $brandDarkBlue3 0%, $brandPrimaryColor 100%);

/* Category Flyout */
$categoryFlyoutTitleMargin: 0 0 1rem 0;
$categoryFlyoutTitleRebateOldValueDisplay: none;
$categoryFlyoutTitleRebateMargin: ();
$categoryFlyoutTitleRebatePadding: ();
$categoryFlyoutTitleRebateBorder: ();
$categoryFlyoutTitleRebateBackground:();
$categoryFlyoutTitleRebateFontSize: 1.4rem;
$categoryFlyoutTitleRebateTextAlign: ();
$categoryFlyoutMerchantTitleFontSize: 1.4rem;
$categoryFlyoutMerchantTitleMargin: .6rem 0;
$categoryFlyoutTitleRebateNewValueFontSize: 1.4rem;
$categoryFlyoutMerchantTitleColor: $brandPrimaryGrey;
$categoryFlyoutMerchantTitleFontWeight: 700;
$categoryFlyoutTitleRebateNewValueColor: $rebateColor;

/* Form Live Validation */
$formLiveValidationErrorColor: #e51937;
$formLiveValidationInputTextOutline: ();
$formLiveValidationInputTextFocusOutline: 1px solid $brandPrimaryColor;
$formLiveValidationInputTextMargin: 0;
$formLiveValidationInputTextFocusBorderWidth: 0 0 1px 0;
$formLiveValidationInputTextFocusBorderColor: #4470c3;
$formLiveValidationInputTextFocusMarginBottom: ();
$formLiveValidationInputTextFontFamily: $brandFontFamily;
$formLiveValidationInputTextColor: $brandPrimaryGrey;
$formLiveValidationInputTextPlaceholderColor: $brandPrimaryGrey;
$formLiveValidationInputTextLabelFocusFontWeight: bold;
$formLiveValidationInputDescriptionFontSize: 1.3rem;
$formLiveValidationInputDescriptionLinkFontWeight: ();
$formLiveValidationInputFocusLabelFontWeight: bold;
$formLiveValidationInputFocusOutlineOffset: 1px;
$formLiveValidationInputErrorFocusOutlineColor: $formLiveValidationErrorColor;
$formLiveValidationInputDescriptionPaddingTop: 0;
$formLiveValidationInputDescriptionLinkColor: ();
$formLiveValidationInputTextFontSize: (small: 1.8rem, medium: 1.8rem);
$formLiveValidationInputWrapMargin: (small: 2rem auto 0, medium: 4.7rem 0 0 0);
$formLiveValidationContainerPadding: (small: 2rem 3rem, medium: 0 4rem 2rem 4rem);
$formLiveValidationContainerColor: $brandPrimaryGrey;
$formLiveValidationCheckboxMargin: 2rem 0 5rem 0;
$formLiveValidationCheckboxCheckedBackgroundColor: #4db4fa;
$formLiveValidationCheckboxCheckedBoxShadow: none;
$formLiveValidationCheckboxLabelBorder: none;
$formLiveValidationCheckboxCheckedBorder: none;
$formLiveValidationCheckboxLabelWidth: 2.2rem;
$formLiveValidationCheckboxLabelHeight: 2.2rem;
$formLiveValidationCheckboxCheckedIconBorder: 2px solid $brandWhite;
$formLiveValidationCheckboxLabelBackgroundColor: #4db4fa;
$formLiveValidationCheckboxLabelFontSize: 1.3rem;
$formLiveValidationCheckboxLabelSupFontSize: .8rem;
$formLiveValidationCheckboxLabelLineHeight: 1.6rem;
$formLiveValidationCheckboxLabelBoxShadow: none;
$formLiveValidationButtonWidth: (small: 100%, large: 100%);
$formLiveValidationButtonHeight: 4rem;
$formLiveValidationButtonFontSize: 1.6rem;
$formLiveValidationButtonLetterSpacing: ();
$formLiveValidationButtonFontFamily: $brandFontFamily;
$formLiveValidationButtonFontWeight: normal;
$formLiveValidationButtonBorder: none;
$formLiveValidationButtonBorderRadius: 2px;
$formLiveValidationShowOrHidePasswordColor: $brandPrimaryColor;
$formLiveValidationShowOrHidePasswordFontSize: 1.3rem;

/* Form Tooltip */
$tooltipFormHintCloseButtonAfterFontFamily: 'AmericanIcons';
$tooltipFormHintCloseButtonAfterContent: '\e04c';
$tooltipFormHintCloseButtonAfterColor: $brandPrimaryColor;

/* First Favorite */
$addToFavoriteFirstTimePopUpModalBackground: (small: $brandPrimaryGrey, medium: $brandPrimaryGrey);
$addToFavoriteFirstTimePopUpTitleColor: (small: $brandWhite, medium: $brandWhite);
$addToFavoriteFirstTimePopUpTitleFontSize: 3.8rem;
$addToFavoriteFirstTimePopUpTextColor: (small: $brandWhite, medium: $brandWhite);
$addToFavoriteFirstTimePopUpTextMargin: (small: 3rem 4rem 3rem 0, medium: 3rem 0);
$addToFavoriteFirstTimePopUpTitleFontWeight: 500;
$addToFavoriteFirstTimePopUpButtonFontWeight: 500;

/* adBlock Warning */
$adBlockWarningBackground: $brandPrimaryGrey;
$adBlockWarningBorderBottom: 2px solid $brandWhite;
$adBlockWarningPadding: 0;
$adBlockWarningMessageJustifyContent: center;
$adBlockWarningMessageFontWeight: 400;
$adBlockWarningMessageBackground: $brandPrimaryGrey;
$adBlockWarningMessageColor: $brandWhite;
$adBlockWarningMessagePadding: 0;
$adBlockWarningMessageInnerPadding: 0 3rem;
$adBlockWarningMessageInnerMaxWidth: 100rem;
$adBlockWarningIconFontFamily: 'AmericanIcons';
$adBlockWarningIconContent: '\e03e';

/* Recent Stores Flyout */
$recentStoresFlyoutHeadBgColor: #d8e5e4;
$recentStoresFlyoutH2Color: $brandPrimaryColor;
$recentStoresFlyoutH2FontSize: 150%;
$recentStoresFlyoutLogoItemMargin: .9rem auto;

/* Sticky Footer Desktop */
$stickyFooterDesktopWrapHeight: 8rem;
$stickyFooterDesktopWrapBackground: $brandPrimaryGrey;
$stickyFooterDesktopControlPositionTop: -2.6rem;
$stickyFooterDesktopControlWidth: 3.2rem;
$stickyFooterDesktopControlBoxShadow: 0 -3px 3px rgba(0, 0, 0, .15);
$stickyFooterDesktopControlColor: $brandDarkGray;
$stickyFooterDesktopControlBeforeContent: '\2715';
$stickyFooterDesktopControlBeforeFontFamily: 'fontello';
$stickyFooterDesktopControlBeforeFontWeight: bold;
$stickyFooterDesktopControlBeforeFontSize: 2rem;
$stickyFooterDesktopControlBeforeLineHeight: 2.4rem;
$stickyFooterDesktopControlBeforeLetterSpacing: -.4rem;
$stickyFooterDesktopControlBeforePadding: .6rem;
$stickyFooterDesktopControlBeforeCollapsedFontSize: 2.8rem;
$stickyFooterDesktopControlBeforeCollapsedFontWeight: normal;
$stickyFooterDesktopControlBeforeCollapsedLineHeight: 2rem;
$stickyFooterDesktopImagePositionBottom: 1rem;
$stickyFooterDesktopRolloverCopyAndDateColor: $brandDarkBlue;
$stickyFooterDesktopUseCodeColor: $brandDarkBlue;
$stickyFooterDesktopMerchantInfoMargin: 0 auto 0 5rem;
$stickyFooterDesktopMerchantRebateOldValueColor: $brandDarkGray3;
$stickyFooterDesktopButtonPadding: 1rem 3rem;
$stickyFooterDesktopButtonFontSize: (medium: 1.4rem, xlarge: 1.6rem);
$stickyFooterDesktopButtonWrapMargin: 0 0 0 1rem;
$stickyFooterDesktopMerchantLogoWrapPadding: 0;
$stickyFooterDesktopImageWrapperMargin: (medium: auto 0 0 0, large: auto 0 0 5rem);
$stickyFooterDesktopContentMaxWidth: 1300px;
$stickyFooterDesktopMerchantLogoWrapMargin: (medium: 0 3rem 0 0, large: 0 13rem 0 0);
$stickyFooterDesktopDisclaimerColor: $brandDarkGray2;
$stickyFooterDesktopCodeColor: $brandPrimaryColor;
$stickyFooterDesktopMerchantRebateOldValueColor: $brandQuaternaryColor;
$stickyFooterDesktopMerchantRebatePrefixFontWeight: 700;
$stickyFooterDesktopControlBeforePadding: .4rem .8rem .6rem .4rem;
$stickyFooterDesktopControlBeforeCollapsedContent: '\f106';
$stickyFooterDesktopControlCollapsedBackground: $brandPrimaryColor;
$stickyFooterDesktopControlCollapsedColor: $brandWhite;
$stickyFooterDesktopExpirationDotDisplay: none;

/* Button Overlay */
$buttonOverlayCloseButtonPostionRight: 1.2rem;
$buttonOverlayContentBackground: url('~org/assets/images/button_overlay_background.png') no-repeat center;
$buttonOverlayBlockOnePositionTop: 34rem;
$buttonOverlayBlockFourPositionTop: 218rem;
$buttonOverlayScrollbarTrackPieceBackground: #cfe4f4;
$buttonOverlayScrollbarThumBackground: $brandPrimaryColor;
$buttonOverlayMottoColor: $brandDarkBlue2;
$buttonOverlayMottoFontSize: 1.8rem;
$buttonOverlayHeaderFontSize: 3.8rem;
$buttonOverlayHeaderColor: $brandPrimaryColor;
$buttonOverlayHeaderMargin: .5rem 0 .6rem 0;
$buttonOverlayTextLineHeight: 2.2rem;
$buttonOverlayTextMarginBottom: 1.5rem;
$buttonOverlayHiwHintsMargin: 25rem 0 3rem 0;
$buttonOverlayHiwHintsSubheaderMargin: 1.5rem 0 1rem 0;
$buttonOverlayHiwHintsColor: $brandWhite;
$buttonOverlayHiwHintsBlockOneTextMarginTop: 2rem;
$buttonOverlayHiwHintsBorder: 1px solid rgba(255, 255, 255, .3);
$buttonOverlayBlockPadding: 0 7rem;
$buttonOverlayBlockThreePadding: 0 8rem;
$buttonOverlayBlockThreeHeaderColor: $brandWhite;
$buttonOverlayBlockFourPositionTop: 217rem;
$buttonOverlayBlockFourTextMargin: 0 0 2.9rem 0;
$buttonOverlayButtonBorderRadius: .5rem;
$buttonOverlayButtonSecondaryBackground: $brandWhite;
$buttonOverlayButtonSecondaryColor: $brandPrimaryColor;
$buttonOverlayButtonBackground: $brandWhite;
$buttonOverlayButtonColor: $brandPrimaryColor;
$buttonOverlayButtonFontWeight: normal;
$buttonOverlayButtonFontSize: 1.8rem;
$buttonOverlaySupFontSize: .9rem;

/* Gift Guide */
$giftGuideBackground: (small: #e6eef8, medium: unset);
$giftGuideInnerBackground: (small: unset, medium: #e6eef8);
$giftGuideInnerPadding: (small: 0, medium: 3rem 1rem);
$giftGuideButtonWidth: (small: 37.2rem, medium: 49.7rem);
$giftGuideButtonHeight: 4rem;
$giftGuideButtonFontWeight: 500;
$giftGuideButtonFontSize: 1.8rem;
$giftGuideButtonPadding: .8rem;
$giftGuideItemMerchantWrapBorder: 1px solid #e6e7e8;
$giftGuideItemOfferDetailsLineHeight: 20px;
$giftGuideItemOfferDetailsColor: $brandPrimaryGrey;
$giftGuideItemRebatePrefixFontWeight: bold;
$giftGuideItemRebateWrapBottom: 10px;
$giftGuideItemElevationOldValueFontSize: 1.2rem;
$giftGuideItemCopyWrapMinHeight: 6.3rem;
$giftGuideItemPromoCodeWrapColor: $brandQuaternaryColor;
$giftGuideItemPromoCodeColor: $brandPrimaryColor;
$giftGuideItemPromoCodeFontWeight: bold;
$giftGuideItemPromoCodeLineHeight: 1.9rem;
$giftGuideItemPromoCodeMargin: 0 0 .75rem 0;
$giftGuideItemExpirationDateColor: $brandQuaternaryColor;
$giftGuideItemExpirationDateFontSize: 1rem;
$giftGuideItemExpirationDateFontFamily: 'Arial';
$giftGuideItemExpirationDateLineHeight: 1.6rem;

/* Referral page */
// General
$referralTopWrapBackground: $brandGray2;
$referralSectionTitleH2Color: $brandDarkBlue2;
$referralSectionTitleH2FontSize: (small: 2.7rem, medium: 3rem);
$referralSectionTitleH2LineHeight: 3.6rem;
$referralSectionTitleH2TextAlign: center;
$referralSectionTitleH2FontFamily: 'AmericanSans-Light';
$referralSectionTitleH2Margin: 0 0 2rem 0;
// FAQ
$referralFAQItemBorderTop: 1px solid $brandSecondaryGray;
$referralFAQItemH3Color: $brandDarkBlue2;
$referralFAQItemTextColor: $brandPrimaryGrey;
// HIW
$referralHIWItemNumberBackground: $brandPrimaryColor;
$referralHIWItemH3Color: $brandDarkBlue2;
$referralHIWItemTextColor: $brandPrimaryGrey;
// Hero
$referralHeroColor: $brandWhite;
$referralHeroBackground: url('~org/modules/Referral/assets/clouds.svg'), linear-gradient(225deg, #931982 0%, $brandPrimaryColor 100%);
$referralHeroBackgroundSize: (small: 300%, medium: contain);
$referralHeroBackgroundPosition: (small: 25% 100%, medium: 0 100%);
$referralHeroContentWrapPadding: (small: 0 2rem, medium: 0 3rem);
$referralHeroH2Color: $brandWhite;
$referralHeroH2FontFamily: 'AmericanSans-Light';
$referralHeroButtonPadding: 1rem;
$referralHeroButtonBorder: none;
$referralHeroButtonColor: $brandPrimaryColor;
$referralHeroButtonFontSize: 1.8rem;
$referralHeroButtonLineHeight: 2.4rem;
$referralHeroButtonBorderRadius: .5rem;
$referralHeroButtonBackground: $brandWhite;
$referralHeroButtonMinWidth: (small: 100%, medium: 25.8rem);
$referralHeroButtonMinHeight: 5rem;
$referralHeroButtonFontWeight: bold;
$referralHeroButtonHoverBackground: #f4f4f4;
$referralHeroButtonHoverColor: $referralHeroButtonColor;
$referralHeroShareDesktopReferralLinkColor: $brandQuaternaryColor;
$referralHeroShareMobileReferralLinkBorder: 1px $brandWhite dashed;
$referralHeroShareDesktopReferralSuccessIconBackground: url('~org/assets/images/copied-success.png') 0 -1px no-repeat;
$referralHeroShareDesktopReferralButtonMinWidth: 20rem;
$referralHeroShareDesktopReferralButtonBorderRadius: 0 5px 5px 0;
$referralHeroShareDesktopReferralButtonBackground: $brandPrimaryColor;
$referralHeroShareDesktopReferralButtonColor: $brandWhite;
$referralHeroShareDesktopReferralButtonBorder: none;
$referralHeroShareDesktopReferralButtonFontSize: 1.8rem;
$referralHeroShareDesktopReferralButtonLineHeight: 2.2rem;
$referralHeroShareDesktopReferralButtonHoverBackground: $brandPrimaryBlue;

/* Terms and conditions Modal */
$termsAndConditionsModalH2Color: #3a4c5e;
$termsAndConditionsModalH2FontSize: 3.2rem;
$termsAndConditionsModalH2LineHeight: 3.6rem;
$termsAndConditionsModalH2Margin: 3rem 0 2rem 0;
$termsAndConditionsModalTextColor: $brandPrimaryGrey;
$termsAndConditionsModalTextFontSize: 1.6rem;
$termsAndConditionsModalTextLineHeight: 2.4rem;

/* Bonus Rolling Modal */
$bonusRollingModalBorder: 1px solid $brandDarkBlue2;
$bonusRollingModalCloseColor: $brandWhite;
$bonusRollingModalTitleWrapBackground: linear-gradient(0deg, $brandDarkBlue2 0%, $brandDarkBlue3 100%);
$bonusRollingModalTitleWrapBorderBottom: solid $brandPrimaryColor 1.4rem;
$bonusRollingModalTitleWrapBeforeBackground: url('~org/assets/images/aa-clouds.png');
$bonusRollingModalTitleWrapBeforeBackgroundRepeat: no-repeat;
$bonusRollingModalTitleWrapBeforeBackgroundSize: 100%;
$bonusRollingModalTitleWrapBeforeBackgroundPosition: 50% 55%;
$bonusRollingModalTitleWrapBeforeOpacity: .15;
$bonusRollingModalTitleColor: $brandWhite;
$bonusRollingModalTitleFontSize: 1.6rem;
$bonusRollingModalTitleLineHeight: 2.2rem;
$bonusRollingModalTitleFontWeight: bold;
$bonusRollingModalTitleLetterSpacing: 1px;
$bonusRollingModalCountdownSpanColor: $brandWhite;
$bonusRollingModalCountdownSpanFontWeight: bold;
$bonusRollingModalCountdownSpanBeforeColor: $brandWhite;
$bonusRollingModalCountdownSpanBeforeFontWeight: bold;
$bonusRollingModalCountdownSpanBeforeMargin: 0 .3rem;
$bonusRollingModalCountdownSpanAfterFontWeight: bold;
$bonusRollingModalCountdownSpanAfterPadding: 0 0 0 .3rem;
$bonusRollingModalInfoH2Color: $brandPrimaryColor;
$bonusRollingModalInfoH2FontFamily: $brandFontFamilyLight;
$bonusRollingModalInfoH2FontSize: (small: 2.4rem, medium: 3.8rem, large: ());
$bonusRollingModalInfoH2LineHeight: (small: 3rem, medium: 4.4rem, large: ());
$bonusRollingModalInfoButtonMinHeight: 5rem;
$bonusRollingModalInfoButtonMinWidth: 25.8rem;
$bonusRollingModalInfoButtonBorderRadius: .5rem;
$bonusRollingModalInfoButtonBackground: $brandPrimaryColor;
$bonusRollingModalInfoButtonHoverBackground: $brandDarkBlue3;
$bonusRollingModalInfoButtonFontSize: 1.8rem;
$bonusRollingModalInfoTermsButtonColor: $brandPrimaryColor;
$bonusRollingModalInfoTermsButtonFontSize: 1.4rem;
$bonusRollingModalInfoTermsButtonLineHeight: 2rem;
$bonusRollingModalInfoCloseTermsColor: $brandDarkBlue2;
$bonusRollingModalInfoTermsInfoH3Color: $brandPrimaryGrey;
$bonusRollingModalInfoTermsInfoTextColor: $brandPrimaryGrey;
$bonusRollingModalHiwH3Color: $brandPrimaryGrey;
$bonusRollingModalHiwItemNumberColor: (small: $brandPrimaryGrey, medium: (), large: $brandPrimaryColor);
$bonusRollingModalHiwItemNumberFontFamily: (small: (), medium: (), large: $brandFontFamilyLight);
$bonusRollingModalHiwItemTextColor: $brandPrimaryGrey;

/* Bonus Home Banner */
$bonusHomeBannerBackground: #fafbfb;
$bonusHomeBannerH2Color: $brandPrimaryColor;
$bonusHomeBannerH2FontFamily: $brandFontFamilyLight;
$bonusHomeBannerTermsButtonColor: $brandPrimaryColor;
$bonusHomeBannerNewMemberWrapBackground: $brandDarkBlue3;
$bonusHomeBannerMhpBottomBannerBorder: 1px solid $brandDarkBlue2;
$bonusHomeBannerMhpBottomBannerBackground: linear-gradient(0deg, $brandDarkBlue2 0, $brandDarkBlue3 100%);
$bonusHomeBannerMhpBottomBannerBorderBottom: 15px solid $brandPrimaryColor;
$bonusHomeBannerMhpBottomBannerColor: $brandWhite;
$bonusHomeBannerMhpBottomBannerBeforeBackground: url('~org/assets/images/aa-clouds.png');
$bonusHomeBannerVhpBannerBackground: $brandWhite;
$bonusHomeBannerVhpBannerBorder: 1px solid $brandDarkBlue2;
$bonusHomeBannerMhpBottomNewMemberWrapBackground: $brandGray2;

/* Hybrid Stores */
$hybridStoresTitleMargin: (small: 0 0 3.8rem, medium: 0 0 5.8rem);
$hybridStoresTitleTextAlign: left;
$hybridStoresTitleColor: $brandQuaternaryColor;
$hybridStoresTitleFontFamily: $brandFontFamilyLight;
$hybridStoresTitleLineHeight: 3rem;
$hybridStoresViewAllLinkColor: $brandPrimaryColor;
$hybridStoresViewAllLinkLineHeight: 2rem;
$hybridStoresViewAllLinkFontSize: 1.4rem;
$hybridStoresViewAllTextColor: $brandPrimaryGrey;
$hybridStorePadding: (small: 0 0 3.4rem, medium: 0 0 6.4rem);
$hybridStoreMerchantTileBoxShadow: 1px 1px 2px 0 rgba(0, 0, 0, .2);
$hybridStoreMerchantTileDescriptionMargin: 1rem 1.5rem 0;
$hybridStoreMerchantTileDescriptionWidth: 18rem;
$hybridStoreMerchantTileNameLineHeight: 2.6rem;
$hybridStoreMerchantTileNameColor: $brandPrimaryGrey;
$hybridStoreMerchantTileRebateValueDisplay: block;
$hybridStoreMerchantTilePremierMerchantRebateElevatedNewSpacing: 0;
$hybridStoreMerchantTilePremierMerchantRebateFontSize: 1.6rem;
$hybridStoreMerchantTilePremierMerchantRebateLineHeight: 2.2rem;
$hybridStoreMerchantTilePremierMerchantRebateElevatedNewValueFontWeight: bold;
$hybridStoreMerchantTilePremierMerchantRebateElevatedNewValueLineHeight: 2.4rem;
$hybridStoreMerchantTilePremierMerchantRebateFontWeight: bold;
$hybridStoreMerchantTileElevationOldValueAfterContent: '.';
$hybridStoreMerchantTileBorder: 2px solid $brandSecondaryGray;
$hybridStoreMerchantTileImgWidth: 8.4rem;
$hybridStoreMerchantExtraRewardsTileInnerBackground: linear-gradient(to top, #fafbfb 0%, #fafbfb 2.8rem, $brandWhite 2.8rem);
$hybridStoreMerchantPillPositionBottom: 1rem;
$hybridStoreMerchantPillBorderRadius: 0;
$hybridStoreMerchantPillLabelPadding: 0;
$hybridStoreMerchantPillLabelAfterDisplay: none;
$hybridStoreMerchantPillLabelFontSize: 1.2rem;
$hybridStoreMerchantFavoriteIconWidth: 1.4rem;
$hybridStoreMerchantFavoriteIconPositionRight: 2.1rem;
$hybridStoreMerchantFavoriteIconPositionTop: 1.3rem;
$hybridStoreMerchantFavoriteIconHeight: 1.28rem;
$hybridStoreMerchantFavoriteIconLineHeight: 1;
$hybridStoreMerchantFavoriteFontIconBeforeFontSize: 1.4rem;
$hybridStoresSliderFontSize: ();
$hybridStoresSliderLineHeight: ();
$hybridStoresButtonFontSize: 1.8rem;
$hybridStoresButtonBackground: $brandWhite;
$hybridStoresButtonColor: $brandPrimaryColor;
$hybridStoresButtonBorder: 1px solid $brandPrimaryColor;
$hybridStoresButtonPadding: 1.22rem 5rem;
$hybridStoresButtonFontWeight: 600;
$hybridStoresButtonBorderRadius: .5rem;
$hybridStoresButtonContainerMargin: (small: (), medium: .4rem 0 0);
$hybridStoresButtonContainerPadding: (small: (), medium: 0 0 10.8rem);

/* Hybrid Products */
$hybridProductsWrapMargin: 0;
$hybridProductsWrapBackground: linear-gradient(180deg, $brandPrimaryColor 0%, $brandDarkBlue3 100%);
$hybridProductsWrapPadding: (small: 3rem 0 4rem, medium: 3rem 0 7rem);
$hybridProductsSectionTitleH2FontSize: 2.4rem;
$hybridProductsSectionTitleH2FontFamily: $brandFontFamilyLight;
$hybridProductsSectionTitleH2TextAlign: left;
$hybridProductsSectionTitleColor: $brandWhite;
$hybridProductsSectionTitleLinkLineHeight: 2rem;
$hybridProductsSectionTitleLinkFontSize: 1.4rem;
$productItemWrapPadding: .3rem .4rem .4rem;
$productItemWrapBorder: 1px solid $brandGray3;
$productItemWrapBorderRadius: 0;
$productItemProductInfoColor: $brandPrimaryGrey;
$productItemProductInfoNameFont: 600 1.4rem/2rem $brandFontFamily;
$productItemProductInfoPadding: .7rem 1.5rem;
$productItemProductInfoPriceFont: bold 1.6rem/2rem $brandFontFamily;
$productItemProductInfoPriceMargin: 0 0 .3rem;
$productItemProductInfoRebateFont: bold 1.6rem/2rem $brandFontFamily;
$productItemProductInfoRebateColor: $brandPrimaryColor;
$productItemMerchantBackgroundColor: $brandGray6;
$productItemMerchantBottom: .5rem;
$productItemMerchantRight: .5rem;
$productItemMerchantLeft: .5rem;
$productItemMerchantPadding: 1rem 1.5rem;
$productItemMerchantPFont: normal 1.4rem/2rem $brandFontFamily;
$productItemExtraFlagWidth: 10.2rem;
$productItemExtraFlagBackgroundColor: $brandDarkBlue2;
$productItemExtraFlagAfterBorderColor: transparent $brandDarkBlue3;
$productItemFlagFont: bold 1.2rem/1.9rem $brandFontFamily;
$productItemFlagColor: $brandWhite;
$productItemFlagLeft: -1.1rem;
$productItemFlagTextTransform: ();
$productItemFlagWidth: 6.4rem;
$productItemFlagBackgroundColor: #931982;
$productItemFlagAfterBorderColor: transparent #460c3e;

/* Hybrid Coupons */
$hybridCouponsBackground: ();
$hybridCouponsPadding: (small: 3rem 0, medium: 3rem 0 10rem 0);
$hybridCouponsTitleH2FontTextAlign: left;
$hybridCouponsTitleH2Color: $brandQuaternaryColor;
$hybridCouponsTitleH2FontFamily: $brandFontFamilyLight;
$hybridCouponsViewAllLinkFontSize: 1.4rem;
$hybridCouponsViewAllLinkLineHeight: 2rem;
$hybridCouponsTitleMargin: (small: 0 0 2.6rem, medium: 0 0 5rem);
$hybridCouponsTilesColumnGap: 4.8rem;
$hybridCouponTileMargin: (small: 0 0 2rem, medium: 0 0 3rem);
$hybridCouponTileOfferDetailsColor: $brandPrimaryGrey;
$hybridCouponTileOfferDetailsLineHeight: 2rem;
$hybridCouponTileCouponCodeLabelColor: $brandPrimaryGrey;
$hybridCouponTileCouponCodeLabelLineHeight: 2rem;
$hybridCouponTileCouponCodeColor: $brandPrimaryColor;
$hybridCouponTileCouponCodeFontSize: 1.2rem;
$hybridCouponTileCouponCodeLetterSpacing: .75px;
$hybridCouponTileSimilarCouponsLinkBackground: $brandGray6;
$hybridCouponTileSimilarCouponsLinkLineHeight: 2rem;
$hybridCouponTileSimilarCouponsLinkColor: $brandPrimaryGrey;
$hybridCouponTileOfferExpiresFontStyle: ();
$hybridCouponTileBorder: ();
$hybridCouponTileLogoWrapMaxWidth: (small: 8.4rem, medium: 25%);

/* Product Details Modal */
$productSearchResultsPadding: (small: 0 0 5rem, medium: 0 0 5rem);
$productSearchResultsSectionTitleMargin: (small: unset, medium: 2rem 0 0);
$productSearchResultsSectionTitleH2TextAlign: left;
$productSearchResultsSectionTitleH2FontSize: (small: 2.4rem, medium: 3rem);
$productSearchResultsSectionTitleH2FontFamily: $brandFontFamilyLight;
$productSearchResultsSectionTitleH2Color: (small: $brandQuaternaryColor, medium: $brandDarkBlue2);
$productSearchResultsMobileMenuTriggerStickedFontSize: 1.4rem;
$productSearchResultsMobileMenuTriggerStickedColor: $brandPrimaryColor;
$productSearchResultsMobileMenuTriggerStickedMargin: 0;
$productSearchResultsMobileMenuTriggerStuckTriggerFontWeight: bold;
$productSearchResultsMobileMenuTriggerStuckTriggerColor: $brandPrimaryGrey;
$productSearchResultsMobileMenuTriggerStuckAnonimousPositionTop: 14rem;
$productSearchResultsWrapPadding: (small: 0 0 5rem, medium: 1rem 0 3.5rem);
$productSearchResultsDetailsBoxShadow: none;
$productSearchResultsSeeMoreButtonWidth: (small: 100%, medium: 50%);
$productSearchResultsSeeMoreButtonColor: $brandPrimaryColor;
$productSearchResultsSeeMoreButtonBackground: $brandWhite;
$productSearchResultsSeeMoreButtonMargin: (small: 0 auto, medium: ());
$productSearchResultsSeeMoreButtonBorder: 1px solid $brandPrimaryColor;
$productSearchResultsSeeMoreButtonBorderRadius: .5rem;
$productSearchResultsSeeMoreButtonFontSize: 1.8rem;
$productSearchResultsSeeMoreButtonLineHeight: 2.4rem;
$productSearchResultsMerchantWidth: calc(100% - 1rem);
$psrMenuMobileTitleColor: $brandPrimaryGrey;
$psrMenuMobileTitleLineHeight: 2.6rem;
$psrMenuMobileTitleFontSize: 2rem;
$psrMenuMobileFilterBorderBottom: 1px solid $brandSecondaryGray;
$psrMenuMobileFilterTitleLineHeight: 2.6rem;
$psrMenuMobileFilterTitleFontSize: 2rem;
$psrMenuMobileFilterTitleAfterColor: $brandPrimaryColor;
$psrMenuMobileFilterDropdownListItemInputAccentColor: $brandPrimaryColor;
$psrMenuMobileFilterMoreOrLessButtonTextDecoration: none;
$psrMenuMobileFilterMoreOrLessButtonFontSize: 1.4rem;
$psrMenuMobileFilterMoreOrLessButtonColor: $brandPrimaryColor;
$psrMenuMobileClearFilterButtonFontSize: 1.4rem;
$psrMenuMobileClearFilterButtonColor: $brandPrimaryColor;
$psrMenuMobileClearFilterButtonTextDecoration: none;
$psrMenuMobileViewResultsButtonPadding: 1.4rem 4rem;
$psrMenuMobileViewResultsButtonBorderRadius: .5rem;
$psrMenuMobileViewResultsButtonFontSize: 1.8rem;
$psrMenuMobileButtonsWrapMargin: 7.9rem 0 0 0;
$psrMenuDesktopMargin: 2rem 0 .5rem;
$psrMenuDesktopSortLabelColor: ();
$psrMenuDesktopSortLabelFontSize: 1.6rem;
$psrMenuDesktopSortLabelLineHeight: 2.2rem;
$psrMenuDesktopSortSelectColor: $brandPrimaryColor;
$psrMenuDesktopSortSelectBorder: 1px solid $brandPrimaryColor;
$psrMenuDesktopSortSelectBackground: $brandWhite url('~org/assets/images/chevron_down.svg') no-repeat 95% 55%;
$psrMenuDesktopSortSelectAppearance: none;
$psrMenuDesktopFiltersFilterByFontSize: 1.6rem;
$psrMenuDesktopFiltersFilterByColor: ();
$psrMenuDesktopFiltersFilterByLineHeight: 2.2rem;
$psrMenuDesktopFiltersButtonColor: $brandPrimaryColor;
$psrMenuDesktopFiltersButtonBorder: 1px solid $brandPrimaryColor;
$psrMenuDesktopFiltersButtonBorderRadius: .6rem;
$psrMenuDesktopFiltersButtonAfterColor: $brandPrimaryColor;
$psrMenuDesktopFiltersDropdownTitleColor: $brandPrimaryColor;
$psrMenuDesktopFiltersDropdownListItemInputAccentColor: $brandPrimaryColor;
$psrMenuDesktopFiltersDropdownBorder: .25px solid $brandPrimaryColor;
$psrMenuDesktopFiltersDropdownBoxShadow: 0 4px 20px 0 rgba(0, 0, 0, .15);
$psrMenuDesktopActiveFiltersClearFiltersFontSize: 1.4rem;
$psrMenuDesktopActiveFiltersClearFiltersColor: $brandPrimaryColor;
$psrMenuDesktopActiveFilterBackground: $brandGray6;
$psrMenuDesktopActiveFilterColor: $brandPrimaryColor;
$psrMenuDesktopActiveFilterFontSize: 1.4rem;
$psrMenuDesktopActiveFilterButtonColor: $brandPrimaryColor;
$psrMenuDesktopActiveFiltersGap: 1rem 2.5rem;

/* Product Details Modal */
$productDetailsModalOfferInfoMargin: (small: 0 0 0 3rem);
$productDetailsModalProductNameFontSize: (small: 1.8rem, medium: 1.8rem);
$productDetailsModalProductNameColor: ();
$productDetailsModalCompareTextFontSize: (small: 1.4rem, medium: 1.6rem);
$productDetailsModalListFontSize: (small: 1.4rem, medium: 1.6rem);
$productDetailsModalDescriptionColor: ();
$productDetailsModalDescriptionLineHeight: 2rem;
$productDetailsModalDescriptionFontSize: 1.4rem;
$productDetailsModalDetailsButtonDisplay: block;
$productDetailsModalDetailsButtonColor: $brandPrimaryColor;
$productDetailsModalDetailsButtonPadding: 1rem 0 0;
$productDetailsModalDetailsButtonTextDecoration: none;
$productDetailsModalPillBorderRadius: 0;
$productDetailsModalPillLabelPadding: .5rem 0 0;
$productDetailsModalPillLabelFontSize: 1.2rem;
$productDetailsModalPillLabelFontWeight: bold;
$productDetailsModalSalePillBackground: #931982;
$productDetailsModalTableHeadFontSize: 1.6rem;
$productDetailsModalTableHeadBackground: $brandGray6;
$productDetailsModalTableHeadTextTransform: ();
$productDetailsModalOfferCellPadding: 0;
$productDetailsModalTableHeadRowMargin: 0 0 0 3rem;
$productDetailsModalTableHeadCellPadding: 1.9rem 0;
$productDetailsModalNameColor: $brandPrimaryColor;
$productDetailsModalPriceFontSize: (small: 1.6rem, medium: 1.8rem);
$productDetailsModalPriceMargin: (small: .4rem 0, medium: 0);
$productDetailsModalNameAndRebateLineHeight: 2.2rem;
$productDetailsModalRebatePrefixFontWeight: bold;
$productDetailsModalRebateFontSize: (small: 1.4rem, medium: 1.6rem);
$productDetailsModalClickLinkFontSize: 1.8rem;
$productDetailsModalClickLinkPadding: .5rem 0;
$productDetailsModalClickLinkBorderRadius: .5rem;
$productDetailsModalClickLinkLineHeight: 2.2rem;
$productDetailsModalClickLinkFontWeight: normal;

/* Slide Menu */
$slideMenuTriggerIconBackground: ();
$slideMenuTriggerIconDisplay: none;
$slideMenuTriggerIconHeight: .8rem;
$slideMenuTriggerIconWidth: .8rem;
$slideMenuCloseButtonPositionTop: 2.5rem;
$slideMenuCloseButtonFontSize: 2rem;
$slideMenuCloseButtonColor: $brandPrimaryColor;
$slideMenuTriggerIconMargin: 0 .3rem 0;
$slideMenuTriggerIconAppliedDisplay: inline-block;
$slideMenuTriggerIconAppliedAfterBackground: #d14904;
$slideMenuTriggerIconAppliedAfterWidth: 100%;
$slideMenuTriggerIconAppliedAfterHeight: 100%;
$slideMenuTriggerIconAppliedAfterPositionTop: ();

/* Coupon Search Results */
$couponSearchResultsContentPadding: (small: 0, medium: 1.1rem 0 9.2rem 0);
$couponSearchResultsTitleMargin: 0 0 5rem;
$couponSearchResultsTitleH2FontFamily: $brandFontFamilyLight;
$couponSearchResultsTitleH2Color: $brandQuaternaryColor;
$couponSearchResultsTitleH2LineHeight: 3rem;
$couponSearchResultsTitleH2Margin: 0 0 .7rem;
$couponSearchResultsTitleH2TextAlign: left;
$couponSearchResultsTitleViewAllFontSize: 1.4rem;
$couponSearchResultsTitleViewAllLineHeight: 2rem;
$couponSearchResultsViewButtonColor: $brandPrimaryColor;
$couponSearchResultsViewButtonBackground: none;
$couponSearchResultsViewButtonBorder: 1px solid $brandPrimaryColor;
$couponSearchResultsViewButtonFontSize: 1.8rem;
$couponSearchResultsViewButtonLineHeight: 2.4rem;
$couponSearchResultsViewButtonBorderRadius: .5rem;
$couponSearchResultsSlideMenuTriggerColor: $brandPrimaryColor;
$couponSearchResultsSlideMenuTriggerFontSize: 1.4rem;
$scrFilterDesktopFilterByColor: $brandPrimaryGrey;
$scrFilterDesktopFilterByFontSize: 1.6rem;
$scrFilterDesktopFilterByLineHeight: 2.2rem;
$scrFilterDesktopButtonBorderRadius: .6rem;
$scrFilterDesktopButtonBorder: 1px solid $brandPrimaryColor;
$scrFilterDesktopButtonColor: $brandPrimaryColor;
$scrFilterDesktopButtonMinWidth: 13.4rem;
$scrFilterDesktopButtonAfterColor: $brandPrimaryColor;
$scrFilterDesktopClearFiltersColor: $brandPrimaryColor;
$scrFilterDesktopClearFiltersFontSize: 1.4rem;
$scrFilterDesktopClearFiltersLineHeight: 2rem;
$scrFilterDesktopFilteredMerchantFontSize: 1.4rem;
$scrFilterDesktopFilteredMerchantColor: $brandPrimaryColor;
$scrFilterDesktopFilteredMerchantBackground: $brandGray6;
$scrFilterDesktopFilteredMerchantButtonColor: $brandPrimaryColor;
$scrFilterMobileTitleFontSize: 2rem;
$scrFilterMobileTitleLineHeight: 2.6rem;
$scrFilterMobileTitleAfterColor: $brandPrimaryColor;
$scrFilterMobileClearFilterButtonTextDecoration: none;
$scrFilterMobileClearFilterButtonFontSize: 1.4rem;
$scrFilterMobileClearFilterButtonColor: $brandPrimaryColor;
$scrFilterMobileViewResultsButtonPadding: 1.5rem 4rem;
$scrFilterMobileViewResultsButtonFontSize: 1.8rem;
$scrFilterMobileViewResultsButtonBorderRadius: .5rem;
$merchantWithCouponsMargin: (small: 0 0 7rem, medium: 0 0 7rem);
$merchantWithCouponsRebatePrefixFontWeight: 700;
$merchantWithCouponsCountLineHeight: 2rem;
$merchantWithCouponsCountBackground: $brandGray6;
$merchantWithCouponsCouponCodeWrapLineHeight: 2rem;
$merchantWithCouponsCouponCodeLabelColor: $brandPrimaryGrey;
$merchantWithCouponsCouponCodeFontSize: 1.2rem;
$merchantWithCouponsCouponCodeLetterSpacing: .75px;
$merchantWithCouponsCouponCodeColor: $brandPrimaryColor;
$merchantWithCouponsCouponBorderBottom: 1px solid $brandSecondaryGray;
$merchantWithCouponsCouponButtonFontSize: 1.8rem;
$merchantWithCouponsCouponButtonBorder: 1px solid $brandPrimaryColor;
$merchantWithCouponsCouponButtonBorderRadius: .5rem;
$merchantWithCouponsCouponButtonBackground: none;
$merchantWithCouponsCouponButtonPadding: (small: .6rem 1rem, medium: .6rem 1rem);
$merchantWithCouponsCouponButtonWidth: 14.8rem;
$merchantWithCouponsCouponButtonColor: $brandPrimaryColor;

/* Sticky Content */
$stickyContentStuckTopPositionTop: 8rem;

/* Slide Menu */
$slideMenuTriggerStuckBackground: #e7ecef;
$slideMenuTriggerStuckPadding: 1.7rem 2.5rem;
$slideMenuTriggerStuckLineHeight: 2.2rem;
$slideMenuTriggerStuckHeight: 5.6rem;
$slideMenuTriggerStuckTextAfterFontFamily: 'fontello';
$slideMenuTriggerStuckTextAfterContent: '\f107';
$slideMenuTriggerStuckTextAfterFontSize: 2.2rem;
$slideMenuTriggerStuckTextAfterColor: $brandDarkBlue3;
$slideMenuTriggerStuckTextAfterMargin: 0 0 0 2.5rem;
$slideMenuTriggerStuckTextAfterPosition: relative;
$slideMenuTriggerStuckTextAfterPositionTop: .3rem;
$slideMenuTriggerStuckTextAfterFontWeight: normal;

/* Safari Button Install Tutorial */
$safariButtonInstallPermissionsTutorialBackground: $brandGray6;
$safariButtonInstallPermissionsTutorialImageBackgroundImage: url('~org/assets/safariButtonInstallTutorial/permissionSetting/gettingStarted.png');
$safariButtonInstallTutorialSetPermissionsClickImageWidth: 80rem;
$safariButtonInstallTutorialSetPermissionsClickImageHeight: 11.8rem;
$safariButtonInstallTutorialSetPermissionsClickImageMargin: .5rem 0 2.5rem;
$safariButtonInstallPermissionsTutorialDescriptionLogoHeight: 3.4rem;
$safariButtonInstallPermissionsTutorialDescriptionLogoWidth: 30rem;
$safariButtonInstallPermissionsTutorialDescriptionLogoMargin: 12.8rem 0 4.8rem;
$safariButtonInstallPermissionsTutorialDescriptionHeaderLineHeight: 3.6rem;
$safariButtonInstallPermissionsTutorialDescriptionHeaderFontWeight: normal;
$safariButtonInstallPermissionsTutorialDescriptionHeaderFontSize: 3rem;
$safariButtonInstallPermissionsTutorialDescriptionHeaderColor: $brandDarkBlue2;
$safariButtonInstallPermissionsTutorialDescriptionHeaderMargin: 0 0 1.2rem;
$safariButtonInstallPermissionsTutorialDescriptionImportantTextFontWeight: bold;
$safariButtonInstallPermissionsTutorialDescriptionPLineHeight: 2.2rem;
$safariButtonInstallPermissionsTutorialDescriptionPMargin: 0 0 2rem;
$nonSafariTutorialImageMargin: 16rem 0 4.5rem;
$nonSafariTutorialHeaderMargin: 0 0 6rem;
$nonSafariTutorialTextMargin: 0 1rem 1.4rem;
$nonSafariTutorialCopyLinkInputWidth: 29rem;
$nonSafariTutorialCopyLinkInputMargin: 0;
$nonSafariTutorialCopyLinkInputPadding: 1.6rem 1.5rem 1.4rem;
$nonSafariTutorialCopyLinkInputBorder: 1px solid #626b79;
$nonSafariTutorialCopyLinkInputColor: #626b79;
$nonSafariTutorialCopyLinkButtonBackground: $brandPrimaryColor;
$nonSafariTutorialCopyLinkButtonColor: $brandWhite;
$nonSafariTutorialCopyLinkedTextColor: $brandPrimaryColor;
$nonSafariTutorialCopyLinkButtonBorderRadius: 0 6px 6px 0;
$installTutorialStepsLogoMargin: 7rem 0 0;
$installTutorialStepsLogoPadding: 0 3.2rem;
$installTutorialStepsLogoHeight: auto;
$installTutorialStepsLogoWidth: 100%;
$installTutorialDescriptionMargin: (medium: 3.8rem 3.2rem 3.6rem, xlarge: 9.9rem 3.2rem 3.6rem);
$installTutorialDescriptionHeaderFontSize: 2.4rem;
$installTutorialDescriptionHeaderFontWeight: normal;
$installTutorialDescriptionHeaderLineHeight: 3.6rem;
$installTutorialDescriptionHeaderColor: $brandDarkBlue2;
$installTutorialDescriptionHeaderMargin: 0 0 1rem;
$installTutorialDescriptionTextLineHeight: 2.2rem;
$installTutorialDescriptionTextMargin: 0 0 1.6rem;
$installTutorialStepsBackgroundColor: $brandWhite;
$installTutorialListStepButtonPadding: 0 0 0 5.4rem;
$installTutorialListStepTextLineHeight: 2.2rem;
$installTutorialListStepsSelectedStepBackground: $brandGray6;
$couponFinderApplyButtonWidth: 25%;
$couponFinderArrowLeft: 41.5%;
$couponFinderArrowWidth: 40%;
$couponFinderArrowHeight: 29%;
$installTutorialWatchAgainButtonBorder: 1px solid $brandPrimaryColor;
$installTutorialWatchAgainButtonBackgroundColor: $brandWhite;
$installTutorialWatchAgainButtonColor: $brandPrimaryColor;
$installTutorialWatchAgainButtonBorderRadius: 5px;
$installTutorialWatchAgainButtonFontSize: 1.8rem;
$installTutorialVideosBackground: $brandGray6;
$installTutorialVideosExitButtonBorder: 1px solid $brandDarkBlue3;
$installTutorialVideosExitButtonColor: $brandDarkBlue3;
$installTutorialVideosExitButtonAfterFontFamily: $brandFontFamilyLight;

/* Home page */
$homePageMhpTopWrapPadding: (small: 2rem 0 0 0);
$homePageMhpTopWrapBackground: (small: linear-gradient(0deg, $brandDarkBlue2 0%, $brandDarkBlue3 100%), medium: linear-gradient(180deg, #074f87 0, $brandLightBlue2 100%));

/* Carousel */
$carouselDotBackground: unset;
$carouselDotBoxShadow: inset 0 0 0 .1rem #0970ab;
$carouselDotActiveBackground: #0970ab;
$carouselInvertedDotActiveBackground: #fff;
$carouselInvertedDotBoxShadow: inset 0 0 0 .1rem  #fff;
$carouselInvertedDotActiveBoxShadow: none;
$carouselCounterNumberFontSize: 1.6rem;
$carouselCounterNumberFontWeight: bold;
$carouselCounterColor: $brandPrimaryColor;
$carouselCounterArrowColor: $brandPrimaryColor;
$carouselCounterArrowFontSize: 2.6rem;
$carouselCounterNumberPositionTop: .2rem;

/* DNT Mobile Warning */
$dntMobileWarningWrapBorderColor: $brandPrimaryRed;
$dntMobileWarningWrapBorderRadius: 4px;
$dntMobileWarningIconColor: $brandPrimaryRed;
$dntMobileWarningIconBorder: 1px solid $brandPrimaryRed;
