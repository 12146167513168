@import '~org/styles/variables';

$creditCardMarketingBannerWrapperBackground: () !default;
$creditCardMarketingBannerWrapperBottomBorder: () !default;
$creditCardMarketingBannerWrapperPadding: () !default;

$creditCardBannerPadding: (small: 3rem 1rem 6.5rem 1rem, medium: 1.5rem 1rem, large: 1.5rem 3rem) !default;
$creditCardBannerTextAlign: (small: center, medium: center) !default;
$creditCardBannerPosition: relative !default;
$creditCardBannerBackground: () !default;
$creditCardBannerMargin: (small: 0 -2rem, medium: 0 -1rem, large: 0 -3rem, xlarge: 0 auto) !default;
$creditCardBannerDisplay: flex !default;
$creditCardBannerJustifyContent: space-between !default;
$creditCardBannerAlignItems: (medium: center, large: center) !default;
$creditCardBannerMaxWidth: 110rem !default;
$creditCardBannerBorderLeft: () !default;
$creditCardBannerInfoWrapMargin: () !default;
$creditCardBannerInfoWrapMaxWidth: 57rem !default;
$creditCardBannerBorderBottom: () !default;

$creditCardBannerBeforeContent: '' !default;
$creditCardBannerBeforeDisplay: (small: block, medium: none) !default;
$creditCardBannerBeforeHeight: 8rem !default;
$creditCardBannerBeforeBackground: () !default;
$creditCardBannerBeforePosition: absolute !default;
$creditCardBannerBeforeZIndex: 0 !default;
$creditCardBannerBeforePositionTop: 0 !default;
$creditCardBannerBeforePositionLeft: -50% !default;
$creditCardBannerBeforePositionRight: -50% !default;

$creditCardImageWrapWidth: () !default;
$creditCardImageWrapMargin: () !default;

$creditCardBannerImagePosition: relative !default;
$creditCardBannerImageMaxWidth: (small: 16.5rem, medium: 18.8rem, large: 18.8rem) !default;
$creditCardBannerImageDisplay: inline-block !default;
$creditCardBannerImageMargin: (small: (), medium: -5rem 0 -1.5rem, large: -5rem 0 -1.5rem) !default;

$creditCardBannerTipColor: () !default;
$creditCardBannerTipFontSize: () !default;
$creditCardBannerTipLineHeight: () !default;
$creditCardBannerTipLetterSpacing: () !default;
$creditCardBannerTipTextTransform: () !default;
$creditCardBannerTipFontWeight: () !default;

$creditCardBannerHeadingFontSize: () !default;
$creditCardBannerHeadingFontWeight: () !default;
$creditCardBannerHeadingLineHeight: () !default;
$creditCardBannerHeadingMargin: () !default;

$creditCardBannerTextColor: #000 !default;
$creditCardBannerTextMargin: (small: 1rem 0 2rem, medium: 0) !default;
$creditCardBannerTextFontSize: (small: 2rem, medium: 2rem, large: 2rem) !default;
$creditCardBannerTextFontWeight: (small: 500, medium: 500) !default;
$creditCardBannerTextLineHeight: (small: 3.2rem, medium: 3.2rem) !default;
$creditCardBannerTextTextAlign: (small: center, medium: center) !default;

$creditCardBannerTermsFontSize: () !default;
$creditCardBannerTermsMargin: () !default;
$creditCardBannerTermsLineHeight: () !default;

$creditCardBannerButtonWidth: (small: 100%, medium: unset) !default;
$creditCardBannerButtonMaxWidth: 30rem !default;
$creditCardBannerButtonPadding: (small: 1.44rem 3rem, medium: 1.44rem 3rem, large: 1.64rem 3rem) !default;
$creditCardBannerButtonBorder: () !default;
$creditCardBannerButtonMinWidth: (medium: 18rem, large: 26rem, xlarge: 30rem) !default;
$creditCardBannerButtonMaxHeight: 5.12rem !default;
$creditCardBannerButtonFontSize: () !default;

$creditCardBannerButtonAfterFontFamily: () !default;
$creditCardBannerButtonAfterContent: () !default;
$creditCardBannerButtonAfterFontSize: 1.4rem !default;
$creditCardBannerButtonAfterPadding: 0 0 0 .8rem !default;

$creditCardBannerLinkColor: () !default;
$creditCardBannerLinkFontSize: () !default;
$creditCardBannerLinkMargin: () !default;
$creditCardBannerLinkDisplay: () !default;
$creditCardBannerLinkTextAlign: () !default;

$creditCardBannerWrapperPadding: (medium: 0 1rem, large: 0 3rem) !default;
$creditCardBannerWrapperWidth: 100% !default;
$creditCardBannerWrapperBackground: () !default;
$creditCardBannerInsideWrapperDisplay: flex !default;
$creditCardBannerInsideWrapperJustifyContent: () !default;
$creditCardBannerInsideWrapperFlexGrow: () !default;
$creditCardBannerInsideWrapperWidth: () !default;
$creditCardBannerInsideWrapperMargin: () !default;

.mn_creditCardMarketingBannerWrapper {
  background: $creditCardMarketingBannerWrapperBackground;
  padding: $creditCardMarketingBannerWrapperPadding;

  .mn_creditCardMarketingBanner {
    padding: map-get($creditCardBannerPadding, small);
    text-align: map-get($creditCardBannerTextAlign, small);
    position: $creditCardBannerPosition;
    background: map-get($creditCardBannerBackground, small);
    margin: map-get($creditCardBannerMargin, small);
    border-bottom: $creditCardBannerBorderBottom;

    &:before {
      content: $creditCardBannerBeforeContent;
      display: map-get($creditCardBannerBeforeDisplay, small);
      height: $creditCardBannerBeforeHeight;
      background: $creditCardBannerBeforeBackground;
      position: $creditCardBannerBeforePosition;
      z-index: $creditCardBannerBeforeZIndex;
      top: $creditCardBannerBeforePositionTop;
      left: $creditCardBannerBeforePositionLeft;
      right: $creditCardBannerBeforePositionRight;
    }

    img {
      position: $creditCardBannerImagePosition;
      max-width: map-get($creditCardBannerImageMaxWidth, small);
      display: $creditCardBannerImageDisplay;
      margin: map-get($creditCardBannerImageMargin, small);
    }

    .mn_cardTip {
      color: $creditCardBannerTipColor;
      font-size: $creditCardBannerTipFontSize;
      line-height: $creditCardBannerTipLineHeight;
      letter-spacing: $creditCardBannerTipLetterSpacing;
      text-transform: $creditCardBannerTipTextTransform;
      font-weight: $creditCardBannerTipFontWeight;
    }

    h2 {
      font-size: $creditCardBannerHeadingFontSize;
      font-weight: map-get($creditCardBannerHeadingFontWeight, small);
      line-height: map-get($creditCardBannerHeadingLineHeight, small);
      margin: $creditCardBannerHeadingMargin;
    }

    p {
      color: $creditCardBannerTextColor;
      font-size: map-get($creditCardBannerTextFontSize, small);
      font-weight: map-get($creditCardBannerTextFontWeight, small);
      line-height: map-get($creditCardBannerTextLineHeight, small);
      text-align: map-get($creditCardBannerTextTextAlign, small);
      margin: map-get($creditCardBannerTextMargin, small);
    }

    .mn_cardTerms {
      font-size: map-get($creditCardBannerTermsFontSize, small);
      margin: $creditCardBannerTermsMargin;
      line-height: $creditCardBannerTermsLineHeight;
    }

    .mn_button {
      width: map-get($creditCardBannerButtonWidth, small);
      max-width: $creditCardBannerButtonMaxWidth;
      padding: map-get($creditCardBannerButtonPadding, small);
      border: $creditCardBannerButtonBorder;
      font-size: map-get($creditCardBannerButtonFontSize, small);

      &:after {
        font-family: $creditCardBannerButtonAfterFontFamily;
        content: $creditCardBannerButtonAfterContent;
        font-size: $creditCardBannerButtonAfterFontSize;
        padding: $creditCardBannerButtonAfterPadding;
      }
    }
  }

  @media #{$medium-screen} {
    padding: map-get($creditCardBannerWrapperPadding, medium);

    .mn_creditCardMarketingBanner {
      background: map-get($creditCardBannerBackground, medium);
      margin: map-get($creditCardBannerMargin, medium);
      display: $creditCardBannerDisplay;
      justify-content: $creditCardBannerJustifyContent;
      align-items: map-get($creditCardBannerAlignItems, medium);
      padding: map-get($creditCardBannerPadding, medium);
      border-left: $creditCardBannerBorderLeft;
      text-align: map-get($creditCardBannerTextAlign, medium);

      &:before {
        display: map-get($creditCardBannerBeforeDisplay, medium);
      }

      .mn_creditCardMarketingBannerInsideWrapper {
        display: $creditCardBannerInsideWrapperDisplay;
        justify-content: $creditCardBannerInsideWrapperJustifyContent;
        flex-grow: $creditCardBannerInsideWrapperFlexGrow;
        width: $creditCardBannerInsideWrapperWidth;
        margin: $creditCardBannerInsideWrapperMargin;
      }

      .mn_button {
        width: map-get($creditCardBannerButtonWidth, medium);
        min-width: map-get($creditCardBannerButtonMinWidth, medium);
        padding: map-get($creditCardBannerButtonPadding, medium);
        max-height: $creditCardBannerButtonMaxHeight;
        font-size: map-get($creditCardBannerButtonFontSize, medium);
      }

      .mn_externalLink {
        color: $creditCardBannerLinkColor;
        font-size: $creditCardBannerLinkFontSize;
        margin: $creditCardBannerLinkMargin;
        display: $creditCardBannerLinkDisplay;
        text-align: $creditCardBannerLinkTextAlign;
      }

      .mn_creditCardInfoWrap {
        max-width: $creditCardBannerInfoWrapMaxWidth;
        margin: $creditCardBannerInfoWrapMargin;
      }

      h2 {
        font-weight: map-get($creditCardBannerHeadingFontWeight, medium);
        line-height: map-get($creditCardBannerHeadingLineHeight, medium);
      }

      p {
        font-weight: map-get($creditCardBannerTextFontWeight, medium);
        line-height: map-get($creditCardBannerTextLineHeight, medium);
        font-size: map-get($creditCardBannerTextFontSize, medium);
        margin: map-get($creditCardBannerTextMargin, medium);
        text-align: map-get($creditCardBannerTextTextAlign, medium);
      }

      .mn_cardTerms {
        font-size: map-get($creditCardBannerTermsFontSize, medium);
      }

      .mn_creditCardImageWrap {
        width: map-get($creditCardImageWrapWidth, medium);
        margin: map-get($creditCardImageWrapMargin, medium);
      }

      img {
        max-width: map-get($creditCardBannerImageMaxWidth, medium);
        margin: map-get($creditCardBannerImageMargin, medium);
      }
    }
  }

  @media #{$large-screen} {
    padding: map-get($creditCardBannerWrapperPadding, large);

    .mn_creditCardMarketingBanner {
      margin: map-get($creditCardBannerMargin, large);
      padding: map-get($creditCardBannerPadding, large);
      align-items: map-get($creditCardBannerAlignItems, large);

      .mn_button {
        min-width: map-get($creditCardBannerButtonMinWidth, large);
        padding: map-get($creditCardBannerButtonPadding, large);
      }

      .mn_creditCardImageWrap {
        width: map-get($creditCardImageWrapWidth, large);
        margin: map-get($creditCardImageWrapMargin, large);
      }

      img {
        max-width: map-get($creditCardBannerImageMaxWidth, large);
        margin: map-get($creditCardBannerImageMargin, large);
      }

      p {
        font-size: map-get($creditCardBannerTextFontSize, large);
      }
    }
  }

  @media #{$xlarge-screen} {
    width: $creditCardBannerWrapperWidth;
    background: $creditCardBannerWrapperBackground;

    .mn_creditCardMarketingBanner {
      max-width: $creditCardBannerMaxWidth;
      margin: map-get($creditCardBannerMargin, xlarge);

      .mn_button {
        min-width: map-get($creditCardBannerButtonMinWidth, xlarge);
      }
    }
  }
}
