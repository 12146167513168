@import '~core/styles/mixins';
@import '~org/styles/variables';

.mn_mainNavigationTOF {
  width: 25rem;
  margin-right: 2.5rem;

  /* Navigation drop down */
  .mn_navigationDropdownWrapper {
    position: relative;
    padding-bottom: 3rem;
    margin-bottom: -3rem;

    .mn_navigationDropdown {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
      transform: translate3d(0, -10px, 0);
      transition: all .3s ease-in-out;
      position: absolute;
      top: 5.5rem;
      left: 0;
      width: 92rem;
      padding: 2rem;
      z-index: 110;
      text-align: left;
      display: flex;
      justify-content: space-between;
      background: $brandWhite;
      box-shadow: 0 1px 10px 1px rgba(0, 0, 0, .4);

      &:before {
        content: '';
        position: absolute;
        top: -20px;
        left: 0;
        height: 20px;
        width: 25rem;
        background: $brandWhite;
        z-index: -1;
        box-shadow: 5px 25px 0 5px $brandWhite, 0 1px 10px 1px rgba(0, 0, 0, .4);
      }
    }

    .mn_navigationDropdownTrigger {
      display: block;
      background: none;
      border: none;
      border-bottom: 1px solid $brandWhite;
      color: #fff;
      font-size: 1.8rem;
      line-height: 2.7rem;
      height: 3.5rem;
      padding: .4rem 1.5rem .4rem .2rem;
      text-align: left;
      width: 25rem;

      .mn_navArrow {
        position: absolute;
        top: 0;
        right: 0;

        &:before {
          font-family: 'fontello', serif;
          content: '\f107';
          font-size: 1.8rem;
          border: 1px solid $brandWhite;
          border-radius: 50%;
          height: 1.8rem;
          width: 1.8rem;
          display: block;
          line-height: 1;
          position: absolute;
          right: .5rem;
          top: .7rem;
          text-align: center;
        }
      }
    }

    &.mn_focused {
      .mn_navigationDropdown {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        pointer-events: all;
        visibility: visible;
      }

      .mn_navigationDropdownTrigger {
        .mn_navArrow:before {
          content: '\f106';
        }
      }
    }
  }

  /* Common navigation lists styles */
  h2 {
    font-size: 1.8rem;
    color: #36495a;
    font-weight: bold;
    margin: 0 0 1rem;
  }

  .mn_navDropdownMenu {
    width: 25rem;
    flex: 0 0 auto;
    margin: 0 -1rem;
    padding: 0;
    border: none;
    list-style: none;

    li {
      margin: 0;

      & > a {
        display: block;
        padding: .65rem 1rem;
        font-size: 1.4rem;
        position: relative;

        &:before {
          content: '';
          display: none;
          position: absolute;
          top: 0;
          bottom: 0;
          right: -5px;
          width: 1.3rem;
          background: #fcfcfc;
          z-index: 111;
        }

        &:hover,
        &:focus,
        &:active {
          background: #fcfcfc;
          box-shadow: 0 0 6px 0 rgba(0, 0, 0, .2);
          border-radius: 3px;
          text-decoration: none;
          z-index: 3;

          &:before {
            display: block;
          }
        }
      }
    }

   /* stylelint-disable */
    li.mn_navCategoriesTOF {
      & > a {
        &:after {
          display: none;
          content: '';
          position: absolute;
          top: 50%;
          right: 1rem;
          transform: translateY(-50%);
          width: 8px;
          height: 15px;
          background: url('~org/assets/images/icons/arrow_right_hover.svg') no-repeat 0 0;
          background-size: contain;
        }

        &:hover,
        &:focus,
        &:active {
          &:after {
            display: block;
          }
        }
      }
    }
  }

  .mn_leftMainNavPanelSect {
    flex: 0 0 24rem;
    max-width: 24rem;
    padding: 0;
    border-right: #ccc solid 1px;
  }

  .mn_middleMainNavPanelSect {
    flex: 0 0 30rem;
    max-width: 30rem;
    border-right: #ccc solid 1px;
    margin-bottom: 22rem;

    .mn_navDropdownMenu {
      li > a {
        display: flex;

        .mn_pill {
          width: 8rem;
          left: 11rem;
          bottom: .5rem;
          transform: none;
        }
      } 
    }
  }

  .mn_rightMainNavPanelSect {
    flex: 0 0 28rem;
    max-width: 28rem;
    margin-bottom: 26rem;
  }

  .mn_middleMainNavPanelSect,
  .mn_rightMainNavPanelSect {
    .mn_navDropdownMenu {
      li {
        & > a {
          &:hover,
          &:focus,
          &:active {
            color: #2172ba;
            background: #ecf0f3;
            font-weight: 700;
            box-shadow: none;
            border-radius: 0;
          }
        }
      }
    }
  }

  .mn_bottomMainNavPanelSect {
    width: 63rem;
    position: absolute;
    bottom: 9.5rem;
    left: 29rem;
  }
}
