@import '~org/styles/variables';

.mn_mobileNavWelcomeMessage {
  background: linear-gradient(180deg, $brandDarkBlue2 0, $brandLightBlue2 100%);
  text-align: left;
  padding: 5.2rem 1.5rem 2.5rem;
  color: #fff;
  position: relative;
  margin: 0 -1.5rem;

  .mn_closeHamburgerMenu {
    background: none;
    border: none;
    font-size: 2rem;
    position: absolute;
    top: 1.2rem;
    right: .8rem;
    color: $brandWhite;
  }

  .mn_button {
    display: block;
    max-width: 15.5rem;
    text-transform: none;
    padding: 0;
    margin: 0 auto;
    height: 3.6rem;
    line-height: 3.6rem;

    &.mn_logoutLink {
      margin: 1.5rem 0 0;
      color: $brandPrimaryGrey;
      background: linear-gradient(180deg, $brandWhite 0, #ebeef0 100%);
    }
  }

  h3 {
    color: $brandWhite;
    text-align: left;
    margin: 0;
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: 700;

    .mn_displayableUserId {
      font-weight: 500;
    }
  }

  p {
    margin: 0;
    font-size: 1.8rem;
    line-height: 2.3rem;

    a {
      font-weight: bold;
      color: $brandWhite;
    }
  }
}
