@import '~org/styles/variables';

.mn_navBar {
  .mn_mainNav {
    display: flex;
    flex-direction: column;
    user-select: none;
    margin-bottom: 1rem;

    &.mn_contentSection {
      padding: 1rem 0 0;
    }

    .mn_navigationListItem {
      &.mn_active {
        font-weight: 700;
      }
    }
  }

  .mn_extraRewardsPill {
    width: 10.5rem;
    left: calc(100% + 1rem);
    transform: none;
    height: 2.24rem;

    .mn_pillLabel {
      line-height: 2.24rem;
      font-size: 1.3rem;
      font-weight: 400;

      &:after {
        width: 2.24rem;
        height: 2.24rem;
      }
    }
  }
}
