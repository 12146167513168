@import '~org/styles/variables';

.mn_pageHeader {
  position: relative;
  z-index: 3;

  .mn_searchBar {
    position: relative;
    z-index: 1;
  }

  .mn_headerInner {
    background: $brandDarkBlue3;
    transform: translateZ(0);
    transition: opacity .5s ease-in-out;
  }

  .mn_headerMainBar {
    position: relative;
    z-index: 3;
  }

  // Inverted
  &.mn_inverse {
    .mn_headerInner {
      background: $brandWhite;

      .mn_searchAndNavWrap {
        .mn_hybridSearchForm {
          .mn_searchInput {
            border-bottom: 1px solid $brandQuaternaryColor;
            color: $brandTextColor;

            &:focus {
              border-bottom: none;
            }
          }

          .mn_searchInput::placeholder {
            color: $brandQuaternaryColor;
          }

          .mn_searchSubmit {
            i:before {
              color: $brandDarkBlue2;
            }
          }
        }
      }
    }

    .mn_brandLogo a:after {
      background: url('~org/assets/images/aa_logo.svg');
      background-size: contain;
      background-repeat: no-repeat;
    }

    .mn_brandLinks {
      .mn_loginLink {
        border: none;
        color: #fff;
        background-image: linear-gradient(to bottom, $brandPrimaryColor, $brandDarkBlue3);
      }
    }

    .mn_aaIconSearchSvg:before {
      background: url('~org/assets/images/icons/search_plain_blue.svg') no-repeat;
    }

    .mn_mainNavigationTOF {
      .mn_navigationDropdownWrapper {
        .mn_navigationDropdownTrigger {
          border-bottom: 1px solid $brandQuaternaryColor;
          color: $brandQuaternaryColor;
        }
      }
    }

    .mn_searchForm .mn_searchInput {
      color: $brandQuaternaryColor;
      border-bottom: 1px solid $brandQuaternaryColor;

      &::-webkit-input-placeholder { color: $brandQuaternaryColor; }
      &::-moz-placeholder { color: $brandQuaternaryColor; }
    }

    .mn_accountNavigation {
      color: $brandQuaternaryColor;
    }

    .mn_aaIconMyAccount:before {
      background: url('~org/assets/images/icons/my-acc-icon-blue.svg') no-repeat;
    }

    .mn_aaIconHelp:before {
      background: url('~org/assets/images/icons/help-icon-blue.svg') no-repeat;
    }

    .mn_aaIconExtraMiles:before {
      background: url('~org/assets/images/icons/extra-miles-icon-blue.svg') no-repeat;
    }

    .mn_aaIconFavorite:before {
      background: url('~org/assets/images/icons/heart-icon-blue.svg') no-repeat;
      background-size: contain;
      width: 3rem;
      background-position-y: 2px;
    }

    .mn_accountNavigation .mn_navigationDropdownTrigger:hover:after,
    .mn_navigationItem:hover:after {
      background: $brandQuaternaryColor;
    }

    .mn_favoritesSection .mn_navigationItem:hover:after {
      bottom: -.3rem;
    }

    .mn_aaIconHamburger:before {
      background: url('~org/assets/images/icons/hamburger-blue.svg') no-repeat;
    }
  }

  // Sticky
  &.mn_sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 101;
    transform: translateZ(0);

    .mn_headerInner {
      opacity: 1;
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .4);
    }

    .mn_headerMainBar {
      transition: .2s padding ease-in-out, .2s background ease-in-out;

      .mn_brandLogo a {
        &:before,
        &:after {
          transition: all .5s ease-in-out;
        }
      }
    }
  }

  .mn_brandLinks {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 0 2.5rem;

    .mn_loginLink {
      height: 3.5rem;
      width: 10rem;
      border: 1px solid $brandWhite;
      border-radius: 5px;
      line-height: 2.4rem;
      font-size: 1.8rem;
      background: transparent;
      padding: .5rem 1.5rem;
      font-weight: 400;
    }
  }

  @media #{$medium-screen} {
    .mn_headerInner {
      background: linear-gradient(180deg, $brandDarkBlue 0, #074f87 100%);
    }

    .mn_brandLinks {
      padding: .5rem 0 0;

      .mn_loginLink {
        margin-right: 1rem;
      }
    }
  }
}

// VHP header
.mn_VHP {
  .mn_pageHeader {
    .mn_headerInner {
      background: transparent;
    }

    &.mn_sticky {
      .mn_headerInner {
        background: linear-gradient(180deg, $brandDarkBlue3 0, #096bb4 100%);
      }
    }
  }
}

.mn_pageHeaderOffset {
  overflow: hidden;
  visibility: hidden;
  flex: 0 0 0;
}

html:not(.mn_open) {
  .mn_pageHeader.mn_isAnonymous {
    &:not(.mn_sticky) {
      .mn_headerMainBar {
        background: none;
        max-width: 1420px;
        margin: 0 auto;
      }
    }
  }
}
