@import '~core/styles/mixins';
@import 'variables';

@mixin mn_linkWithArrow($fontSize: 1.2rem, $margin: 0 0 0 .8rem) {
  &:after {
    content: '\e005';
    font-family: 'americanairlines-icon', serif;
    font-size: $fontSize;
    vertical-align: middle;
    display: inline-block;
    margin: $margin;
  }
}

@mixin top-shadow() {
  position: relative;

  &:before {
    content: '';
    background: url('../assets/images/shadow_up.png') no-repeat 50% 0;
    background-size: contain;
    width: 100%;
    height: 1.5rem;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }
}
