@import 'variables';
@import 'mixins';
@import 'interstitial';
@import 'icons';
@import '~core/styles/responsive-utilities';
@import '~core/styles/grid';
@import '~core/styles/mixins';
@import '~core/styles/defaults';

/* Fonts */
@import 'fonts';
@import '~core/fonts/fontello/css/fontello';
@import '~org/fonts/aa-categories/css/aa_categories';

.mn_pageWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;

  @media #{$xlarge-screen} {
    .mn_pageBody {
      min-height: 92rem; // compensate large desktop navigation height
    }
  }
}

html {
  width: 100%;

  &.mn_isIphoneSafari {
    overflow-y: initial;
  }

  &.mn_open {
    .mn_pageWrapper {
      min-height: calc(#{$hamburgerMenuNavMinHeight} + 100px);
    }

    @media #{$less-than-medium-screen} {
      overflow-y: hidden;
    }
  }
}

body:not(#mn_h, #mn_button-safari-tutorial) .mn_pageWrapper {
  @media #{$medium-screen} {
    border-top: 5px solid #4fb3f5;
  }
}

.mn_noScroll {
  position: absolute;
  overflow: hidden;
  width: 100%;
}

.mn_externalLink:after {
  font-family: 'AmericanIcons', serif;
  content: '\e03f';
  display: inline-block;
  margin-left: 5px;
}

.mn_checkbox,
.mn_radio {
  label {
    position: relative;
    display: inline-block;
    padding-left: 3.5rem;

    &:before,
    &:after {
      position: absolute;
      content: '';
      display: inline-block;
    }

    &:before {
      background-color: $brandWhite;
      color: $brandWhite;
      height: 20px;
      width: 20px;
      border: $brandBorder;
      box-shadow: inset 0 0 0 2px $brandWhite, inset 0 0 0 3px $brandSecondaryGray;
      vertical-align: middle;
      left: 0;
    }

    &:after {
      height: .4rem;
      width: .8rem;
      border: 2px solid $brandWhite;
      border-top-style: none;
      border-right-style: none;
      margin: .5rem;
      transform: rotate(-45deg);
      left: .2rem;
      top: .1rem;
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    opacity: 0;
    position: absolute;

    & + label:after {
      content: none;
    }

    &:checked + label:before {
      box-shadow: inset 0 0 0 2px $brandWhite, inset 0 0 0 3px $brandPrimaryColor;
      background-color: $brandPrimaryColor;
    }

    &:checked + label:after {
      content: '';
    }

    &:focus + label:before {
      outline: 1px dotted $brandBlack;
    }
  }
}

.mn_radio input[type='radio'] {
  & ~ label .mn_control {
    border-radius: 100%;
  }
}

.mn_letterboxTOF {
  @media #{$less-than-medium-screen} {
    .mn_additionalElevationPrefix {
      display: none;
    }
  }
}

sup {
  line-height: 1;
  font-size: 80%;
}

.mn_searchBar {
  .mn_quickSearch {
    .mn_quickSearchResults.mn_noResult {
      display: flex;
      flex-direction: column;

      .mn_productLinkWrap {
        order: 2;
      }

      .mn_quickSearchResults {
        order: 1;
        border-width: 0;
      }
    }
  }
}

html.mn_MHP {
  @media #{$medium-screen} {
    .mn_pageTopWrap:after {
      content: '';
      position: relative;
      display: block;
      height: .5rem;
      background: linear-gradient(90deg, $brandDarkBlue2 0, rgba(0, 70, 127, 0));
    }
  }

  .mn_newMembers {
    .mn_emailOptInWrapper {
      .mn_decorationLine {
        border-bottom: 1px solid rgba(54, 73, 90, .15);
        position: absolute;
        bottom: 0;
        width: 80%;
        left: calc(50% - 80% / 2);
      }
    }
  }
}

html.mn_VHP {
  .mn_pageWrapper {
    background: linear-gradient(108deg, #0061ab 0%, #4db4fa 100%) top / 100% 100rem no-repeat;
  }

  .mn_brandFooter {
    padding: 0;
  }

  .mn_htmlText {
    margin: 7.5rem auto 3.8rem;
    font-size: 1.3rem;
    color: $brandQuaternaryColor;
  }

  @media #{$medium-screen} {
    .mn_brandFooter {
      padding: 5.5rem 1rem 8.5rem;
    }
  }
}

#mn_refer-a-friend {
  .mn_pageHeader .mn_brandLinks {
    display: none;
  }

  @media #{$large-screen} {
    .mn_pageHeader .mn_brandLinks {
      display: flex;
    }
  }
}

#mn_hsr .mn_hybridStores .mn_contentWrap,
#mn_msr .mn_hybridStores .mn_contentWrap,
#mn_sr .mn_productSearchResults .mn_contentWrap,
#mn_csr .mn_couponSearchResults .mn_contentWrap {
  @include top-shadow();

  &:before {
    top: -3rem;
  }
}

#mn_button-safari-tutorial {
  .mn_adBlockWarning {
    display: none;
  }

  .mn_pageHeader {
    display: none;
  }

  .mn_pageHeaderOffset {
    display: none;
  }

  .mn_brandFooter {
    display: none;
  }
}

.mn_pMHP {
  .mn_howItWorks {
    .mn_sectionTitle {
      margin: 0 0 1rem 0;

      h2 {
        font-size: 3.5rem;
      }
    }

    .mn_watchVideoLinkWrapper {
      margin-bottom: 2rem;
    }

    .mn_item {
      .mn_imageWrap img {
        width: 22.6rem;
      }
    }
  }
}
