@import '~core/styles/mixins';
@import '~org/styles/variables';

.mn_accountNavigation {
  display: flex;
  justify-content: flex-start;
  color: $brandWhite;
  margin-left: 2rem;

  /* Account Navigation elements */
  .mn_navigationWrap {
    display: flex;
    align-items: center;
    margin: 0 -1rem 0 0;
    padding: 0;
    list-style: none;

    & > li:not(.mn_userSection) {
      padding: 0 .5rem;
    }

    .mn_navigationItem {
      display: block;
      padding: 0 .5rem 1.3rem;
      margin-top: 1.3rem;
      text-decoration: none;
      color: #fff;
      text-align: center;
      background: none;
      border: none;
      position: relative;

      & > i {
        display: block;
        width: 2rem;
        height: 2.2rem;
        line-height: 1.9rem;
        font-size: 2.8rem;
      }
    }

    .mn_searchTrigger {
      cursor: pointer;
      margin: 1.8rem 0 0;
    }
  }

  /* Dropdown */
  .mn_navigationDropdownTrigger {
    position: relative;
    margin: 1.8rem -.1rem 0;
    padding: 0 0 1.8rem;
    display: flex;
    align-items: flex-end;

    &:hover,
    &:focus,
    &:active {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: -8px;
        bottom: 15px;
        height: 3px;
        background: $brandWhite;
      }
    }

    .mn_navigationItem {
      position: relative;
      z-index: 2;
    }

    .mn_navigationDropdown {
      opacity: 0;
      visibility: hidden;
      transform: translate3d(-25%, -20px, 0);
      transition: all .3s ease-in;
      position: absolute;
      top: 6.5rem;
      right: -5rem;
      width: 14.3rem;
      z-index: 2;
      pointer-events: none;
      text-align: left;
      background: #fff;
      color: #000;
      padding: 1rem;
      box-shadow: 0 2px 10px 0 #627a88;

      &:before {
        content: '';
        left: 68%;
        margin: -8px 0 0 0;
        pointer-events: none;
        position: absolute;
        width: 0;
        height: 0;
        bottom: 0;
        top: 0;
        box-sizing: border-box;
        border: 1rem solid $brandWhite;
        border-color: transparent transparent $brandWhite $brandWhite;
        transform: rotate(135deg);
        box-shadow: -3px 2px 2px 0 rgba(0, 0, 0, .05);
        background: $brandWhite;
      }

      &.mn_extraRewardsNavigationDropdown {
        padding: 2.2rem 1.7rem;

        &:before {
          left: 87%;
        }

        .mn_flipTile .mn_premierMerchantRebate {
          text-align: center;
        }
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          margin: .5rem 0;
          padding: 0;
          font-size: 1.4rem;
          line-height: 1.8rem;
        }

        a {
          display: block;
        }
      }
    }

    &.mn_focused {
      .mn_navigationDropdown {
        opacity: 1;
        transform: translate3d(-21%, 0, 0);
        visibility: visible;
        pointer-events: auto;
      }
    }
  }

  .mn_favoritesSection {
    .mn_navigationItem {
      &:hover {
        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: -10px;
          bottom: -5px;
          height: 3px;
          background: $brandWhite;
        }
      }
    }
  }

  /* User section */
  .mn_userSection {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0;

    .mn_navigationDropdownTrigger .mn_navigationItem > i {
      &:hover {
        &:after {
          display: none;
        }
      }
    }

    .mn_userInfo {
      margin-right: .5rem;
      font-size: 12px;
      line-height: 1.8rem;
      text-align: right;
      min-width: 195px;

      .mn_userNameWrap {
        display: block;
        white-space: nowrap;
        font-weight: 700;

        .mn_userName {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 17rem;
          vertical-align: bottom;
        }
      }
    }

    .mn_navigationDropdown {
      .mn_highlitedSection {
        padding: 1.2rem 1.7rem;
        background: #f7f7f7;
        color: #000;

        .mn_userId {
          text-transform: uppercase;
          font-weight: bold;
          margin-bottom: .5rem;
        }

        .mn_lifetimeEarning {
          font-size: 1.2rem;
          margin: 0 0 .5rem;
        }
      }
    }

    .mn_button {
      height: 4.8rem;
      width: 12rem;
    }
  }

  /* Extra Rewards Dropdown section */
  .mn_headerExtraRewardsSection {
    .mn_navigationDropdown {
      right: -10rem;
      width: 35.7rem;
    }
  }
}

/* Secondary navigation search bar for tablet and small desktop */
.mn_secondaryNavSearchBar {
  margin: 0 0 0 auto;

  .mn_searchBar {
    width: 31.5rem;
    padding: 0;

    &:before {
      display: none;
    }

    .mn_searchForm {
      .mn_dropDownWrap {
        top: 2.5rem;
        box-shadow: 0 2px 4px 0 $brandQuaternaryColor;
      }

      .mn_searchInput {
        font-size: 1.8rem;
        height: 3.5rem;
        padding: .4rem 3.5rem .4rem .6rem;
        display: block;

        &::placeholder {
          color: $brandWhite;
        }
      }

      .mn_searchSubmit {
        position: absolute;
        top: .5rem;
        right: 0;
        height: 3.5rem;
        line-height: 3.5rem;
        font-size: 2.2rem;
        width: 3rem;
      }
    }
  }
}
