/* stylelint-disable */
@font-face {
  font-family: 'aa_categories';
  src: url('../font/aa_categories.eot?63712187');
  src:
  url('../font/aa_categories.eot?63712187#iefix') format('embedded-opentype'),
  url('../font/aa_categories.woff2?63712187') format('woff2'),
  url('../font/aa_categories.woff?63712187') format('woff'),
  url('../font/aa_categories.ttf?63712187') format('truetype'),
  url('../font/aa_categories.svg?63712187#aa_categories') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */

/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */

/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'aa_categories';
    src: url('../font/aa_categories.svg?63712187#aa_categories') format('svg');
  }
}
*/

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'aa_categories';
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-appliances:before { content: '\e800'; }
.icon-babies-kids-toys:before { content: '\e801'; }
.icon-beauty-skincare:before { content: '\e802'; }
.icon-bopuis:before { content: '\e803'; }
.icon-computers-electronics:before { content: '\e804'; }
.icon-domain-names:before { content: '\e805'; }
.icon-entertainment-events:before { content: '\e806'; }
.icon-flowers-gifts:before { content: '\e807'; }
.icon-food-dining:before { content: '\e808'; }
.icon-free-ship:before { content: '\e809'; }
.icon-furniture-decor:before { content: '\e80a'; }
.icon-gift-cards:before { content: '\e80b'; }
.icon-gifts:before { content: '\e80c'; }
.icon-health-wellness:before { content: '\e80d'; }
.icon-home-kitchen-bath:before { content: '\e80e'; }
.icon-hotels-accommodations:before { content: '\e80f'; }
.icon-jewelry-accessories:before { content: '\e810'; }
.icon-magazines-newspapers:before { content: '\e811'; }
.icon-mens-clothing:before { content: '\e812'; }
.icon-office-school-supplies:before { content: '\e813'; }
.icon-shoes:before { content: '\e814'; }
.icon-shop:before { content: '\e815'; }
.icon-spa-treatments:before { content: '\e816'; }
.icon-sports-outdoors:before { content: '\e817'; }
.icon-tools-auto-pets:before { content: '\e818'; }
.icon-travel-luggage:before { content: '\e819'; }
.icon-wireless-services:before { content: '\e81a'; }
.icon-womens-clothing:before { content: '\e81b'; }
