@import '~org/styles/variables';

$countdownDivider: 0 .3rem !default;
$countdownFontWeight: bold !default;

$countdownLettersFontWeight: normal !default;
$countdownLettersDaysContent: (small: 'D', medium: 'DAYS', large: 'D') !default;
$countdownLettersHoursContent: (small: 'H', medium: 'HOURS', large: 'H') !default;
$countdownLettersMinutesContent: (small: 'M', medium: 'MINUTES', large: 'M') !default;
$countdownLettersSecondsContent: (small: 'S', medium: 'SECONDS', large: 'S') !default;
$countdownLettersSecondsDisplay: () !default;

.mn_countdown {
  white-space: nowrap;

  span {
    font-weight: $countdownFontWeight;
    color: #333;

    &:before {
      content: ':';
      color: #999;
      margin: $countdownDivider;
    }

    &:first-of-type:before { display: none; }

    &:after {
      font-weight: $countdownLettersFontWeight;
    }

    &.mn_days:after { content: map-get($countdownLettersDaysContent, small); }
    &.mn_hours:after { content: map-get($countdownLettersHoursContent, small); }
    &.mn_minutes:after { content: map-get($countdownLettersMinutesContent, small); }

    &.mn_seconds {
      display: map-get($countdownLettersSecondsDisplay, small);

      &:after { content: map-get($countdownLettersSecondsContent, small); }
    }
  }

  @media #{$medium-screen} {
    span {
      &.mn_days:after { content: map-get($countdownLettersDaysContent, medium); }
      &.mn_hours:after { content: map-get($countdownLettersHoursContent, medium); }
      &.mn_minutes:after { content: map-get($countdownLettersMinutesContent, medium); }

      &.mn_seconds {
        display: map-get($countdownLettersSecondsDisplay, medium);

        &:after { content: map-get($countdownLettersSecondsContent, medium); }
      }
    }
  }

  @media #{$large-screen} {
    span {
      &.mn_days:after { content: map-get($countdownLettersDaysContent, large); }
      &.mn_hours:after { content: map-get($countdownLettersHoursContent, large); }
      &.mn_minutes:after { content: map-get($countdownLettersMinutesContent, large); }

      &.mn_seconds {
        display: map-get($countdownLettersSecondsDisplay, large);

        &:after { content: map-get($countdownLettersSecondsContent, large); }
      }
    }
  }
}
