@import '~org/styles/variables';

.mn_headerSecondaryBar {
  pointer-events: none;
  right: -100%;
  width: 100%;
  height: auto;
  max-height: calc(100vh + 1rem);
  background: $brandWhite;
  transform: translate3d(0, 0, 0);
  padding: 0 1.5rem 10rem;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  transition: all .5s;
  top: 0;
  position: absolute;
  z-index: 20;

  .mn_divider {
    display: block;
    height: 5px;
    margin: 0 -1.5rem;
    background: linear-gradient(90deg, #00467f 0, #439fd5 100%);
  }

  .mn_open & {
    pointer-events: auto;
    transform: translateX(-100%);
  }

  @media #{$medium-screen} {
    position: absolute;
    right: -400px;
    width: 400px;
    max-height: 0;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    background: #fff;
    font-weight: 500;
    z-index: 5;
    transition: all .5s;
    transform: translate(0, 0);
    visibility: hidden;
    top: 0;

    .mn_open & {
      transform: translateX(-400px);
      visibility: visible;
      position: absolute;
      overflow: hidden;
      min-height: $hamburgerMenuNavMinHeight;
      max-height: auto;
    }
  }
}

/* Full page height navigation background */
@media #{$medium-screen} and #{$less-than-xlarge-screen} {
  body {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 12.5rem;
      bottom: 0;
      right: -400px;
      z-index: 3;
      width: 400px;
      background: #fff;
      transition: all .5s;
    }
  }

  html.mn_open {
    overflow-x: hidden;

    body {
      overflow: visible;

      &:before {
        transform: translateX(-400px);
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .5);
      }
    }
  }
}
