@import '~core/styles/mixins';
@import '~org/styles/variables';

.mn_searchTriggerWrapper {
  position: relative;

  button {
    font-size: 2.2rem;
    color: $brandWhite;
    padding: 0;
    background: none;
    border: none;
    display: block;
    height: 4.2rem;
    width: 4rem;

    &[aria-expanded='true'] {
      opacity: 1;
      background: rgba(255, 255, 255, .25);
      border-radius: 4px;
      pointer-events: all;

      &:before {
        margin: .5rem 0 0 1rem;
      }
    }
  }
}
