@import '~org/styles/variables';

.mn_brandFooter {
  text-align: left;
  padding: 2rem 0 0 0;

  .mn_brandFooterInner {
    display: flex;
  }

  .mn_footerNav {
    flex-basis: 70%;
    margin: 0 4% 0 0;

    ul {
      padding: 0 0 1rem 1rem;
      list-style: none;

      li {
        padding: .5rem 0;

        a {
          font-size: 1.6rem;
          color: $brandWhite;
        }
      }
    }
  }

  .mn_footerRightSide {
    display: flex;
    flex-direction: column;
  }

  .mn_backToTopButtonWrap {
    background: $brandWhite;
    padding: 5.5rem 1rem 8.5rem;
    text-align: center;

    .mn_backToTopButton {
      color: $brandPrimaryColor;
      font-size: 1.6rem;
      background: none;
      border: none;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media #{$medium-screen} {
    background: $brandPrimaryGrey;
    padding: 5.5rem 2rem 8.5rem;
  }

  @media #{$large-screen} {
    .mn_footerNav {
      flex-basis: 45%;
    }
  }

  @media #{$large-screen} {
    .mn_footerNav {
      flex-basis: 33%;
    }
  }
}
