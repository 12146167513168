@import '~org/styles/variables';

$bonusRollingModalPadding: 0 !default;
$bonusRollingModalMaxWidth: calc(100% - 2rem) !default;
$bonusRollingModalMinHeight: auto !default;
$bonusRollingModalMargin: 0 auto !default;
$bonusRollingModalBorder: () !default;
$bonusRollingModalWidth: (small: (), medium: 80rem, large: 80rem) !default;

$bonusRollingModalCloseColor: () !default;

$bonusRollingModalTitleWrapMinHeight: 8rem !default;
$bonusRollingModalTitleWrapBackground: () !default;
$bonusRollingModalTitleWrapBorderBottom: () !default;

$bonusRollingModalTitleWrapBeforeBackground: () !default;
$bonusRollingModalTitleWrapBeforeBackgroundRepeat: () !default;
$bonusRollingModalTitleWrapBeforeBackgroundSize: () !default;
$bonusRollingModalTitleWrapBeforeBackgroundPosition: () !default;
$bonusRollingModalTitleWrapBeforeOpacity: () !default;

$bonusRollingModalTitleColor: () !default;
$bonusRollingModalTitleFontSize: () !default;
$bonusRollingModalTitleLineHeight: () !default;
$bonusRollingModalTitleFontWeight: () !default;
$bonusRollingModalTitleLetterSpacing: () !default;
$bonusRollingModalTitleTextAlign: center !default;
$bonusRollingModalTitleTextTransform: uppercase !default;
$bonusRollingModalTitlePadding: 0 2rem !default;

$bonusRollingModalCountdownMinWidth: 18rem !default;

$bonusRollingModalCountdownSpanColor: () !default;
$bonusRollingModalCountdownSpanFontWeight: () !default;

$bonusRollingModalCountdownSpanBeforeColor: () !default;
$bonusRollingModalCountdownSpanBeforeFontWeight: () !default;
$bonusRollingModalCountdownSpanBeforeMargin: () !default;

$bonusRollingModalCountdownSpanAfterFontWeight: () !default;
$bonusRollingModalCountdownSpanAfterPadding: () !default;

$bonusRollingModalInfoPadding: (small: 1rem 1rem 2rem 1rem, medium: 3rem 3rem 2rem 3rem, large: 4rem 3rem 2rem 3rem) !default;

$bonusRollingModalInfoH2Margin: (small: 0 0 1rem 0, medium: 0 0 3rem 0, large: 0 0 4rem 0) !default;
$bonusRollingModalInfoH2Color: () !default;
$bonusRollingModalInfoH2FontFamily: () !default;
$bonusRollingModalInfoH2FontSize: (small: (), medium: (), large: ()) !default;
$bonusRollingModalInfoH2LineHeight: (small: (), medium: (), large: ()) !default;
$bonusRollingModalInfoH2TextAlign: center !default;

$bonusRollingModalInfoButtonMinHeight: () !default;
$bonusRollingModalInfoButtonMinWidth: () !default;
$bonusRollingModalInfoButtonBorderRadius: () !default;
$bonusRollingModalInfoButtonBackground: () !default;
$bonusRollingModalInfoButtonMargin: 0 0 2rem 0 !default;
$bonusRollingModalInfoButtonFontSize: () !default;

$bonusRollingModalInfoButtonHoverBackground: () !default;

$bonusRollingModalInfoTermsButtonColor: () !default;
$bonusRollingModalInfoTermsButtonFontSize: () !default;
$bonusRollingModalInfoTermsButtonLineHeight: () !default;

$bonusRollingModalInfoCloseTermsPositionTop: (small: 0, medium: 0) !default;
$bonusRollingModalInfoCloseTermsPositionRight: (small: 0, medium: -1rem) !default;
$bonusRollingModalInfoCloseTermsColor: #000 !default;
$bonusRollingModalInfoCloseTermsFontSize: 1.8rem !default;

$bonusRollingModalInfoTermsInfoMinHeight: 17.5rem !default;
$bonusRollingModalInfoTermsInfoMargin: (small: 0 -.7rem 1rem 0, medium: 0 -2rem 0 0) !default;
$bonusRollingModalInfoTermsInfoPadding: 0 2.6rem 0 0 !default;

$bonusRollingModalInfoTermsInfoScrollbarWidth: .4rem !default;

$bonusRollingModalInfoTermsInfoScrollbarThumbBackground: #d0dae0 !default;
$bonusRollingModalInfoTermsInfoScrollbarThumbBorderRadius: .4rem !default;

$bonusRollingModalInfoTermsInfoScrollbarThumbHoverBackground: #c7d0d6 !default;

$bonusRollingModalInfoTermsInfoH3Color: () !default;
$bonusRollingModalInfoTermsInfoH3FontSize: 1.4rem !default;
$bonusRollingModalInfoTermsInfoH3LineHeight: 1.6rem !default;
$bonusRollingModalInfoTermsInfoH3FontWeight: bold !default;
$bonusRollingModalInfoTermsInfoH3Margin: 0 0 1rem 0 !default;

$bonusRollingModalInfoTermsInfoTextTextAlign: left !default;
$bonusRollingModalInfoTermsInfoTextColor: () !default;
$bonusRollingModalInfoTermsInfoTextFontFamily: () !default;
$bonusRollingModalInfoTermsInfoTextFontSize: 1rem !default;
$bonusRollingModalInfoTermsInfoTextLineHeight: 1.6rem !default;

$bonusRollingModalHiwBackground: #f5f7f7 !default;
$bonusRollingModalHiwPadding: 2rem !default;

$bonusRollingModalHiwH3Color: () !default;
$bonusRollingModalHiwH3FontSize: (small: 1.6rem, medium: (), large: 2rem) !default;
$bonusRollingModalHiwH3LineHeight: (small: 2.2rem, medium: (), large: 2.6rem) !default;
$bonusRollingModalHiwH3Margin: (small: 0 0 1rem 0, medium: 0 0 2rem 0, large: ()) !default;
$bonusRollingModalHiwH3FontWeight: bold !default;

$bonusRollingModalHiwItemsColumnGap: 3rem !default;

$bonusRollingModalHiwItemColumnGap: 2rem !default;

$bonusRollingModalHiwItemNumberTextAlign: left !default;
$bonusRollingModalHiwItemNumberColor: (small: (), medium: (), large: ()) !default;
$bonusRollingModalHiwItemNumberFontSize: (small: 1.6rem, medium: (), large: 3.8rem) !default;
$bonusRollingModalHiwItemNumberLineHeight: (small: 2.2rem, medium: (), large: 4.4rem) !default;
$bonusRollingModalHiwItemNumberFontFamily: (small: (), medium: (), large: ()) !default;

$bonusRollingModalHiwItemTextTextAlign: left !default;
$bonusRollingModalHiwItemTextColor: () !default;
$bonusRollingModalHiwItemTextFontSize: (small: 1.4rem, medium: (), large: 1.5rem) !default;
$bonusRollingModalHiwItemTextLineHeight: (small: 2rem, medium: (), large: 2.1rem) !default;

.mn_bonusRollingModal {
  .mn_modal {
    padding: $bonusRollingModalPadding;
    max-width: $bonusRollingModalMaxWidth;
    min-height: $bonusRollingModalMinHeight;
    margin: $bonusRollingModalMargin;
    border: $bonusRollingModalBorder;
    width: map-get($bonusRollingModalWidth, small);
  }

  .mn_closeModal {
    color: $bonusRollingModalCloseColor;
  }

  .mn_titleWrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: $bonusRollingModalTitleWrapMinHeight;
    background: $bonusRollingModalTitleWrapBackground;
    border-bottom: $bonusRollingModalTitleWrapBorderBottom;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      pointer-events: none;
      background: $bonusRollingModalTitleWrapBeforeBackground;
      background-repeat: $bonusRollingModalTitleWrapBeforeBackgroundRepeat;
      background-size: $bonusRollingModalTitleWrapBeforeBackgroundSize;
      background-position: $bonusRollingModalTitleWrapBeforeBackgroundPosition;
      opacity: $bonusRollingModalTitleWrapBeforeOpacity;
    }

    .mn_title {
      color: $bonusRollingModalTitleColor;
      font-size: $bonusRollingModalTitleFontSize;
      line-height: $bonusRollingModalTitleLineHeight;
      font-weight: $bonusRollingModalTitleFontWeight;
      letter-spacing: $bonusRollingModalTitleLetterSpacing;
      text-align: $bonusRollingModalTitleTextAlign;
      text-transform: $bonusRollingModalTitleTextTransform;
      padding: $bonusRollingModalTitlePadding;

      .mn_countdown {
        min-width: $bonusRollingModalCountdownMinWidth;
        display: inline-block;
        text-align: left;

        .mn_days:after { content: 'D'; }
        .mn_hours:after { content: 'H'; }
        .mn_minutes:after { content: 'M'; }
        .mn_seconds:after { content: 'S'; }

        span {
          color: $bonusRollingModalCountdownSpanColor;
          font-weight: $bonusRollingModalCountdownSpanFontWeight;

          &:before {
            color: $bonusRollingModalCountdownSpanBeforeColor;
            font-weight: $bonusRollingModalCountdownSpanBeforeFontWeight;
            margin: $bonusRollingModalCountdownSpanBeforeMargin;
          }

          &:after {
            font-weight: $bonusRollingModalCountdownSpanAfterFontWeight;
            padding: $bonusRollingModalCountdownSpanAfterPadding;
          }
        }
      }
    }
  }

  .mn_info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: map-get($bonusRollingModalInfoPadding, small);

    h2 {
      margin: map-get($bonusRollingModalInfoH2Margin, small);
      color: $bonusRollingModalInfoH2Color;
      font-family: $bonusRollingModalInfoH2FontFamily;
      font-size: map-get($bonusRollingModalInfoH2FontSize, small);
      line-height: map-get($bonusRollingModalInfoH2LineHeight, small);
      text-align: $bonusRollingModalInfoH2TextAlign;
    }

    .mn_button {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: $bonusRollingModalInfoButtonMinHeight;
      min-width: $bonusRollingModalInfoButtonMinWidth;
      border-radius: $bonusRollingModalInfoButtonBorderRadius;
      background: $bonusRollingModalInfoButtonBackground;
      margin: $bonusRollingModalInfoButtonMargin;
      font-size: $bonusRollingModalInfoButtonFontSize;

      &:hover {
        background: $bonusRollingModalInfoButtonHoverBackground;
      }
    }

    .mn_termsButton {
      padding: 0;
      background: unset;
      border: none;
      cursor: pointer;
      color: $bonusRollingModalInfoTermsButtonColor;
      font-size: $bonusRollingModalInfoTermsButtonFontSize;
      line-height: $bonusRollingModalInfoTermsButtonLineHeight;

      &:hover {
        text-decoration: underline;
      }
    }

    .mn_termsWrap {
      position: relative;

      .mn_closeTerms {
        position: absolute;
        right: map-get($bonusRollingModalInfoCloseTermsPositionRight, small);
        top: map-get($bonusRollingModalInfoCloseTermsPositionTop, small);
        background: unset;
        padding: 0;
        cursor: pointer;
        border: none;
        color: $bonusRollingModalInfoCloseTermsColor;
        font-size: $bonusRollingModalInfoCloseTermsFontSize;
      }

      .mn_termsInfo {
        overflow-y: scroll;
        max-height: $bonusRollingModalInfoTermsInfoMinHeight;
        margin: map-get($bonusRollingModalInfoTermsInfoMargin, small);
        padding: $bonusRollingModalInfoTermsInfoPadding;

        &.mn_firefox {
          scrollbar-color: #999 #fff;
          scrollbar-width: thin;
        }

        &::-webkit-scrollbar {
          width: $bonusRollingModalInfoTermsInfoScrollbarWidth;
        }

        &::-webkit-scrollbar-thumb {
          background: $bonusRollingModalInfoTermsInfoScrollbarThumbBackground;
          border-radius: $bonusRollingModalInfoTermsInfoScrollbarThumbBorderRadius;

          &:hover {
            background: $bonusRollingModalInfoTermsInfoScrollbarThumbHoverBackground;
          }
        }

        h3 {
          color: $bonusRollingModalInfoTermsInfoH3Color;
          font-size: $bonusRollingModalInfoTermsInfoH3FontSize;
          line-height: $bonusRollingModalInfoTermsInfoH3LineHeight;
          font-weight: $bonusRollingModalInfoTermsInfoH3FontWeight;
          margin: $bonusRollingModalInfoTermsInfoH3Margin;
          text-align: center;
        }

        .mn_termsText {
          text-align: $bonusRollingModalInfoTermsInfoTextTextAlign;
          color: $bonusRollingModalInfoTermsInfoTextColor;
          font-family: $bonusRollingModalInfoTermsInfoTextFontFamily;
          font-size: $bonusRollingModalInfoTermsInfoTextFontSize;
          line-height: $bonusRollingModalInfoTermsInfoTextLineHeight;
        }
      }
    }
  }

  .mn_hiw {
    background: $bonusRollingModalHiwBackground;
    padding: $bonusRollingModalHiwPadding;

    h3 {
      color: $bonusRollingModalHiwH3Color;
      font-size: map-get($bonusRollingModalHiwH3FontSize, small);
      line-height: map-get($bonusRollingModalHiwH3LineHeight, small);
      margin: map-get($bonusRollingModalHiwH3Margin, small);
      font-weight: $bonusRollingModalHiwH3FontWeight;
      text-align: center;
    }

    .mn_hiwItems {
      display: flex;
      justify-content: center;
      flex-direction: column;
      column-gap: $bonusRollingModalHiwItemsColumnGap;

      .mn_hiwItem {
        display: flex;
        column-gap: $bonusRollingModalHiwItemColumnGap;

        .mn_number {
          text-align: $bonusRollingModalHiwItemNumberTextAlign;
          color: map-get($bonusRollingModalHiwItemNumberColor, small);
          font-size: map-get($bonusRollingModalHiwItemNumberFontSize, small);
          line-height: map-get($bonusRollingModalHiwItemNumberLineHeight, small);
          font-family: map-get($bonusRollingModalHiwItemNumberFontFamily, small);
        }

        .mn_text {
          text-align: $bonusRollingModalHiwItemTextTextAlign;
          color: $bonusRollingModalHiwItemTextColor;
          font-size: map-get($bonusRollingModalHiwItemTextFontSize, small);
          line-height: map-get($bonusRollingModalHiwItemTextLineHeight, small);
        }
      }
    }
  }

  @media #{$medium-screen} {
    .mn_modal {
      width: map-get($bonusRollingModalWidth, medium);
    }

    .mn_info {
      padding: map-get($bonusRollingModalInfoPadding, medium);

      h2 {
        margin: map-get($bonusRollingModalInfoH2Margin, medium);
        font-size: map-get($bonusRollingModalInfoH2FontSize, medium);
        line-height: map-get($bonusRollingModalInfoH2LineHeight, medium);
      }

      .mn_termsWrap {
        .mn_closeTerms {
          right: map-get($bonusRollingModalInfoCloseTermsPositionRight, medium);
          top: map-get($bonusRollingModalInfoCloseTermsPositionTop, medium);
        }

        .mn_termsInfo {
          margin: map-get($bonusRollingModalInfoTermsInfoMargin, medium);
        }
      }
    }

    .mn_hiw {
      h3 {
        font-size: map-get($bonusRollingModalHiwH3FontSize, medium);
        line-height: map-get($bonusRollingModalHiwH3LineHeight, medium);
        margin: map-get($bonusRollingModalHiwH3Margin, medium);
      }

      .mn_hiwItems {
        flex-direction: row;
        column-gap: 3rem;

        .mn_hiwItem {
          display: flex;
          flex-basis: 33.33%;

          .mn_number {
            color: map-get($bonusRollingModalHiwItemNumberColor, medium);
            font-size: map-get($bonusRollingModalHiwItemNumberFontSize, medium);
            line-height: map-get($bonusRollingModalHiwItemNumberLineHeight, medium);
            font-family: map-get($bonusRollingModalHiwItemNumberFontFamily, medium);
          }

          .mn_text {
            color: $bonusRollingModalHiwItemTextColor;
            font-size: map-get($bonusRollingModalHiwItemTextFontSize, medium);
            line-height: map-get($bonusRollingModalHiwItemTextLineHeight, medium);
          }
        }
      }
    }
  }

  @media #{$large-screen} {
    .mn_modal {
      width: map-get($bonusRollingModalWidth, large);
    }

    .mn_info {
      padding: map-get($bonusRollingModalInfoPadding, large);

      h2 {
        margin: map-get($bonusRollingModalInfoH2Margin, large);
        font-size: map-get($bonusRollingModalInfoH2FontSize, large);
        line-height: map-get($bonusRollingModalInfoH2LineHeight, large);
      }
    }

    .mn_hiw {
      h3 {
        font-size: map-get($bonusRollingModalHiwH3FontSize, large);
        line-height: map-get($bonusRollingModalHiwH3LineHeight, large);
        margin: map-get($bonusRollingModalHiwH3Margin, large);
      }

      .mn_hiwItems {
        .mn_hiwItem {
          .mn_text {
            font-size: map-get($bonusRollingModalHiwItemTextFontSize, large);
            line-height: map-get($bonusRollingModalHiwItemTextLineHeight, large);
          }

          .mn_number {
            color: map-get($bonusRollingModalHiwItemNumberColor, large);
            font-size: map-get($bonusRollingModalHiwItemNumberFontSize, large);
            line-height: map-get($bonusRollingModalHiwItemNumberLineHeight, large);
            font-family: map-get($bonusRollingModalHiwItemNumberFontFamily, large);

            span {
              display: none;
            }
          }
        }
      }
    }
  }
}
