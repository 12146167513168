@font-face {
  font-family: 'AmericanSans';
  src: url('../fonts/AmericanSans/americansans-regular-webfont.eot');
  src:
    url('../fonts/AmericanSans/americansans-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/AmericanSans/americansans-regular-webfont.woff2') format('woff2'),
    url('../fonts/AmericanSans/americansans-regular-webfont.woff') format('woff'),
    url('../fonts/AmericanSans/americansans-regular-webfont.ttf') format('truetype'),
    url('../fonts/AmericanSans/americansans-regular-webfont.svg#americansansregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AmericanSans';
  src: url('../fonts/AmericanSans/americansans-bold-webfont.eot');
  src:
    url('../fonts/AmericanSans/americansans-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/AmericanSans/americansans-bold-webfont.woff2') format('woff2'),
    url('../fonts/AmericanSans/americansans-bold-webfont.woff') format('woff'),
    url('../fonts/AmericanSans/americansans-bold-webfont.ttf') format('truetype'),
    url('../fonts/AmericanSans/americansans-bold-webfont.svg#americansansbold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'AmericanSans-Light';
  src: url('../fonts/AmericanSans/americansans-light-webfont.eot');
  src:
    url('../fonts/AmericanSans/americansans-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/AmericanSans/americansans-light-webfont.woff') format('woff'),
    url('../fonts/AmericanSans/americansans-light-webfont.ttf') format('truetype'),
    url('../fonts/AmericanSans/americansans-light-webfont.svg#americansansregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AmericanIcons';
  src: url('../fonts/AmericanIcons/american-icons-v4.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AmericanCustom';
  src: url('../fonts/AmericanCustom/AAcustom.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
