@import '~org/styles/variables';

$ssoMigrationSiteNoticePosition: relative !default;
$ssoMigrationSiteNoticeZIndex: 101 !default;
$ssoMigrationSiteNoticePadding: (small: 1.2rem 0, medium: 2.4rem 0) !default;
$ssoMigrationSiteNoticeBackground: $brandGray3 !default;
$ssoMigrationSiteNoticeTextAlign: center !default;

$ssoMigrationSiteNoticeTextDisplay: inline-block !default;
$ssoMigrationSiteNoticeTextMaxWidth: (small: 32rem, medium: 100%) !default;
$ssoMigrationSiteNoticeTextColor: $brandDarkBlue3 !default;
$ssoMigrationSiteNoticeTextFontSize: (small: 1.6rem, medium: 2rem) !default;
$ssoMigrationSiteNoticeTextFontWeight: bold !default;
$ssoMigrationSiteNoticeTextLineHeight: 2.6rem !default;
$ssoMigrationSiteNoticeTextMargin: 0 !default;
$ssoMigrationSiteNoticeTextPadding: 0 6rem !default;

$ssoMigrationSiteNoticeSupFontSize: .8rem !default;
$ssoMigrationSiteNoticeSupLineHeight: 1.8rem !default;

$ssoMigrationSiteNoticeClosePosition: absolute !default;
$ssoMigrationSiteNoticeClosePositionTop: (small: .7rem, medium: 2.4rem) !default;
$ssoMigrationSiteNoticeClosePositionRight:(small: .7rem, large: 2.4rem) !default;
$ssoMigrationSiteNoticeCloseFontSize: (small: 1.8rem, medium: 2.7rem) !default;
$ssoMigrationSiteNoticeCloseLineHeight: (small: 1.7rem, medium: 2.5rem) !default;
$ssoMigrationSiteNoticeCloseColor: $brandPrimaryColor !default;
$ssoMigrationSiteNoticeClosePadding: 0 !default;
$ssoMigrationSiteNoticeCloseBackground: none !default;
$ssoMigrationSiteNoticeCloseBorder: none !default;
$ssoMigrationSiteNoticeCloseCursor: pointer !default;

$ssoMigrationSiteNoticeCloseBeforeContent: '\e04c' !default;
$ssoMigrationSiteNoticeCloseBeforeFontFamily: 'AmericanIcons' !default;

.mn_ssoMigrationSiteNotice {
  position: $ssoMigrationSiteNoticePosition;
  z-index: $ssoMigrationSiteNoticeZIndex;
  padding: map_get($ssoMigrationSiteNoticePadding, small);
  background: $ssoMigrationSiteNoticeBackground;
  text-align: $ssoMigrationSiteNoticeTextAlign;

  p {
    display: $ssoMigrationSiteNoticeTextDisplay;
    max-width: map_get($ssoMigrationSiteNoticeTextMaxWidth, small);
    color: $ssoMigrationSiteNoticeTextColor;
    font-size: map_get($ssoMigrationSiteNoticeTextFontSize, small);
    font-weight: $ssoMigrationSiteNoticeTextFontWeight;
    line-height: $ssoMigrationSiteNoticeTextLineHeight;
    margin: $ssoMigrationSiteNoticeTextMargin;

    sup {
      font-size: $ssoMigrationSiteNoticeSupFontSize;
      line-height: $ssoMigrationSiteNoticeSupLineHeight;
    }
  }

  .mn_siteNoticeClose {
    position: $ssoMigrationSiteNoticeClosePosition;
    top: map_get($ssoMigrationSiteNoticeClosePositionTop, small);
    right: map_get($ssoMigrationSiteNoticeClosePositionRight, small);
    font-size: map_get($ssoMigrationSiteNoticeCloseFontSize, small);
    line-height: map_get($ssoMigrationSiteNoticeCloseLineHeight, small);
    color: $ssoMigrationSiteNoticeCloseColor;
    padding: $ssoMigrationSiteNoticeClosePadding;
    background: $ssoMigrationSiteNoticeCloseBackground;
    border: $ssoMigrationSiteNoticeCloseBorder;
    cursor: $ssoMigrationSiteNoticeCloseCursor;

    &:before {
      content: $ssoMigrationSiteNoticeCloseBeforeContent;
      font-family: $ssoMigrationSiteNoticeCloseBeforeFontFamily;
    }
  }

  @media #{$medium-screen} {
    padding: map_get($ssoMigrationSiteNoticePadding, medium);

    p {
      max-width: map_get($ssoMigrationSiteNoticeTextMaxWidth, medium);
      font-size: map_get($ssoMigrationSiteNoticeTextFontSize, medium);
      padding: $ssoMigrationSiteNoticeTextPadding;
    }

    .mn_siteNoticeClose {
      top: map_get($ssoMigrationSiteNoticeClosePositionTop, medium);
      font-size: map_get($ssoMigrationSiteNoticeCloseFontSize, medium);
      line-height: map_get($ssoMigrationSiteNoticeCloseLineHeight, medium);
    }
  }

  @media #{$large-screen} {
    .mn_siteNoticeClose {
      right: map_get($ssoMigrationSiteNoticeClosePositionRight, large);
    }
  }
}
